import {
    ORDER_LOAD,
    ORDER_UNLOAD,
    CHECK_AVAILABILITY_SLOT
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case ORDER_LOAD:
//console.log(action);
     // console.log(action.error);
     // console.log(action.payload.message);
      // debugger;
      return {
        ...state,
        orders: action.payload ? action.payload : null,
        orders_message: action.payload ? action.payload.message : null,
        inProgress: false,
        errors: action.error ? action.payload : null,
        errorsOrders: action.error ? action.payload.message : null
      };
    case CHECK_AVAILABILITY_SLOT:
     // console.log(action);
     // console.log(action.error);
     // console.log(action.payload.message);
      // debugger;
      return {
        ...state,
        orders: action.payload ? action.payload : null,
        orders_message: null,
        availability_slot: action.payload ? action.payload : null,
        availability_slot_error_status: action.error ? action.error : null,
        availability_slot_error: action.error ? action.payload.message : null,
        inProgress: false,
        errors: action.error ? true : null,
        errorsOrders: null
      };
    case ORDER_UNLOAD:
      return {
        ...state,
        orders: null,
        orders_message: null,
        inProgress: false,
        availability_slot: null,
        availability_slot_error_status: null,
        availability_slot_error: null,
        errors: null,
        errorsOrders: null
      }
    default:
      return state;
  }

  return state;
};
