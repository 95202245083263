import React from 'react';
import agent from '../../../agent';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

import './../auth.scss';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Navigate, NavLink } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import { LOGIN, GOOGLESIGNUP, AUTH_PAGE_UNLOADED, APPLESIGNUP } from '../../../constants/actionTypes';
import { Helmet } from "react-helmet";
import { mdiEye, mdiEyeOff, mdiEyeLock } from '@mdi/js';
import Icon from '@mdi/react';
import Google from '../../Auth/Signin/google.png'
import AppleLogin from 'react-apple-login';
import HomeIcon from '../Signin/Home .png';
import OrdersIcon from '../Signin/OrdersIcon.png';
import ProfileIcon from '../Signin/ProfileIcon.png';


import CustomFunction from '../../../components/customFuction';
const Signin = (props) => {
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [userError, setUserError] = useState('')
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

 // let userError = '';
  // error message


  // form validation rules 
  const validationSchema = Yup.object().shape({

    emailid: Yup.string()
      .required('Email ID is required.')
      .matches( /^[A-Za-z0-9][A-Za-z0-9._%+-]*@[A-Za-z]{3,}(\.[A-Za-z]{2,}|\.[A-Za-z]{2,}\.[A-Za-z]{2,})$/, 'Invalid email address'),
      // .max(64, 'Email cannot be longer than 64 characters.'),
    password: Yup.string().required('Password is required')
      .matches(/^\S*$/, 'Password cannot contain spaces.')
      .min(8, 'Password must be at least 8 characters.')
      .max(32, 'Password cannot be longer than 16 characters.')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, { message: 'Please enter at least one uppercase letter, one lowercase letter, one number and one special character.' })
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, formState: { errors, isSubmitSuccessful } } = useForm(formOptions);

  function onSubmit({ emailid, password, userRole }) {
    const originalEmail = emailid;
    const originalEmailLowercase = originalEmail.toLowerCase();
    //console.log(emailid, password, userRole)
    return dispatch({ type: LOGIN, payload: agent.Auth.login(originalEmailLowercase, password, userRole) })
  }
  function onGoogleSubmit(credentialResponse) {
    // console.log("Credential response", credentialResponse);
    const token = credentialResponse.credential;

    const userRole = 'user';
    return dispatch({ type: GOOGLESIGNUP, payload: agent.Auth.googleRegister(token, userRole) })
  }
  // const [login, setLogin] = useState(false);
  // const [data, setData] = useState({});
  // const [picture, setPicture] = useState('');

  // const responseFacebook = (response) => {
  //   console.log(response);
  //   setData(response);
  //   //setPicture(response.picture.data.url);
  //   if (response.accessToken) {
  //     setLogin(true);
  //   } else {
  //     setLogin(false);
  //   }
  // }
  useEffect(() => {
      if (props.auth.errors) {
        setUserError ( props.auth.errors);
      }
    //dispatch({ type: AUTH_PAGE_UNLOADED })
  }, [props.auth.errors]);

  const handleAppleSignIn = (response) => {
    // Handle the response from Apple Sign-In
   // console.log('Apple Sign-In response:', response);
    const appledata = {
      token: response.authorization.id_token,
      role: 'user',
      nonce: response.authorization.nonce,
      fullName: response.user.name,
    };
   // console.log('Apple data:', appledata);
    return dispatch({ type: APPLESIGNUP, payload: agent.Auth.appleRegister(appledata) })
    //socialAppleLogin(appledata); // Call your socialAppleLogin function with the appledata object
  };

  if (CustomFunction.isAuthenticated()) {
    return <Navigate to='/home' />
  } else {
    return (
      <React.Fragment>
        <div className='signup_bg'>
          <div className='main'>
            <Helmet>
              <title>RideDoc | Signin</title>
              <meta name="description" content="Mobile bike mechanics at your door step" />
            </Helmet>
            <div className='header-wrp logo_header'>
              <header className='hd-centered'>
                <Link to="/" className="logo" style={{marginTop: 40}}>
                  <img src={process.env.PUBLIC_URL + '/logo.png'} alt="RideDoc" />
                </Link>
              </header>
              <h3 className='h5' style={{paddingTop: "1.5rem", fontWeight: 400}}>Mobile bike mechanics at your door step</h3>
            </div>
            <div className='main-wrapper'>
              <section className="auth-wrp">
                {/* Sign In Design Start */}
                <div className="auth-hd">
                  <h1 className='h1'>Sign In</h1>
                </div>
                <div className="auth-social-log-wrp">
                  <ul>
                    <li>
                      <GoogleLogin
                        onSuccess={credentialResponse => {
                          onGoogleSubmit(credentialResponse);
                        }}
                        onError={() => {
                        //  console.log('Signup Failed');
                        }}
                        type={"standard"}
                        shape={"circle"}
                      />

                    </li>
                    {/* <li>
                    <a href="Facebook">
                      <img src={process.env.PUBLIC_URL + '/images/facebook-ic.png'} alt="Facebook auth login" />
                    </a>
                  </li> */}
                  {/* <li>
                    <div style={{borderRadius:'10px'}}>
                  <FacebookLogin
                    appId="1106727393297955"
                    autoLoad={true}
                    fields="name,email,picture"
                    //onClick={componentClicked}
                    // render={renderProps => (
                    //   <button  onClick={renderProps.onClick}>This is my custom FB button</button>
                    // )}
                    textButton='  Login with Facebook'
                    scope="public_profile,user_friends"
                    callback={responseFacebook}
                    cssClass="kep-login-facebook-css"
                    icon="fa-facebook" 
                    size='small'
                    className='class'
                    />
                    </div>
                  </li> */}
                  {/* <li>
                    <div>
                    <AppleLogin 
                    clientId="com.RideDoc.web"
                    redirectURI="https://ridedoc.co/"
                    responseType="id_token code"
                    callback={(res) => {handleAppleSignIn(res)}}
                    scope="email name"
                    responseMode="form_post"
                    usePopup={true}
                    onFailure={console.error}
                    render={(props) => (
                      <button onClick={props.onClick} style={{margin:'5px',padding:'8px',backgroundColor:'white',borderRadius:'10px',font:'15px',width:'200px',borderColor:'white'}}>
                        Sign In with Apple
                      </button>
                    )}
                    />
                    </div>
                  </li> */}
                  </ul>
                </div>
                <div className="auth-or-cont">
                  <p>Or Continue with</p>
                </div>
                <div className="auth-default-log-wrp main-signin">
                  {userError && <span className='error'>{userError}</span>}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <input name="emailid" type="text" {...register('emailid')} className={`form-control  signin-form-group ${errors.emailid ? 'is-invalid' : ''}`} />
                      <label className="form-lbl" htmlFor="mail">Email ID</label>
                    </div>
                    {errors.emailid &&
                      <div className="invalid-feedback">{errors.emailid?.message}</div>
                    }
                    <div className="form-group" style={{ display: "flex" }}>
                      <input className="form-control signin-form-group" type={passwordType} {...register('password')} name="password" id="password" />
                      <label className="form-lbl" htmlFor="password">Password</label>
                      <div className='form-outline-primary'
                        style={{ position: "absolute", right: 0, marginTop: 12, marginRight: 10 }}
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <Icon path={mdiEyeLock} size={.8} />
                        ) : (
                          <Icon path={mdiEye} size={.8} />
                        )}
                      </div>
                    </div>
                    {errors.password &&
                      <div className="invalid-feedback">{errors.password?.message}</div>
                    }
                    <input
                      type="hidden"
                      {...register('userRole')}
                      defaultValue={'user'} />

                    
                    <div className="form-group signin-btn">
                      <button className="btn btn-primary" type="submit">Sign in</button>
                    </div>
                  </form>
                  <div className='form-group sign-in-group'>
                  <div className="alredy-signin">
                    <p >Not a member? <Link to="/signup" className='font-weight-900'>Sign up</Link></p>
                  </div>
                  <div className='text-right'>
                    <p><Link to="/forgot-password" className='font-weight-900'>Forgot Password?</Link></p>
                  </div>
                  </div>
                </div>
                {/* Sign In Design End */}
              </section>
            </div>
          </div>
        </div>
        
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(Signin);