import {
  LOGIN,
  REGISTER,
  AUTH_PAGE_UNLOADED,
  ASYNC_START,
  UPDATE_FIELD_AUTH,
  GOOGLESIGNUP,
  REFRESH_TOKEN,
  FORGOTPASSWORD
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        type: action.type,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        serverErrors: action.error ? action.payload : null,
        register: null,
        forgotpassword: null
      };
    case GOOGLESIGNUP:
     // console.log("GOOGLESIGNUP")
     // console.log(action)
      return {
        ...state,
        type: action.type,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        serverErrors: action.error ? action.payload : null,
        currentUser: action.payload ? action.payload : null,

        register: null,
        forgotpassword: null
      };
    case REGISTER:
      return {
        ...state,
        type: action.type,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        serverErrors: action.error ? action.payload : null,
        register: action.error ? null : action.payload,
        forgotpassword: null
      };
    case FORGOTPASSWORD:
      return {
        ...state,
        type: action.type,
        inProgress: false,
        errors: action.error ? action.payload : null,
        serverErrors: action.error ? action.payload : null,
        forgotpassword: action.error ? null : action.payload.message
      };
    case REFRESH_TOKEN:
      //console.log(REFRESH_TOKEN)
      //console.log(action)
      // debugger
      return {
        ...state,
        type: action.type,
        inProgress: false,
        errors: action.error ? action.payload.message : null,
        register: null,
        forgotpassword: null
      }
    case AUTH_PAGE_UNLOADED:
      return {
        ...state,
        type: action.type,
        inProgress: false,
        errors: null,
        serverErrors: null,
        register: null,
        forgotpassword: null
      };
    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === GOOGLESIGNUP) {
        return { ...state, inProgress: true, register: null };
      }
      break;
    case UPDATE_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};
