import React from 'react';
import agent from '../../../agent';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

import './../auth.scss';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Navigate, NavLink } from 'react-router-dom';
import { REGISTER, GOOGLESIGNUP, AUTH_PAGE_UNLOADED } from '../../../constants/actionTypes';
// import { Redirect } from 'react-router';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import Icon from '@mdi/react';
import { mdiClose, mdiEye, mdiEyeOff, mdiEyeLock } from '@mdi/js';
// import { authActions } from '_store';
import { Helmet } from "react-helmet";
import CustomFunction from '../../../components/customFuction';
import HomeIcon from '../Signin/Home .png';
import OrdersIcon from '../Signin/OrdersIcon.png';
import ProfileIcon from '../Signin/ProfileIcon.png';

const mapStateToProps = state => (
  { ...state }
);

const Signup = (props) => {

 // console.log("Props on sign up", props);

  const [showResults, setShowResults] = React.useState(false)
  const registerPopup = () => setShowResults(prevState => !prevState);


 // console.log("after Pop up show result value", showResults);
  // const closePopup = () => setShowResults(false);
  const dispatch = useDispatch();
  let userError = '';
  // error message
  if (props.auth) {
    if (props.auth.errors != null && props.auth.errors != '') {
      userError = props.auth.errors
    }
  }

  // After Form Submit FormField Error Message
  let commonServerMulError = [];
  if (props.auth.serverErrors) {
    if (props.auth.serverErrors.code === 422) {
      commonServerMulError = props.auth.serverErrors
      commonServerMulError = CustomFunction.isErrorMulFormMessage(commonServerMulError.errors.json)
    }
  }

  const [passwordType, setPasswordType] = useState("password");
  // const [login, setLogin] = useState(false);
  // const [data, setData] = useState({});
  // const [picture, setPicture] = useState('');

  // const responseFacebook = (response) => {
  //   console.log(response);
  //   setData(response);
  //   //setPicture(response.picture.data.url);
  //   if (response.accessToken) {
  //     setLogin(true);
  //   } else {
  //     setLogin(false);
  //   }
  // }
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // form validation rules 
  const validationSchema = Yup.object().shape({

    emailid: Yup.string()
      .required('Email ID is required.')
      .matches(/^[A-Za-z0-9][A-Za-z0-9._%+-]*@[A-Za-z]{3,}(\.[A-Za-z]{2,}|\.[A-Za-z]{2,}\.[A-Za-z]{2,})$/, 'Invalid email address'),
      // .max(64, 'Email cannot be longer than 64 characters.'),
    password: Yup.string().required('Password is required')
      .matches(/^\S*$/, 'Password cannot contain spaces.')
      .min(8, 'Password must be at least 8 characters long.')
      .max(32, 'Password cannot be longer than 16 characters.')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, { message: 'Please enter at least one uppercase letter, one lowercase letter, one number and one special character.' })
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  function onSubmit({ emailid, password, userRole }) {
    setShowResults(false);
    const lowercaseEmailId = emailid.toLowerCase();
    return dispatch({ type: REGISTER, payload: agent.Auth.register(userRole, password, lowercaseEmailId) })
  }
  function onGoogleSubmit(credentialResponse) {
    const token = credentialResponse.credential;
    const userRole = 'user';
    return dispatch({ type: GOOGLESIGNUP, payload: agent.Auth.googleRegister(token, userRole) })
  }
  useEffect(() => {
    userError = '';
    dispatch({ type: AUTH_PAGE_UNLOADED })
  }, []);

  if (CustomFunction.isAuthenticated()) {
    return <Navigate to='/home' />
  } else {

    return (
      <React.Fragment>
        <div className='signup_bg'>
          <div className='main'>
            <Helmet>
              <title>RideDoc | Signup</title>
              <meta name="description" content="Mobile bike mechanics at your door step" />
            </Helmet>
            <div className='header-wrp logo_header'>
              <header className='hd-centered'>
                <Link to="/" className="logo" style={{marginTop: 40}}>
                  <img src={process.env.PUBLIC_URL + '/logo.png'} alt="RideDoc" />
                </Link>
              </header>
              <h3 className='h5' style={{paddingTop: "1.5rem", fontWeight: 400}}>Mobile bike mechanics at your door step</h3>
            </div>
            <div className='main-wrapper'>
              <section className="auth-wrp">
                {/* Sign Up Design Start */}
                <div className="auth-hd">
                  <h1 className='h1'>Sign up</h1>
                </div>
                <div className="auth-social-log-wrp">
                  <ul>
                    <li>
                      <GoogleLogin
                        onSuccess={credentialResponse => {
                          onGoogleSubmit(credentialResponse);
                        }}
                        onError={() => {
                       //   console.log('Signup Failed');
                        }}
                        type={"standard"}
                        shape={"circle"}
                      />
                    </li>
                    {/* <li>
                    <a href="google">
                      <img src={process.env.PUBLIC_URL + '/images/facebook-ic.png'} alt="Facebook auth login" />
                    </a>
                  </li> */}
                   {/* <li>
                    <div style={{borderRadius:'10px'}}>
                  <FacebookLogin
                    appId="1106727393297955"
                    autoLoad={true}
                    fields="name,email,picture"
                    //onClick={componentClicked}
                    // render={renderProps => (
                    //   <button  onClick={renderProps.onClick}>This is my custom FB button</button>
                    // )}
                    scope="public_profile,user_friends"
                    callback={responseFacebook}
                    cssClass="kep-login-facebook-css"
                    textButton='  Login with Facebook'
                    icon="fa-facebook" 
                    size='small'
                    className='class'
                    />
                    </div>
                  </li> */}
                  </ul>
                </div>
                <div className="auth-or-cont">
                  <p>Or Continue with</p>
                </div>
                <div className="auth-default-log-wrp main-signin">
                  {userError && <span className='error'>{userError}</span>}
                  {commonServerMulError && commonServerMulError}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <input name="emailid" type="text" {...register('emailid')} className={`form-control signin-form-group ${errors.emailid ? 'is-invalid' : ''}`} />
                      <label className="form-lbl" htmlFor="mail">Email ID</label>
                    </div>
                    {errors.emailid &&
                      <div className="invalid-feedback">{errors.emailid?.message}</div>
                    }
                    <div className="form-group" style={{ display: "flex", marginBottom: "1rem" }}>
                      <input className="form-control signin-form-group" {...register('password')} type={passwordType} name="password" id="password" />
                      <label className="form-lbl" htmlFor="password">Password</label>
                      <div className='form-outline-primary'
                        style={{ position: "absolute", right: 0, marginTop: 12, marginRight: 10 }}
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <Icon path={mdiEyeLock} size={.8} />
                        ) : (
                          <Icon path={mdiEye} size={.8} />
                        )}
                      </div>
                    </div>
                    {errors.password &&
                      <div className="invalid-feedback">{errors.password?.message}</div>
                    }

                    <div className='terms-condition-group'>
                      <p className='terms'>By creating an account you accept our <Link to='/terms-conditions'>Terms and Conditions</Link></p>
                    </div>

                    <input
                      type="hidden"
                      {...register('userRole')}
                      defaultValue={'user'} />
                    <div className="form-group form-reg-btn">
                      <button className="btn btn-primary" type='submit'>Register</button>
                    </div>
                  </form>
                  <div className="alredy-signup">
                    <p>Already Registered? <Link to="/signin" className='font-weight-900'>Sign in</Link></p>
                  </div>
                </div>
                {/* Sign up Design End */}
              </section>
            </div>
            {(props.auth.register) &&

              <div className={showResults ? 'reg-pop dn' : 'reg-pop'}>
                <div className='reg-popin'>
                  <Link to="#" className='reg-close' onClick={registerPopup}>
                    <Icon path={mdiClose} size={'24px'} color="#222" />
                  </Link>
                  <div className='reg-pophd'>
                    <h4>User successfully registered. Please check your email and validate before logging in.</h4>
                    <p>Click here to <Link to="/signin" className='font-weight-900'>Sign in</Link></p>
                  </div>
                  {/* {console.log("Inside: popup", showResults)} */}

                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>

    );
  }
}


export default connect(mapStateToProps)(Signup);