import {
    ORDER_HISTORY_LOAD,
    ORDER_HISTORY_UNLOAD
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case ORDER_HISTORY_LOAD:
        return {
          ...state,
          orderData: action.error ? null : action.payload,
          inProgress: null,
          errors: action.error ? action.payload.message : null,
        };
      case ORDER_HISTORY_UNLOAD:
        return {};
      default:
        return state;
    }
  };
  
// import {
//   ORDERS_LIST_REQUEST,
//   ORDERS_LIST_SUCCESS,
//   ORDERS_LIST_FAIL,
//   CLEAR_ERRORS,
// } from "../constants/actionTypes";

// export default (state = {}, action) => {

//   console.log(action);
//   // debugger;



//   switch (action.type) {
//     case ORDERS_LIST_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };

//     case ORDERS_LIST_SUCCESS:
//       return {
//         loading: false,
//         order: action.payload,
//       };

//     case ORDERS_LIST_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     case CLEAR_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };

//     default:
//       console.log(action.type);
//       // debugger;
//       return {
//         state
//       }
//       // return state;
//   }
// };