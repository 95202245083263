import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  REFRESH_TOKEN,
  REGISTER,
  GOOGLESIGNUP,
  AUTH_PAGE_UNLOADED,
  USER_LOAD
} from '../constants/actionTypes';
import jwt_decode from 'jwt-decode';

const defaultState = {
  appName: 'RideDoc',
  token: null,
  viewChangeCounter: 0
};

export default (state = defaultState, action) => {
  let userData = null;

  // console.log(action.payload)
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload : null,
        redirectTo: null
      };
    case USER_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload : null,
        redirectTo: null
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    // case LOGOUT:
    //   return { ...state, redirectTo: '/', token: null, currentUser: null };
    case LOGIN:
      return {
        ...state,
        redirectTo: action.error ? null : '/home',
        token: action.error ? null : action.payload.results.access_token,
        currentUser: action.error ? null : action.payload.results.access_token
      };
    case GOOGLESIGNUP:
      return {
        ...state,
        redirectTo: action.error ? null : '/home',
        token: action.error ? null : action.payload.results.access_token,
        currentUser: action.payload ? action.payload : null,
      };
    case REFRESH_TOKEN:
      // console.log(action)
      // debugger;
      return {
        ...state,
        redirectTo: action.error ? null : '/home',
        token: action.error ? null : action.payload.results.refresh_token,
        currentUser: action.error ? null : action.payload.results.refresh_token
      };
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : '/signin',
        token: action.error ? null : action.payload.results.access_token,
        currentUser: null
      };

    case AUTH_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    default:
      return state;
  }
};
