// import { applyMiddleware, createStore } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { promiseMiddleware, localStorageMiddleware, checkRefreshTokenExpirationMiddleware } from './middleware';
// import reducer from './reducer';
// import {rootReducer, history} from './reducer';
import createRootReducer from './reducer'

import { routerMiddleware } from 'react-router-redux'
// import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from "history";


export const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
        return [myRouterMiddleware, promiseMiddleware, localStorageMiddleware, checkRefreshTokenExpirationMiddleware];
    } else {
        // Enable additional logging in non-production environments.
        return [myRouterMiddleware, promiseMiddleware, localStorageMiddleware, checkRefreshTokenExpirationMiddleware, createLogger()]
    }
};

const preloadedState = {};
export const store = configureStore({
    middleware: getMiddleware(),
    reducer: createRootReducer(history),
    preloadedState,
});
