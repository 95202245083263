import React, { useEffect } from 'react';
import './privacypolicy.scss';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';



function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
            <div className='header-terms-wrp'>
             <header>
              <div className="header-terms-backic">
                <Link onClick={() => window.history.back()} className="header-terms-backic-icon">
                  <Icon path={mdiArrowLeft} size={'28px'} color="#222" />
                </Link>
              </div>
              <div className='profile-menu-header-text'>
                <p>Privacy Policy</p>
              </div>
            </header>
            </div>
            <div className='c79 doc-content'>
                <p className="c38 p-content"><span className="c8 c57">RideDoc Privacy Policy</span></p>
                <p className="c10 p-content"><span className="c9">Last Updated: June, 30th 2023</span></p>
                <p className="c10 p-content">
                    <span className="c9">At RideDoc our mission is to simplify the process of bike repairs and
                        services by connecting bikers with mobile bike mechanics. To do that, we
                        need to collect, use, and share some of your personal information. This
                        Privacy Policy is meant to help you understand how RideDoc does that and
                        how to exercise the choices and rights you have in your
                        information.</span>
                </p>
                <ol className="c25 lst-kix_list_1 start" start="1">
                    <li className="c20 c41 c2 li-bullet-0 li-text">
                        <span className="c3">The Scope of This Policy</span>
                    </li>
                </ol>
                <p className="c10 p-content">
                    <span className="c9">This policy applies to all RideDoc users, including Riders and
                        Mechanics (including Mechanic applicants), and to all RideDoc platforms
                        and services, including our applications, websites, technology,
                        facilities, and other services (collectively, the &ldquo;RideDoc
                        Platform&rdquo;). This policy applies only to personal information, not
                        to aggregate information or information that does not identify you.
                        Please remember that your use of the RideDoc Platform is also subject to
                        our Terms of Service. Certain elements of the RideDoc Platform may
                        operate under separate or additional terms or practices different from
                        or in addition to those described in this policy; in those cases, you
                        will be provided separate notice and information relevant to your use of
                        those parts of the RideDoc Platform.
                    </span>
                </p>
                <p className="c70 p-content">
                    <span className="c9"
                    >Some states such as California, Colorado, Connecticut, Utah, and
                        Virginia provide specific rights to residents of the state regarding
                        personal information. To see more information about these rights and how
                        to exercise them, see addendum &ldquo;RideDoc Privacy Policy &ndash;
                        State Information &amp; Rights&rdquo;</span>
                </p>
                <p className="c70 c22 p-content"><span className="c9"></span></p>
                <p className="c10 p-content">
                    <span className="c9">When you use the RideDoc Platform, we collect the information you
                        provide, usage information, and information about your device. We also
                        collect information about you from other sources like service providers,
                        and optional programs in which you participate, which we may combine
                        with other information we have about you. Here are the types of
                        information we collect about you:</span>
                </p>
                <p className="c15 p-content"><span className="c3">A. Information You Provide to Us</span></p>
                <ul className="c25 lst-kix_list_17-0 start">
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Account Registration.&nbsp;</span>
                        <span className="c9"
                        >When you create an account with RideDoc, we collect the information
                            you provide us, such as your name, email address, phone number, birth
                            date, profile photo, and payment information. You may choose to share
                            additional info with us for your Rider profile, like saved addresses
                            (e.g., home or work) or the bikes you are using, and set up other
                            preferences (such as your preferred pronouns). We may ask that you
                            provide additional information related to the identity of your
                            account, such as documents related to identification (e.g.,
                            Mechanic&rsquo;s certification), a profile picture, or
                            &ldquo;selfie&rdquo; imagery. If you choose to engage in additional
                            offerings on the RideDoc Platform (e.g. linking to other accounts like
                            travel rewards), you may provide us additional information relevant to
                            those offerings.</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Mechanic Information.</span>
                        <span className="c9">&nbsp;If you apply to be a Mechanic, we will collect the information
                            you provide in your application, including your name, email address,
                            phone number, birth date, profile photo, physical address, government
                            identification number (such as social security number) and
                            Mechanic&rsquo;s certification information. We collect the payment
                            information you provide us, including your bank routing numbers, and
                            tax information. Depending on where you want to provide the service,
                            we may also ask for additional business license or permit information
                            or other information relevant to that location. We may need additional
                            information from you at some point after you become a Mechanic,
                            including information to confirm your identity (like
                            &ldquo;selfie&rdquo; imagery).</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Ratings and Feedback.</span>
                        <span className="c9">&nbsp;When you rate and provide feedback about the RideDoc Platform
                            (including about Riders or Mechanics), we collect all of the
                            information you provide in your feedback.</span>
                    </li>
                    <li className="c10 c41 c47 li-bullet-0 li-text">
                        <span className="c3">Communications.</span>
                        <span className="c9">&nbsp;When you contact us or we contact you, including through
                            surveys or research projects, we collect any information that you
                            provide, including the contents of the messages or attachments you
                            send us.</span>
                    </li>
                </ul>
                <p className="c15 p-content">
                    <span className="c3">B. Information We Collect When You Use the RideDoc Platform</span>
                </p>
                <ul className="c25 lst-kix_list_dot-0 start">
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Location Information.</span>
                        <span className="c9"
                        >&nbsp;Great services start with an easy and accurate service
                            location. The RideDoc Platform may collect location information
                            (including GPS and WiFi data, IP address, and Bluetooth data)
                            differently depending on your RideDoc app settings . We might collect
                            your device&rsquo;s precise location when you open and use the app,
                            including while the app is running in the background. We also collect
                            precise location for a limited time after you exit Mechanic mode in
                            order to detect service incidents, and continue collecting it until a
                            reported or detected incident is no longer active.</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Usage Information.</span><span className="c9"
                        >&nbsp;We collect information about your use of the RideDoc Platform,
                            including service information like the date, time, service location,
                            service type, other specifics required to conduct the service e.g.
                            parts required, payment, and whether you used a promotional or
                            referral code. We also collect information about your interactions
                            with the RideDoc Platform like our apps and websites, including the
                            pages and content you view and the dates and times of your use. We may
                            also infer information from your use of or information you provide us
                            in your interactions with the RideDoc Platform.</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Device Information.</span><span className="c9"
                        >&nbsp;We collect information about the devices you use to access the
                            RideDoc Platform, including device model, IP address, type of browser,
                            version of operating system, identity of carrier and manufacturer,
                            radio type (such as 4G), preferences and settings (such as preferred
                            language), application installations, device identifiers, advertising
                            identifiers, and push notification tokens. If you are a Mechanic, we
                            might also collect mobile sensor data from your device.
                        </span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Communications</span><span className="c9"
                        >: Communications Between Riders and Mechanics is direct via the
                            contact information provided by the rider (Phone or eMail)</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Address Book Contacts</span>
                        <span className="c9"
                        >.&nbsp;You may set your device permissions to grant RideDoc access to
                            your contact lists and direct RideDoc to access your contact list, for
                            example to help you refer friends to RideDoc. If you choose to do
                            this, we will access the names and contact information of the people
                            in your address book.</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Calendar Information.</span>
                        <span className="c9"
                        >&nbsp;You may set your device permissions or otherwise grant RideDoc
                            access to your chosen calendar and direct RideDoc to access calendar
                            information, for example to add a mobile bike service appointment to
                            your calendar. If you choose to do this, we will access and store
                            information available in your calendar to use in providing you these
                            optional features.</span>
                    </li>
                    <li className="c10 c41 c47 li-bullet-0 li-text">
                        <span className="c3">Cookies, Analytics, and Third-Party Technologies.</span>
                        <span className="c9">&nbsp;We collect information through the use of
                            &ldquo;cookies&rdquo;, tracking pixels, data analytics tools like </span>
                        <span className="c56">Google Analytics</span>
                        <span className="c9"
                        >, SDKs, and other third-party technologies to understand how you
                            navigate through the RideDoc Platform and interact with RideDoc
                            advertisements, to make your RideDoc experience safer, to learn what
                            content is popular, to improve your site experience, to serve you
                            better ads on other sites, and to save your preferences. Cookies are
                            small text files that web servers place on your device; they are
                            designed to store basic information and to help websites and apps
                            recognize your browser. We may use both session cookies and persistent
                            cookies. A session cookie disappears after you close your browser. A
                            persistent cookie remains after you close your browser and may be
                            accessed every time you use the RideDoc Platform.</span>
                    </li>
                </ul>
                <p className="c15 p-content">
                    <span className="c3">C. Information We Collect From Other Sources</span>
                </p>
                <ul className="c25 lst-kix_list_19-0 start">
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Service Providers and Other Parties</span><span className="c9">.&nbsp;Service providers and other parties provide us with
                            information needed for core aspects of the RideDoc Platform, as well
                            as for additional services, programs, loyalty benefits, and promotions
                            that can enhance your RideDoc experience. These service providers and
                            other parties could include background check providers, insurance
                            partners, financial service providers, marketing providers, and other
                            businesses. We may obtain the following information about you from
                            these parties:</span>
                    </li>
                </ul>
                <ul className="c25 lst-kix_list_19-1 start">
                    <li className="c66 c41 li-bullet-0 li-text">
                        <span className="c9"
                        >Information to make the RideDoc Platform safer, like background check
                            information or identity verification information;
                        </span>
                    </li>
                    <li className="c66 c41 li-bullet-0 li-text">
                        <span className="c9"
                        >Information about your participation in third-party programs that
                            provide things like insurance coverage and financial instruments, such
                            as insurance, payment, transaction, and fraud detection
                            information;</span>
                    </li>
                    <li className="c41 c66 li-bullet-0 li-text">
                        <span className="c9"
                        >Information to operationalize loyalty and promotional/marketing
                            programs or applications, services, or features you choose to connect
                            or link to your RideDoc account, such as information about your use of
                            such programs, applications, services, or features; and</span>
                    </li>
                    <li className="c66 c41 li-bullet-0 li-text">
                        <span className="c9"
                        >Information about you provided by specific services, such as bike
                            type, demographic and market segment information.</span>
                    </li>
                </ul>
                <ul className="c25 lst-kix_list_19-0">
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Referral Programs.</span><span className="c9"
                        >&nbsp;Friends help friends use the RideDoc Platform. If someone
                            refers you to RideDoc, we will collect information about you from that
                            referral including your name and contact information.</span>
                    </li>
                    <li className="c10 c41 c47 li-bullet-0 li-text">
                        <span className="c3">Other Users and Sources</span><span className="c9"
                        >.&nbsp;Other users or public or third-party sources such as law
                            enforcement, insurers, media, or witnesses may provide us information
                            about you, for example as part of an investigation into an incident or
                            to provide you support.</span>
                    </li>
                </ul>
                <ol className="c25 lst-kix_list_1" start="2">
                    <li className="c2 li-bullet-0 li-text">
                        <span className="c3">How We Use Your Information</span>
                    </li>
                </ol>
                <p className="c10 c27 p-content">
                    <span className="c3">We use your personal information to:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-1 start">
                    <li className="c17 li-bullet-0 li-text">
                        <span className="c9">Provide the RideDoc Platform;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Maintain the security and safety of the RideDoc Platform and its
                            users;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Build and maintain the RideDoc community;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Provide customer support;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Improve the RideDoc Platform; and</span>
                    </li>
                    <li className="c1 li-bullet-0 li-text">
                        <span className="c9">Respond to legal proceedings and obligations.</span>
                    </li>
                </ul>
                <p className="c10 c27 p-content">
                    <span className="c36">Providing the RideDoc Platform.</span
                    ><span className="c9"
                    >&nbsp;We use your personal information to provide an intuitive, useful,
                        efficient, and worthwhile experience on our platform. To do this, we use
                        your personal information to:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-3 start">
                    <li className="c17 li-bullet-0 li-text">
                        <span className="c9"
                        >Verify your identity and maintain your account, settings, and
                            preferences;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Connect you to your services and track their progress;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Provide various RideDoc Platform offerings to you that may be
                            personalized to you;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Calculate prices and process payments;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Allow Riders and Mechanics to connect regarding their service and to
                            choose to share information with others;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Communicate with you about your use of the RideDoc Platform and
                            experience;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Collect feedback regarding your experience;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Facilitate additional services and programs with third parties;
                            and</span>
                    </li>
                    <li className="c1 li-bullet-0 li-text">
                        <span className="c9"
                        >Operate contests, sweepstakes, and other promotions.</span>
                    </li>
                </ul>
                <p className="c10 c27 p-content">
                    <span className="c36"
                    >Maintaining the Security and Safety of the RideDoc Platform and its
                        Users.</span>
                    <span className="c9"
                    >&nbsp;Providing you a secure and safe experience drives our platform,
                        both in the real world and on our apps. To do this, we use your personal
                        information to:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-4 start">
                    <li className="c17 li-bullet-0 li-text">
                        <span className="c9">Authenticate and verify users;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Verify that Mechanics meet safety requirements;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Investigate and resolve incidents, accidents, and insurance
                            claims;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Avoid unsafe activities;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Find and prevent fraud; and</span>
                    </li>
                    <li className="c1 li-bullet-0 li-text">
                        <span className="c9"
                        >Block and remove unsafe or fraudulent users from the RideDoc
                            Platform.</span>
                    </li>
                </ul>
                <p className="c10 c27 p-content">
                    <span className="c36">Building and Maintaining the RideDoc Community</span
                    ><span className="c9"
                    >.&nbsp;RideDoc works to be a positive part of the community. We use
                        your personal information to:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-5 start">
                    <li className="c17 li-bullet-0 li-text">
                        <span className="c9"
                        >Communicate with you about events, promotions, elections, and
                            campaigns;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Personalize and provide content, experiences, communications, and
                            targeted advertising to promote and grow the RideDoc Platform;
                            and</span>
                    </li>
                    <li className="c1 li-bullet-0 li-text">
                        <span className="c9"
                        >Help facilitate donations you choose to make through the RideDoc
                            Platform.</span>
                    </li>
                </ul>
                <p className="c10 c27 p-content">
                    <span className="c36">Providing Customer Support.</span>
                    <span className="c9"
                    >&nbsp;We work hard to provide the best experience possible, including
                        supporting you when you need it. To do this, we use your personal
                        information to:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-6 start">
                    <li className="c17 li-bullet-0 li-text">
                        <span className="c9"
                        >Investigate and assist you in resolving questions or issues you have
                            regarding the RideDoc Platform; and</span>
                    </li>
                    <li className="c1 li-bullet-0 li-text">
                        <span className="c9">Provide you support or respond to you.</span>
                    </li>
                </ul>
                <p className="c10 c27 p-content">
                    <span className="c36">Improving the RideDoc Platform.</span>
                    <span className="c9"
                    >&nbsp;We are always working to improve your experience and provide you
                        with new and helpful features. To do this, we use your personal
                        information to:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-7 start">
                    <li className="c17 li-bullet-0 li-text">
                        <span className="c9">Perform research, testing, and analysis;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Develop new products, features, partnerships, and services;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Prevent, find, and resolve software or hardware bugs and issues;
                            and</span>
                    </li>
                    <li className="c1 li-bullet-0 li-text">
                        <span className="c9"
                        >Monitor and improve our operations and processes, including security
                            practices, algorithms, and other modeling.</span>
                    </li>
                </ul>
                <p className="c10 c27 p-content">
                    <span className="c36">Responding to Legal Proceedings and Requirements.</span>
                    <span className="c64"
                    >&nbsp;Sometimes the law, government entities, or other regulatory
                        bodies impose demands and obligations on us with respect to the services
                        we seek to provide. In such a circumstance, we may use your personal
                        information to respond to those demands </span>
                    <span className="c3">or obligations.</span>
                </p>
                <ol className="c25 lst-kix_list_1" start="3">
                    <li className="c2 li-bullet-0 li-text">
                        <span className="c3">How We Share Your Information</span>
                    </li>
                </ol>
                <p className="c10 p-content">
                    <span className="c9"
                    >We do not sell your personal information to third parties for money --
                        no one can buy the personal information we collect from and about you
                        and we do not act as a data broker. However, we may need to share your
                        personal information with other users, third parties, and service
                        providers to make the RideDoc Platform work and to deliver relevant
                        personalized ads to you on and off the RideDoc Platform. Some of these
                        disclosures may constitute &ldquo;sharing&rdquo; or
                        &ldquo;selling&rdquo; of personal information under certain U.S. state
                        privacy laws. This section otherwise explains when and why we share your
                        information.</span>
                </p>
                <p className="c37 p-content"><span className="c3">A. Sharing between RideDoc Users</span></p>
                <p className="c0 c27 p-content"><span className="c3"></span></p>
                <p className="c10 c29 p-content"><span className="c3">Riders and Mechanics.</span></p>
                <ul className="c25 lst-kix_list_dot-23 start">
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Rider information shared with Mechanic:</span>
                        <span className="c9"
                        >&nbsp;As part of surfacing the service request and enabling the
                            service, we share with the Mechanic the Rider&rsquo;s service type,
                            location, bike type name, profile photo, rating, Rider statistics
                            (like approximate number of services and years as a RideDoc customer),
                            and information the Rider includes in their Rider profile (like
                            preferred pronouns) as well as all additional information you include
                            in your service request on the service itself. Once the service is
                            finished, we also eventually share the Rider&rsquo;s rating and
                            feedback with the Mechanic. (We remove the Rider&rsquo;s identity
                            associated with ratings and feedback when we share it with Mechanics,
                            but a Mechanic may be able to identify the Rider that provided the
                            rating or feedback.)</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c3">Mechanic information shared with Rider:</span>
                        <span className="c9"
                        >&nbsp;Upon a Mechanic accepting a requested service, we will share
                            with the Rider the Mechanic&rsquo;s name, profile photo, preferred
                            pronouns, rating, as well as we may share other information in the
                            Mechanic&rsquo;s RideDoc profile, such as information Mechanics choose
                            to add (like country flag) and Mechanic statistics (like approximate
                            number of completed services and years as a Mechanic).</span>
                    </li>
                    <li className="c10 c41 c47 li-bullet-0 li-text">
                        <span className="c3">Referral Programs.</span>
                        <span className="c9"
                        >&nbsp;If you refer someone to the RideDoc Platform, we will let them
                            know that you generated the referral. If another user referred you, we
                            may share information about your use of the RideDoc Platform with that
                            user. For example, a referral source may receive a bonus when you join
                            the RideDoc Platform or complete a certain number of rides and would
                            receive such information.</span>
                    </li>
                </ul>
                <p className="c15 p-content">
                    <span className="c3"
                    >B. Sharing With Service Providers to Provide the RideDoc Platform, and
                        Other Parties</span>
                </p>
                <p className="c10 c27 p-content">
                    <span className="c9"
                    >Depending on whether you&rsquo;re a Rider or a Mechanic, RideDoc may
                        share the following categories of your personal information to provide
                        you with a variety of the RideDoc Platform&rsquo;s features and
                        services:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-8 start">
                    <li className="c30 c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Personal identifiers, such as your name, address, email address,
                            phone number, date of birth, government identification number (such as
                            social security number)</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Financial information, such as bank routing numbers, tax information,
                            and any other payment information you provide us;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Commercial information, such as service information, Mechanic/Rider
                            statistics and feedback, and Mechanic/Rider transaction history;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Internet or other electronic network activity information, such as
                            your IP address, type of browser, version of operating system, carrier
                            and/or manufacturer, device identifiers, and mobile advertising
                            identifiers;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Location data; and</span>
                    </li>
                    <li className="c59 c41 c11 li-bullet-0 li-text">
                        <span className="c9"
                        >This and other information you may direct us to share with other
                            parties, such as when you choose to link your RideDoc account with a
                            separate travel rewards program or when you choose to engage with an
                            offering from another company through the RideDoc Platform.</span>
                    </li>
                </ul>
                <p className="c10 c27 p-content">
                    <span className="c9"
                    >We disclose those categories of personal information to these other
                        parties and service providers to fulfill the following purposes:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-9 start">
                    <li className="c11 c30 li-bullet-0 li-text">
                        <span className="c9">Maintaining and servicing your RideDoc account;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Processing or fulfilling services or other offerings of the RideDoc
                            Platform;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Providing you customer service;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Processing transactions and payments;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Processing Mechanic applications;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Verifying the identity of users;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Detecting and preventing fraud and unsafe activity;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Processing insurance claims;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Providing Mechanic loyalty and promotional programs;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Providing marketing and advertising services to RideDoc, including to
                            provide targeted and cross-contextual behavioral advertising
                            personalized to you;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Providing financing;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Providing requested emergency services;</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c9">Providing analytics services to RideDoc; and</span>
                    </li>
                    <li className="c59 c41 c11 li-bullet-0 li-text">
                        <span className="c9"
                        >Undertaking research to develop and improve the RideDoc
                            Platform.</span>
                    </li>
                </ul>
                <p className="c15 p-content">
                    <span className="c3"
                    >C. For Legal Reasons and to Protect the RideDoc Platform</span>
                </p>
                <p className="c10 c27 p-content">
                    <span className="c9"
                    >We may share your personal information in response to a legal
                        obligation, or if we have determined that sharing your personal
                        information is reasonably necessary or appropriate to:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-10 start">
                    <li className="c17 li-bullet-0 li-text">
                        <span className="c9"
                        >Comply with any applicable federal, state, or local law or
                            regulation, civil, criminal or regulatory inquiry, investigation or
                            legal process, enforceable governmental request, or requirement as
                            condition to operate (e.g., operating permit, license or
                            agreement);</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Respond to legal process (such as a search warrant, subpoena,
                            summons, or court order);</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9">Enforce our Terms of Service;</span>
                    </li>
                    <li className="c18 li-bullet-0 li-text">
                        <span className="c9"
                        >Cooperate with law enforcement agencies concerning conduct or
                            activity that we reasonably and in good faith believe may violate
                            federal, state, or local law; or</span>
                    </li>
                    <li className="c1 li-bullet-0 li-text">
                        <span className="c9"
                        >Exercise or defend legal claims, protect against harm to our rights,
                            property, interests, or safety or the rights, property, interests, or
                            safety of you, third parties, or the public as required or permitted
                            by law.</span>
                    </li>
                </ul>
                <p className="c15 p-content">
                    <span className="c3">D. In Connection with Sale or Merger</span>
                </p>
                <p className="c10 c27 p-content">
                    <span className="c9"
                    >We may share your personal information while negotiating or in relation
                        to a change of corporate control such as a restructuring, merger, or
                        sale of our assets.</span>
                </p>
                <p className="c15 p-content"><span className="c3">E. Upon Your Further Direction</span></p>
                <p className="c10 c27 p-content">
                    <span className="c9"
                    >With your permission or upon your direction, we may disclose your
                        personal information to interact with a third party or for other
                        purposes.</span>
                </p>
                <ol className="c25 lst-kix_list_1" start="4">
                    <li className="c2 li-bullet-0 li-text">
                        <span className="c3">How We Store and Protect Your Information</span>
                    </li>
                </ol>
                <p className="c10 p-content">
                    <span className="c9"
                    >We retain your information for as long as necessary to provide you and
                        our other users the RideDoc Platform. This means we keep your profile
                        information for as long as you maintain an account. We retain
                        transactional information such as services and payments for at least
                        seven years to ensure we can perform legitimate business functions, such
                        as accounting for tax obligations. We also retain your information as
                        necessary to comply with our legal obligations, resolve disputes and
                        enforce our terms and policies. If you request account deletion, we will
                        delete your information as set forth in the &ldquo;Deleting Your
                        Account&rdquo; section below.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c9"
                    >We take reasonable and appropriate measures designed to protect your
                        personal information. But no security measures can be 100% effective,
                        and we cannot guarantee the security of your information, including
                        against unauthorized intrusions or acts by third parties.</span>
                </p>
                <ol className="c25 lst-kix_list_1" start="5">
                    <li className="c2 li-bullet-0 li-text">
                        <span className="c3">Your Rights And Choices Regarding Your Data</span>
                    </li>
                </ol>
                <p className="c10 p-content">
                    <span className="c9"
                    >RideDoc provides ways for you to access and delete your personal
                        information as well as exercise applicable data rights that give you
                        certain control over your personal information.</span>
                </p>
                <p className="c15 p-content"><span className="c3">A. All Users</span></p>
                <ul className="c25 lst-kix_list_dot-24 start">
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c3">Email Subscriptions.</span>
                        <span className="c9"
                        >&nbsp;You can always unsubscribe from our commercial or promotional
                            emails by clicking unsubscribe in those messages. We will still send
                            you transactional and relational emails about your use of the RideDoc
                            Platform. Please note that it might take up to 72 hours until your
                            opt-out is reflected in our systems</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c3">Push Notifications.</span>
                        <span className="c9"
                        >&nbsp;You can opt out of receiving push notifications through your
                            device settings. Please note that opting out of receiving push
                            notifications may impact your use of the RideDoc Platform (such as
                            receiving a notification that you mechanic is on his way).</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c3">Profile Information</span>
                        <span className="c9"
                        >.&nbsp;You can review and edit certain account information you have
                            chosen to add to your profile by logging in to your account settings
                            and profile.</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c3">Location Information.</span>
                        <span className="c9"
                        >&nbsp;You can prevent your device from sharing location information
                            through your device&rsquo;s system settings. But if you do, this may
                            impact RideDoc&rsquo;s ability to provide you our full range of
                            features and services. You may also control some elements of sharing
                            your location with RideDoc in your RideDoc account settings.</span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c3">Cookie Tracking.</span>
                        <span className="c9"
                        >&nbsp;You can modify your cookie settings on your browser.
                        </span>
                    </li>
                    <li className="c11 li-bullet-0 li-text">
                        <span className="c3">Accessing Your Information.</span>
                        <span className="c9"
                        >&nbsp;You can see information we have about you by logging into your
                            account and viewing things like your profile, settings, and
                            preferences (like preferred pronouns and address shortcuts such as
                            home and work), service history, or payment information. In addition,
                            you may have some information included in things we have sent to you,
                            such as service receipts.</span>
                    </li>
                    <li className="c10 c11 c41 li-bullet-0 li-text">
                        <span className="c3">Deleting Your Account.</span>
                        <span className="c9"
                        >&nbsp;If you would like to delete your RideDoc account, please visit
                            the Profile section of the RideDoc App and follow the &ldquo;Delete
                            Account&rdquo; instructions. In some cases, we will be unable to
                            delete your account, such as if there is an issue with your account
                            related to trust, safety, or fraud. When we delete your account, we
                            may retain certain information for legitimate business purposes or to
                            comply with legal or regulatory obligations. For example, we may
                            retain your information to resolve open insurance claims, or we may be
                            obligated to retain your information as part of an open legal claim.
                            When we retain such data, we do so in ways designed to prevent its use
                            for other purposes.</span>
                    </li>
                </ul>
                <p className="c15 p-content"><span className="c3">B. State-Specific Rights</span></p>
                <p className="c10 c27 p-content">
                    <span className="c9">
                        Some states such as California, Colorado, Connecticut, Utah, and
                        Virginia provide specific rights to residents of the state regarding
                        personal information. To see more information about these rights and how
                        to exercise them, see&nbsp;addendum &ldquo;RideDoc Privacy Policy
                        &ndash; State Information &amp; Rights&rdquo;</span>
                </p>
                <ol className="c25 lst-kix_list_1" start="6">
                    <li className="c2 li-bullet-0 li-text">
                        <span className="c3">Children&rsquo;s Data</span>
                    </li>
                </ol>
                <p className="c10 c27 p-content">
                    <span className="c9">RideDoc is not directed to children, and we don&rsquo;t knowingly
                        collect personal information from children under the age of 13. If we
                        find out that a child under 13 has given us personal information, we
                        will take steps to delete that information. If you believe that a child
                        under the age of 13 has given us personal information, please contact us
                        by writing an email to hello@ridedoc.co</span>
                </p>
                <ol className="c25 lst-kix_list_1" start="7">
                    <li className="c2 li-bullet-0 li-text">
                        <span className="c3">Links to Third-Party Websites</span>
                    </li>
                </ol>
                <p className="c10 c27 p-content">
                    <span className="c9"
                    >The RideDoc Platform may contain links or references to third-party
                        websites, products, or services. Those third parties may have privacy
                        policies that differ from ours. We are not responsible for those third
                        parties and their websites, products or services, and we recommend that
                        you review their policies. Please contact those parties directly if you
                        have any questions about their privacy policies.</span>
                </p>
                <ol className="c25 lst-kix_list_1" start="8">
                    <li className="c2 li-bullet-0 li-text">
                        <span className="c3">Changes to This Privacy Policy</span>
                    </li>
                </ol>
                <p className="c10 c27 p-content">
                    <span className="c9"
                    >We may update this policy from time to time as the RideDoc Platform
                        changes and privacy law evolves. If we update it, we will do so online,
                        and if we make material changes, we will let you know through the
                        RideDoc Platform or by some other method of communication like email.
                        When you use RideDoc, you are agreeing to the most recent terms of this
                        policy.</span >
                </p>
                <ol className="c25 lst-kix_list_1" start="9">
                    <li className="c2 li-bullet-0 li-text"><span className="c3">Contact Us</span></li>
                </ol>
                <p className="c10 c27 p-content">
                    <span className="c9"
                    >If you have any questions or concerns about your privacy or anything in
                        this policy, including if you need to access this policy in an
                        alternative format, we encourage you send us an email to
                        hello@ridedoc.co</span>
                </p>
                <p className="c0 p-content"><span className="c9"></span></p>
                {/* <hr style="page-break-before: always; display: none" /> */}
                <hr style={{pageBreakBefore: "always", display: "none"}} />
                <p className="c70 c22 p-content"><span className="c9"></span></p>
                <p className="c0 p-content"><span className="c4"></span></p>
                <p className="c10 p-content">
                    <span className="c8 c52"
                    >Addendum &ldquo;RideDoc Privacy Policy &ndash; State Information &amp;
                        Rights&rdquo;</span>
                </p>
                <p className="c10 p-content"><span className="c4">Last updated: July, 1st 2023</span></p>
                <p className="c10 p-content">
                    <span className="c4"
                    >This Addendum contains additional information relevant for specific
                        state laws and regulations regarding data privacy, including rights that
                        may be available to some residents of these states. The information
                        below applies if you are a resident of the specific state, and the
                        relevant rights will be available from the date that the state laws and
                        regulations become effective. This is an addendum to the content
                        provided in and forms part of RideDoc&#39;s&nbsp;Privacy Policy.</span>
                </p>
                <ol className="c25 lst-kix_list_dot-11 start" start="1">
                    <li className="c26 li-bullet-0 li-text"><span className="c4">CALIFORNIA</span></li>
                    <li className="c51 c41 li-bullet-0 li-text"><span className="c4">COLORADO</span></li>
                    <li className="c41 c51 li-bullet-0 li-text"><span className="c4">CONNECTICUT</span></li>
                    <li className="c51 c41 li-bullet-0 li-text"><span className="c4">UTAH</span></li>
                    <li className="c59 c41 c78 li-bullet-0 li-text"><span className="c4">VIRGINIA</span></li>
                </ol>
                <p className="c20 c22 p-content"><span className="c46"></span></p>
                <p className="c20 p-content"><span className="c46">CALIFORNIA</span></p>
                <p className="c10 p-content">
                    <span className="c4"
                    >Categories of Personal Information Disclosed and Categories of Third
                        Parties to Whom it was Disclosed.&nbsp;The categories of personal
                        information that may have been disclosed to a third party for a business
                        purpose, as well as the purposes for disclosing that information, are
                        provided in section 4 of our&nbsp;Privacy Policy. The chart below
                        specifies which categories of personal information were disclosed to
                        which categories of third parties:</span>
                </p>
                <hr style={{pageBreakBefore: "always", display: "none"}} />
                <p className="c70 c22 p-content"><span className="c4"></span></p>
                <p className="c0 p-content"><span className="c4"></span></p>
                <a id="t.a87676f9de9351675897e0921809726b575bb870"></a><a id="t.0"></a>
                <div className='table-container'>
                <table className="c75">
                    <tr className="c62">
                        <td className="c55" colspan="2" rowspan="2">
                            <p className="c16 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c65" colspan="7" rowspan="1">
                            <p className="c7 p-content"><span className="c35">Categories of Third Parties</span></p>
                        </td>
                    </tr>
                    <tr className="c21">
                        <td className="c61" colspan="1" rowspan="1">
                            <p className="c16 p-content">
                                <span className="c14">Mechanics (if you are a Rider)</span>
                            </p>
                        </td>
                        <td className="c49" colspan="1" rowspan="1">
                            <p className="c16 p-content">
                                <span className="c14">Riders (if you are a Mechanic)</span>
                            </p>
                        </td>
                        <td className="c74" colspan="1" rowspan="1">
                            <p className="c16 p-content">
                                <span className="c14">Other Riders (if you take a shared ride)</span>
                            </p>
                        </td>
                        <td className="c71" colspan="1" rowspan="1">
                            <p className="c16 p-content">
                                <span className="c14">People who refer you to RideDoc</span>
                            </p>
                        </td>
                        <td className="c77" colspan="1" rowspan="1">
                            <p className="c16 p-content">
                                <span className="c14"
                                >Third Party who requests or pays for your ride</span >
                            </p>
                        </td>
                        <td className="c28" colspan="1" rowspan="1">
                            <p className="c16 p-content">
                                <span className="c14"
                                >Parties to whom you request to share or link accounts</span>
                            </p>
                        </td>
                        <td className="c50" colspan="1" rowspan="1">
                            <p className="c16 p-content">
                                <span className="c14"
                                >Other parties with whom you intentionally interact (e.g., to
                                    obtain a vehicle service)</span>
                            </p>
                        </td>
                    </tr>
                    <tr className="c62">
                        <td className="c40" colspan="1" rowspan="5">
                            <p className="c7 p-content">
                                <span className="c35">Categories of Personal Information</span>
                            </p>
                        </td>
                        <td className="c6" colspan="1" rowspan="1">
                            <p className="c7 p-content">
                                <span className="c14"
                                >Information you provide as part of your RideDoc account (e.g.,
                                    name)</span>
                            </p>
                        </td>
                        <td className="c19" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c24" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c12" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c54" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c69" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c32" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c33" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                    </tr>
                    <tr className="c62">
                        <td className="c53" colspan="1" rowspan="1">
                            <p className="c7 p-content">
                                <span className="c14"
                                >Information you provide when you communicate with other users
                                    (e.g., texts)</span>
                            </p>
                        </td>
                        <td className="c44" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c76" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c43" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c72" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c31" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c45" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c60" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                    </tr>
                    <tr className="c62">
                        <td className="c6" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c14">Location information</span></p>
                        </td>
                        <td className="c19" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c24" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c12" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c54" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c69" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c32" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c33" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                    </tr>
                    <tr className="c62">
                        <td className="c53" colspan="1" rowspan="1">
                            <p className="c7 p-content">
                                <span className="c14">Information about your use of RideDoc</span>
                            </p>
                        </td>
                        <td className="c44" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c76" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c43" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c72" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c31" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c45" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c60" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                    </tr>
                    <tr className="c62">
                        <td className="c6" colspan="1" rowspan="1">
                            <p className="c7 p-content">
                                <span className="c14"
                                >Other information you provide to RideDoc (e.g., feedback)</span>
                            </p>
                        </td>
                        <td className="c19" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c24" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c12" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c54" colspan="1" rowspan="1">
                            <p className="c7 c22 p-content"><span className="c5"></span></p>
                        </td>
                        <td className="c69" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c32" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                        <td className="c33" colspan="1" rowspan="1">
                            <p className="c7 p-content"><span className="c5">X</span></p>
                        </td>
                    </tr>
                </table>
                </div>
                <p className="c70 c22 p-content">
                    <span className="c4"><br /></span>
                </p>
                <p className="c10 p-content">
                    <span className="c8"
                    >Categories of Personal Information Shared and Categories of Third
                        Parties to Whom it was Shared.</span>
                    <span className="c4"
                    >The following categories of personal information may have been shared
                        to a third party for the purpose of cross-context behavioral
                        advertising:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-12 start">
                    <li className="c23 li-bullet-0 li-text">
                        <span className="c4">Personal identifiers, such as name, phone number and email
                            address;</span>
                    </li>
                    <li className="c23 c41 li-bullet-0 li-text">
                        <span className="c4"
                        >Internet or other electronic network activity information, such as
                            your IP address, type of browser, version of operating system, carrier
                            and/or manufacturer, device identifiers, device event information,
                            make and model of device, and mobile advertising identifiers;
                            and</span>
                    </li>
                    <li className="c41 c23 c59 li-bullet-0 li-text">
                        <span className="c4"
                        >Information about you and your use of the RideDoc Platform, such as
                            your region and whether you are a rider or Mechanic.</span>
                    </li>
                </ul>
                <p className="c10 p-content">
                    <span className="c4"
                    >Each of the categories of personal information listed above may be
                        shared with the following categories of third parties for the purpose of
                        cross-context behavioral advertising:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-13 start">
                    <li className="c23 li-bullet-0 li-text">
                        <span className="c4"
                        >Advertising publishers that place advertisements on our behalf, such
                            as Google or Meta;</span>
                    </li>
                    <li className="c23 c41 li-bullet-0 li-text">
                        <span className="c4"
                        >Advertising partners that place advertisements on the RideDoc
                            Platform on behalf of other advertisers; and</span>
                    </li>
                    <li className="c59 c41 c23 li-bullet-0 li-text">
                        <span className="c4"
                        >Parties that provide statistical and analytic information regarding
                            the effectiveness of our marketing.</span>
                    </li>
                </ul>
                <p className="c10 p-content">
                    <span className="c4"
                    >RideDoc is not directed to children, and we don&rsquo;t knowingly
                        collect personal information from children under the age of 16;
                        accordingly we don&rsquo;t knowingly share the personal information from
                        children under the age of 16 for cross-context behavioral advertising
                        nor do we sell that information. If we find out that a child under 16
                        has given us personal information, we will take steps to delete that
                        information. If you believe that a child under the age of 16 has given
                        us personal information, please contact send us an email at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Sensitive Personal Information.</span>
                    <span className="c4"
                    >&nbsp;RideDoc does not use or disclose sensitive personal information
                        other than to provide you the RideDoc Platform and as permitted by
                        California law. RideDoc does not sell or share sensitive personal
                        information for the purpose of cross-context behavioral
                        advertising.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Deidentified Information.</span>
                    <span className="c4"
                    >&nbsp;As mentioned in our&nbsp;Privacy Policy, personal information
                        does not include information that is deidentified. When we receive or
                        use deidentified information, we maintain it in deidentified form and do
                        not attempt to reidentify the information.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Retention of Personal Information.</span>
                    <span className="c4"
                    >&nbsp;We retain your information for as long as necessary to provide
                        you and our other users the RideDoc Platform. This means we keep your
                        profile information for as long as you maintain an account. We retain
                        transactional information such as rides and payments for at least seven
                        years to ensure we can perform legitimate business functions, such as
                        accounting for tax obligations. We also retain your information as
                        necessary to comply with our legal obligations, resolve disputes and
                        enforce our terms and policies.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8"
                    >Your California Rights Regarding Your Personal Information</span>
                    <span className="c4"
                    >.&nbsp;California law provides some California residents with the
                        rights listed below. You may&nbsp;write to us&nbsp;to exercise rights at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Know.</span>
                    <span className="c4"
                    >&nbsp;You have the right to know and see what personal information we
                        have collected about you, including:</span>
                </p>
                <ul className="c25 lst-kix_list_dot-14 start">
                    <li className="c23 li-bullet-0 li-text">
                        <span className="c4"
                        >The categories of personal information we have collected about
                            you;</span>
                    </li>
                    <li className="c23 c41 li-bullet-0 li-text">
                        <span className="c4"
                        >The categories of sources from which the personal information is
                            collected;</span>
                    </li>
                    <li className="c23 c41 li-bullet-0 li-text">
                        <span className="c4"
                        >The business or commercial purpose for collecting or sharing your
                            personal information;</span>
                    </li>
                    <li className="c23 c41 li-bullet-0 li-text">
                        <span className="c4"
                        >The categories of third parties with whom we have disclosed your
                            personal information; and</span>
                    </li>
                    <li className="c59 c41 c23 li-bullet-0 li-text">
                        <span className="c4"
                        >The specific pieces of personal information we have collected about
                            you.</span>
                    </li>
                </ul>
                <p className="c10 p-content">
                    <span className="c8">Right to Delete.</span>
                    <span className="c4"
                    >&nbsp;You have the right to request that we delete the personal
                        information we have collected from you (and direct our service providers
                        to do the same).</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Correct.</span>
                    <span className="c4"
                    >&nbsp;You have the right to request that we correct inaccurate personal
                        information.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Opt Out of Sharing</span>
                    <span className="c4"
                    >.&nbsp;You have the right to opt out of the sharing of your personal
                        information to a third party for cross-context behavioral
                        advertising.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Other Rights</span>
                    <span className="c4"
                    >.&nbsp;You can request certain information about our disclosure of
                        personal information to third parties for their own direct marketing
                        purposes during the preceding calendar year. This request is free and
                        may be made once a year. You also have the right not to be discriminated
                        against for exercising any of the rights listed above.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Exercising Your California Privacy Rights.</span>
                    <span className="c4"
                    >&nbsp;To request access to or deletion of your personal information, or
                        to exercise any other privacy rights under California law, please email
                        us at hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To opt out of the sharing of personal information for cross-context
                        behavioral advertising, you may also write to us at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To respond to some rights we may need to verify your request either by
                        asking you to log in and authenticate your account or otherwise verify
                        your identity by providing information about yourself or your account.
                        Authorized agents can make a request on your behalf if you have given
                        them legal power of attorney or we are provided proof of signed
                        permission, verification of your identity, and, in some cases,
                        confirmation that you provided the agent permission to submit the
                        request.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Response Timing and Format.</span>
                    <span className="c4"
                    >&nbsp;We aim to respond to a consumer request in relation to these
                        rights within 45 days of receiving that request. If we require more
                        time, we will inform you of the reason and extension period in
                        writing.</span>
                </p>
                <p className="c0 p-content"><span className="c4"></span></p>
                <p className="c20 p-content"><span className="c46">COLORADO</span></p>
                <p className="c10 p-content"><span className="c4">Effective July 1, 2023</span></p>
                <p className="c10 p-content">
                    <span className="c8">Your Colorado Rights Regarding Your Personal Data.</span>
                    <span className="c4"
                    >&nbsp;Colorado law provides some Colorado residents with the rights
                        listed below. You may&nbsp;write to us&nbsp;to exercise rights at
                        hello@ridedoc.co</span>
                </p>
                <p className="c0 p-content"><span className="c4"></span></p>
                <p className="c10 p-content">
                    <span className="c8">Right to Know.</span><span className="c4"
                    >&nbsp;You have the right to know and see what personal data we have
                        collected about you in a portable format.</span
                    >
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Correct.</span>
                    <span className="c4"
                    >&nbsp;You have the right to request that we correct inaccurate personal
                        data.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Delete</span>
                    <span className="c4"
                    >.&nbsp;You have the right to request that we delete the personal data
                        we have collected about you.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Opt Out.</span>
                    <span className="c4"
                    >&nbsp;You have the right to opt out of targeted advertising and the
                        sale of your data (as defined under Colorado law).</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Exercising Your Colorado Privacy Rights.</span>
                    <span className="c4"
                    >&nbsp;To request access to or deletion of your personal data, or to
                        exercise any other privacy rights under Colorado law, please email
                        us:</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >Email: You may&nbsp;write to us&nbsp;to exercise rights at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To opt out of targeted advertising and the sale of personal data, you
                        may also write to us at hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To appeal our decision regarding a request related to these rights, you
                        may&nbsp;write to us also.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To respond to some rights we may need to verify your request either by
                        asking you to log in and authenticate your account or otherwise verify
                        your identity by providing information about yourself or your account.
                        Authorized agents can make a request on your behalf if you have given
                        them legal power of attorney or we are provided proof of signed
                        permission, verification of your identity, and, in some cases,
                        confirmation that you provided the agent permission to submit the
                        request.</span>
                </p>
                <p className="c20 c22 p-content"><span className="c46"></span></p>
                <p className="c20 p-content"><span className="c46">CONNECTICUT</span></p>
                <p className="c10 p-content"><span className="c4">Effective July 1, 2023</span></p>
                <p className="c10 p-content">
                    <span className="c8"
                    >Your Connecticut Rights Regarding Your Personal Data.</span>
                    <span className="c4"
                    >&nbsp;Connecticut law provides some Connecticut residents with the
                        rights listed below. You may&nbsp;write to us&nbsp;to exercise rights at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Know.</span>
                    <span className="c4"
                    >&nbsp;You have the right to know and see what personal data we have
                        collected about you in a portable format.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Correct.</span>
                    <span className="c4"
                    >&nbsp;You have the right to request that we correct inaccurate personal
                        data.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Delete.</span>
                    <span className="c4"
                    >&nbsp;You have the right to request that we delete the personal data we
                        have collected about you.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Opt Out.</span>
                    <span className="c4"
                    >&nbsp;You have the right to opt out of targeted advertising and the
                        sale of your data (as defined under Connecticut law).</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Exercising Your Connecticut Privacy Rights.</span>
                    <span className="c4"
                    >&nbsp;To request access to or deletion of your personal data, or to
                        exercise any other privacy rights under Connecticut law, please email us
                        at hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To opt out of targeted advertising and the sale of personal data, you
                        may also write to us at hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To appeal our decision regarding a request related to these rights, you
                        may&nbsp;write to us also.</span>
                </p>
                <p className="c20 c22 p-content"><span className="c46"></span></p>
                <p className="c20 p-content"><span className="c46">UTAH</span></p>
                <p className="c70 p-content"><span className="c4">Effective December 31, 2023</span></p>
                <p className="c10 p-content">
                    <span className="c8">Your Utah Rights Regarding Your Personal Data.</span>
                    <span className="c4"
                    >&nbsp;Utah law provides some Utah residents with the rights listed
                        below. You may&nbsp;write to us&nbsp;to exercise rights at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Know.</span>
                    <span className="c4"
                    >&nbsp;You have the right to know and see what personal data we have
                        collected about you in a portable format.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Delete.</span>
                    <span className="c4"
                    >&nbsp;You have the right to request that we delete the personal data we
                        have collected about you.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Opt Out.</span>
                    <span className="c4"
                    >&nbsp;You have the right to opt out of targeted advertising (as defined
                        under Utah law).</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Exercising Your Utah Privacy Rights.</span>
                    <span className="c4"
                    >&nbsp;To request access to or deletion of your personal data, or to
                        exercise any other privacy rights under Utah law, please email us at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To opt out of targeted advertising, you may also write to us at
                        hello@ridedoc.co</span>
                </p>
                <p className="c20 c22 p-content"><span className="c46"></span></p>
                <p className="c20 p-content"><span className="c46">VIRGINIA</span></p>
                <p className="c10 p-content"><span className="c4">Effective January 1, 2023</span></p>
                <p className="c10 p-content">
                    <span className="c8">Your Virginia Rights Regarding Your Personal Data</span>
                    <span className="c4"
                    >.&nbsp;Virginia law provides some Virginia residents with the rights
                        listed below. You may&nbsp;write to us&nbsp;to exercise rights at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Know.</span>
                    <span className="c4"
                    >&nbsp;You have the right to know and see what personal data we have
                        collected about you.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Correct.</span>
                    <span className="c4"
                    >&nbsp;You have the right to request that we correct inaccurate personal
                        data.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Delete.</span>
                    <span className="c4"
                    >&nbsp;You have the right to request that we delete the personal data we
                        have collected about you.</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Right to Opt Out.</span>
                    <span className="c4"
                    >&nbsp;You have the right to opt out of targeted advertising and the
                        sale of your data (as defined under Virginia law).</span>
                </p>
                <p className="c10 p-content">
                    <span className="c8">Exercising Your Virginia Privacy Rights.</span>
                    <span className="c4"
                    >&nbsp;To request access to or deletion of your personal data, or to
                        exercise any other privacy rights under Virginia law, please email us at
                        hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To opt out of targeted advertising and the sale of personal data, you
                        may also write to us at hello@ridedoc.co</span>
                </p>
                <p className="c10 p-content">
                    <span className="c4"
                    >To appeal our decision regarding a request related to these rights, you
                        may&nbsp;write to us also.</span>
                </p>
                <p className="c0 p-content"><span className="c4"></span></p>
                <p className="c0 p-content"><span className="c9"></span></p>
                <p className="c0 p-content"><span className="c58"></span></p>
                <p className="c0 p-content"><span className="c58"></span></p>
                <p className="c0 p-content"><span className="c58"></span></p>
                <p className="c22 c70 p-content"><span className="c34"></span></p>
            </div>
            </div>
    );
}

export default PrivacyPolicy;
