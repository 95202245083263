import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './footer.scss'

const LoggedOutView = props => {
  const location = useLocation();

  if (!props.currentUser && location.pathname !== '/') {
    return (
      <div className='footer-main-wrp-in'>
        <div className="footer-lst">
          <div className="footer-lst-in">
            <div className="footer-item">
              <NavLink to="/home" className={({ isActive }) => (isActive ? 'footer-in active' : 'footer-in')}>
                <span className='footer-link-in'>
                  <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.6 19V12.2941H14.4V19H20.4V10.0588H24L12 0L0 10.0588H3.6V19H9.6Z" fill="#FF5F07" />
                  </svg>
                </span>
                <span className='footer-txt'>Home</span>
              </NavLink>
            </div>
            <div className="footer-item">
              <NavLink to="/signup" className={({ isActive }) => (isActive ? 'footer-in active' : 'footer-in')}>
                <span className='footer-link-in'>
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 0C10.7598 0 11.968 0.500445 12.8588 1.39124C13.7496 2.28204 14.25 3.49022 14.25 4.75C14.25 6.00978 13.7496 7.21796 12.8588 8.10876C11.968 8.99956 10.7598 9.5 9.5 9.5C8.24022 9.5 7.03204 8.99956 6.14124 8.10876C5.25044 7.21796 4.75 6.00978 4.75 4.75C4.75 3.49022 5.25044 2.28204 6.14124 1.39124C7.03204 0.500445 8.24022 0 9.5 0ZM9.5 11.875C14.7487 11.875 19 14.0006 19 16.625V19H0V16.625C0 14.0006 4.25125 11.875 9.5 11.875Z" fill="black" />
                  </svg>
                </span>
                <span className='footer-txt'>Profile</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const LoggedInView = props => {
  const location = useLocation();
  if (props.currentUser && location.pathname !== '/') {
    return (
      <div className='footer-main-wrp-in'>
        <div className="footer-lst">
          <div className="footer-lst-in">
            <div className="footer-item">
              <NavLink to="/home" className={({ isActive }) => (isActive ? 'footer-in active' : 'footer-in')}>
                <span className='footer-link-in footer-home'>
                  <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.6 19V12.2941H14.4V19H20.4V10.0588H24L12 0L0 10.0588H3.6V19H9.6Z" fill="#FF5F07" />
                  </svg>
                </span>
                <span className='footer-txt'>Home</span>
              </NavLink>
            </div>
            <div className="footer-item">
              <NavLink to="/profile" className={({ isActive }) => (isActive ? 'footer-in active' : 'footer-in')}>
                <span className='footer-link-in footer-profile'>
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 0C10.7598 0 11.968 0.500445 12.8588 1.39124C13.7496 2.28204 14.25 3.49022 14.25 4.75C14.25 6.00978 13.7496 7.21796 12.8588 8.10876C11.968 8.99956 10.7598 9.5 9.5 9.5C8.24022 9.5 7.03204 8.99956 6.14124 8.10876C5.25044 7.21796 4.75 6.00978 4.75 4.75C4.75 3.49022 5.25044 2.28204 6.14124 1.39124C7.03204 0.500445 8.24022 0 9.5 0ZM9.5 11.875C14.7487 11.875 19 14.0006 19 16.625V19H0V16.625C0 14.0006 4.25125 11.875 9.5 11.875Z" fill="black" />
                  </svg>
                </span>
                <span className='footer-txt'>Profile</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

class Footer extends React.Component {
  render() {
    return (
      <footer className='footer-main-wrp'>
        <LoggedOutView currentUser={this.props.currentUser} />
        <LoggedInView currentUser={this.props.currentUser} />
      </footer>
    );
  }
}

export default Footer;