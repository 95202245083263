import {
  VEHICLE_TYPES_LOAD,
  VEHICLE_MAKES_LOAD,
  VEHICLE_MODELS_LOAD
    } from '../constants/actionTypes';
    
    export default (state = {}, action) => {
      switch (action.type) {
        case VEHICLE_TYPES_LOAD:
          return {
            ...state,
            inProgress: false,
            errors: action.error ? action.payload.message : null,
            types: action.payload ? action.payload : null,
            // makes: null,
            // models: null
          };
        case VEHICLE_MAKES_LOAD:
          return {
              ...state,
              inProgress: false,
              errors: action.error ? action.payload.message : null,
              // types: null,
              makes: action.payload ? action.payload : null,
              // models: null
          };
        case VEHICLE_MODELS_LOAD:
          return {
            ...state,
            inProgress: false,
              errors: action.error ? action.payload.message : null,
              // types: null,
              // makes: null,
              models: action.payload ? action.payload : null,
          };
        
        default:
          return state;
      }
    
      return state;
    };
    