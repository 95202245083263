import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import './../auth.scss';
import { Helmet } from "react-helmet";
import { Link, NavLink, useLocation } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiAccountCircleOutline, mdiAccountTie, mdiLogout, mdiEmailOpen, mdiClipboardTextClock, mdiCalendar, mdiClockTimeTwoOutline, mdiClose, mdiPhoneInTalkOutline, mdiAt } from '@mdi/js';
import agent from '../../../agent';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LOGIN, FORGOTPASSWORD, FORGOTPASSWORD_PAGE_UNLOADED } from '../../../constants/actionTypes';
import HomeIcon from '../Signin/Home .png';
import OrdersIcon from '../Signin/OrdersIcon.png';
import ProfileIcon from '../Signin/ProfileIcon.png';
import { mdiEye, mdiEyeOff, mdiEyeLock } from '@mdi/js';
import Modal from 'react-modal';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const mapStateToProps = state => ({ ...state });

const ForgotPasswordOtp = (props) => {
    const location = useLocation();
    //console.log("LOCATION", location);
    //console.log("Props data", props);


    const [otpIsOpen, setOtpIsOpen] = useState(false);
    const [passwordType, setPasswordType] = useState('');
    const [otpVerifySuccess, setOtpVerifySuccess] = useState('');
    const [otpVerifyError, setOtpVerifyError] = useState('');
    // const [otpId, setOtpId] = useState('');
    const [email, setEmail] = useState('');
    const [cardAddPopup, setCardAddPopup] = React.useState(true);
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    // form validation rules 
    const validationSchema = Yup.object().shape({
        emailid: Yup.string().required('Email ID is required.').email('Please enter valid Email ID').max(64),
        otp_code: Yup.string().required('OTP is required'),
        password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters.')
        .max(30, 'Password should be between 8 and 30 characters')
        .matches(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/, {message: 'Password should contains at least a symbol, upper and lower case letters and a number and nospace'})
    });

    const formOptions = { resolver: yupResolver(validationSchema), defaultValues: {
        emailid: location?.state?.email
      }, };
    const { register, handleSubmit, reset, formState, formState: { errors, isSubmitSuccessful } } = useForm(formOptions);
    
        const onSubmit = async (data) => {
            const request = {
                id: location?.state?.otpId,
                otp_code: data.otp_code,
                password: data.password
            }

            axios.post(process.env.REACT_APP_RIDEDOC_API_ROOT + `auth/verify-otp`, request, {
                headers: {
                    'Content-Type': "application/json",
                }
            }).then((res) => {
                setOtpVerifySuccess(res);
                setOtpIsOpen(true);
            }).catch((err) => {
                setOtpVerifyError(err.message);
                setOtpIsOpen(false);
            })

        }

        function closeModel() {
            setOtpIsOpen(false);
        }

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                background: '#fff',
                maxWidth: '400px',
                padding: '30px 15px',
                width: '90%'
            },
        };

        return (
            <React.Fragment>
                <div className='forgot_bg'>
                    <div className='main'>
                        <Helmet>
                            <title>RideDoc | Signin</title>
                            <meta name="description" content="Mobile bike mechanics at your door step" />
                        </Helmet>
                        <div className='header-wrp'>
                            <header className='hd-centered'>
                                <Link to="/" className="logo" style={{ marginTop: 40 }}>
                                    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="RideDoc" />
                                </Link>
                            </header>
                            <h3 className='h5' style={{ fontWeight: 400, paddingTop: "1rem" }}>Mobile bike mechanics at your door step</h3>
                        </div>
                        <div className='main-wrapper'>
                            <section className="auth-wrp forgot-form-wrp">
                                <div className="auth-hd forgot-hd">
                                    <h1 className='h3'>Forgot Password</h1>
                                </div>
                                <div className="auth-default-log-wrp">
                                    {otpVerifyError && <span className='error'>{otpVerifyError}</span>}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group forgot-group">
                                            <input name="emailid" type="text"
                                            // value={email} // Bind the email state to the input value
                                            // onChange={handleEmailChange}
                                            {...register('emailid')} className={`form-control signin-form-group ${errors.emailid ? 'is-invalid' : ''}`} />
                                            <label className="form-lbl" htmlFor="emailid">Email ID</label>
                                        </div>
                                        {errors.emailid &&
                                            <div className="invalid-feedback">{errors.emailid?.message}</div>
                                        }
                                        <div className="form-group forgot-group">
                                            <input name="otp_code" type="text" {...register('otp_code')} className={`form-control signin-form-group ${errors.otp_code ? 'is-invalid' : ''}`} />
                                            <label className="form-lbl" htmlFor="emailid">OTP</label>
                                        </div>
                                        {errors.otp_code &&
                                            <div className="invalid-feedback">{errors.otp_code?.message}</div>
                                        }

                                        <div className="form-group" style={{ display: "flex" }}>
                                            <input className="form-control signin-form-group" type={passwordType} {...register('password')} name="password" id="password" />
                                            <label className="form-lbl" htmlFor="password">Password</label>
                                            <div className='form-outline-primary'
                                                style={{ position: "absolute", right: 0, marginTop: 12, marginRight: 10 }}
                                                onClick={togglePassword}
                                            >
                                                {passwordType === "password" ? (
                                                    <Icon path={mdiEyeLock} size={.8} />
                                                ) : (
                                                    <Icon path={mdiEye} size={.8} />
                                                )}
                                            </div>
                                        </div>
                                        {errors.password &&
                                            <div className="invalid-feedback">{errors.password?.message}</div>
                                        }

                                        <div className="form-group forgot-group">
                                            <button className="btn btn-primary forgot-btn" type="submit">Verify Otp</button>
                                        </div>
                                    </form>
                                    <div className="alredy-signin forgot-already-signin">
                                        <p>Back to  <Link to="/signin" className="logo">Sign In</Link></p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={otpIsOpen}
                    onRequestClose={closeModel}
                    ariaHideApp={false}
                    style={customStyles}
                    overlayClassName="modal-Overlay"
                    contentLabel="Example Modal"
                >
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> 
       <button >close</button> */}

                    <div className='booking-wrp'>
                        <div className={cardAddPopup ? 'rd-popup-wrp' : 'rd-popup-wrp dn'}>
                            <div className="rd-popup-hd">
                                <div className="rd-popup-close">
                                    <div className='reg-close' onClick={closeModel}>
                                        <Icon path={mdiClose} size={'24px'} color="#222" />
                                    </div>
                                </div>
                            </div>
                            <div className="rd-popup-content order-slot-cnt">
                                <p className='zipcode-popup-mssage'>Your password has been reset successfully</p>
                            </div>
                        </div>
                    </div>
                    <div className='ok-button' style={{ width: "21%", margin: "0 auto" }}>
                        <Link to="/signin"><button className="btn btn-primary btn-ok"> OK </button></Link>
                    </div>
                </Modal>
            </React.Fragment>

        );
    }

    export default connect(mapStateToProps)(ForgotPasswordOtp);