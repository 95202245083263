import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = 'https://api.ridedoc.co/';
const API_ROOT = process.env.REACT_APP_RIDEDOC_API_BASE;

const encode = encodeURIComponent;
const responseBody = res => {
  // console.log("responseBody", res);
  // console.log(res)
  // debugger;
  return res.body
};


// const errorHandler = error => {
//   console.log("errorHandler");
//   console.log(error);
//   let data = {
//     status: error.response?.status,
//     message: error.response?.body?.message ? error.response.body.message : "",
//     data: error.response?.body
//   };
//   return data
// }

let token = null;
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();
const tokenPlugin = req => {
  // console.log(token);
  // debugger
  if (token) {
    req.set('x-access-token', token);
  }
}

const headers = {
  'Content-Type': 'application/json',
  'Authorization': token,
  'x-request-time' : new Date().toString(),
  'x-request-timezone': timeZone
}


// const headers = {
//   'Access-Control-Allow-Origin': '*',
//   'accept': 'application/json',
//   'Content-Type': 'application/json'
// };

// const refreshTokenPlugin = () => {
//   console.log("SDFSDFsdfsdf");
//   // debugger;
//   const tokenData = JSON.parse(localStorage.getItem("token"));
//   const refreshToken = tokenData && tokenData["refresh_token"];
//   const accessToken = tokenData && tokenData["access_token"];

//     if(accessToken){
//       if (jwt_decode(accessToken).exp < Date.now() / 1000) {
//         // requests.set('Authorization', `Bearer ${refreshToken}`);
//         requests.postToken('/auth/refresh', refreshToken)
//         // debugger;
//       }
//     }
// }

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  postToken: (url, token) =>
    superagent.post(`${API_ROOT}${url}`).set('Authorization', `Bearer ${token}`).then(responseBody)
};

const Auth = {
  current: () =>
    requests.get('api/user', { headers: headers }
    ),
  // login: (email, password, role) => (
  //   requests.post('api/auth/login', { headers }, { email: email, password: password, role: role })
  // ),
  login: (email, password, role) => {
   // console.log('Email inside login:', email); // Add this line to log the email value

    return requests.post('api/auth/login', { email: email, password: password, role: role },
      { headers: headers }
    );
  },
  appleRegister: (data) =>{
    requests.post('api/auth/social/apple', data)
  },

  googleRegister: (token, userRole) =>
    requests.post('api/auth/social/google', { token: token, role: userRole }),
  register: (userRole, password, email) =>
    requests.post('api/auth/register'
      , { role: userRole, password: password, email: email }),
  active: () =>
    requests.post('/api/auth/activate', {}),
  forgotpassword: (emailid) =>
    requests.post('api/auth/reset-password', { email: emailid }),

  authRefreshToken: (tokenRefreshToken) =>
    requests.postToken('api/auth/new-access-token', tokenRefreshToken),
  save: user =>
    requests.put('/user', { headers: headers }, { user })
};

const Services = {
  current: () =>
    // requests.get('/services')  
    requests.get('api/service?populate=plans')
};

const ProfileInformation = {
  supportinfo: () =>
    requests.get('/user'
    ),
  supportform: (data) =>
    requests.put('/users',
      {
        address: {
          address: data.address,
          city: data.city,
          country: 'US',
          zipcode: data.zip_code
        },
        lastName: data.last_name,
        firstName: data.first_name,
        vehicle_info: {
          type: data.biketype.value,
          make: data.bikemake.value,
          model: data.bikemodel.value
        },
        mobile: data.phone_number
      }),
  supportzipcode: (data) =>
    requests.get(`/country/zipcode?zipcode=` + data + `&country_code=US`, { headers: headers }
    ),
};

const Contactus = {
  supportinfo: () =>
    requests.get('/support?type=user'),
  supportform: (formdata) =>
    requests.post('api/support', formdata),
  imageupload: (formdata) =>
    requests.post('/orders/image', formdata)
};

const Stripe = {
  public_key_get: () =>
    requests.get('/stripe/public-key')
};

const Vehicles = {
  types: (data) =>
    requests.get('/vehicle/types', { headers: headers }
    ),
  makes: (data) =>
    requests.get('/vehicle/make', { headers: headers }),
  models: (data) =>
    requests.get('/vehicle/model', { headers: headers })
};

const OrderBooking = {
  orderform: (data) =>
    requests.post('/orders', data),
  slot_availability: (country, zipcode, plan_id, orderdate) =>
    requests.get('check-slot/availability?country_code=' + country + '&zipcode=' + zipcode + '&plan_id=' + plan_id + '&date=' + orderdate),

}

const OrderHistory = {
  orderlist: (data) => {
    // console.log(data);
    requests.get('api/order/' + data.id)
  }
};

export default {
  Auth,
  Services,
  Contactus,
  ProfileInformation,
  OrderBooking,
  Vehicles,
  Stripe,
  OrderHistory,
  // refreshTokenPlugin,
  setToken: _token => { token = _token; }
};
