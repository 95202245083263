import agent from '../agent';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { APP_LOAD, REDIRECT, REFRESH_TOKEN } from '../constants/actionTypes';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './Private';
import CustomFunction from './customFuction';

import Home from '../Views/Home/Home';
import Signup from '../Views/Auth/Signup/Signup';
import Signin from '../Views/Auth/Signin/Signin';
import LandingPage from '../Views/LandingPage/LandingPage';
import Faq from '../Views/faq/faq';
import { ParallaxProvider } from 'react-scroll-parallax';
import ForgotPassword from '../Views/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../Views/Auth/ResetPassword/ResetPassword';
import ProfileMenu from '../Views/ProfileMenu/ProfileMenu';
import ProfileInformation from '../Views/ProfileInformation/ProfileInformation';
// import ProfileInfo from '../Views/ProfileInformation/ProfileInfo';
import CardInformation from '../Views/ProfileInformation/CardInformation';
import TuneUp from '../Views/TuneUp/TuneUp';
import BookingTime from '../Views/BookingTime/BookingTime';
// import BookingTimeSuccess from '../Views/BookingTimeSuccess/BookingTimeSuccess';
import OrderHistory from '../Views/OrderHistory/OrderHistory';
import OrderHistoryDetails from '../Views/OrderHistoryDetails/OrderHistoryDetails';
import ContactUs from '../Views/ContactUs/ContactUs';
import Footer from './Footer/Footer';
import NotFound from '../Views/NotFound';
// import PrivacyPolicy from '../Views/PrivacyPolicy';
// import TermsConditions from '../Views/TermsConditions';
import OrderEdit from '../Views/BookingTime/OrderEdit';
import ForgotPasswordOtp from '../Views/Auth/ForgotPassword/ForgotPasswordOtp';
import PrivacyPolicy from '../Views/TermsPolicy/PrivacyPolicy';
import TermsConditions from '../Views/TermsPolicy/TermsConditions';



const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo
  }};

  const mapDispatchToProps = dispatch => ({
    onLoad: (payload, token) =>
      dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),

    onRefreshTokenLoad: (payload) =>
      dispatch({ type: REFRESH_TOKEN, payload, skipTracking: true }),
      
    onRedirect: () =>
      dispatch({ type: REDIRECT })
  });

const App = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {

      // dispatch({type: STRIPE_PUBLIC_KEY_LOAD, payload: agent.Stripe.public_key_get()})

      // page initial render
      const refreshToken = JSON.parse(localStorage.getItem("refresh_token"));
      const accessToken = JSON.parse(localStorage.getItem("access_token"));
      // Refresh token expiry 
      if (accessToken) {
          //props.onRefreshTokenLoad(agent.Auth.authRefreshToken(refreshToken));
      }    
      // get user data
      setTimeout(() => {
        const token = JSON.parse(localStorage.getItem("access_token"));
        agent.setToken(token);
        props.onLoad(token ? agent.Auth.current() : null, token);
      }, 3000);


      setInterval(() => {
        const refreshToken = JSON.parse(localStorage.getItem("refresh_token"));
        const accessToken = JSON.parse(localStorage.getItem("access_token"));
        // Refresh token expiry 
        if (accessToken) {
            //props.onRefreshTokenLoad(agent.Auth.authRefreshToken(refreshToken));
        }    
        // get user data
        setTimeout(() => {
          const token = JSON.parse(localStorage.getItem("access_token"));
          agent.setToken(token);
          props.onLoad(token ? agent.Auth.current() : null, token);
        }, 3000);
      }, 35000000);

      // if(Authorization.isAuthenticated()) {
      //   console.log("isAuthenticated")
      //   console.log(Authorization.isAuthenticated())
      // }
    }, []);

    // 

    // let authToken = JSON.parse(localStorage.getItem("access_token"));

    return (
      // <React.Fragment>
      <ParallaxProvider>

              

          <Routes>   
              
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/home" title={"Home"} element={<Home />} />
            {/* <Route exact path="/privacy-policy" title={"Home"} element={<PrivacyPolicy />} /> */}
            {/* <Route exact path="/terms-conditions" title={"Home"} element={<TermsConditions />} /> */}
            <Route exact path="/signup" title={"Signup"} element={<Signup />} />
            <Route exact path="/signin" title={"Signin"} element={<Signin />} />
            <Route exact path="/faq" title={"faq"} element={<Faq />} />
            <Route exact path="/forgot-password" title={"Forgot Password"} element={<ForgotPassword />} />
            <Route exact path="/forgot-otp-password" title={"Forgot Password"} element={<ForgotPasswordOtp />} />
            {/* <PrivateRoute component={Dashboard} path="/profile" exact /> */}
            <Route exact path="/profile" title={"Profile"} element={<PrivateRoute><ProfileMenu /></PrivateRoute>} />
            <Route path="/profile-information" title={"Profile Information"} element={<PrivateRoute><ProfileInformation /></PrivateRoute>} />
            {/* <Route path="/profile-info" title={"Profile Information"} element={<PrivateRoute><ProfileInfo /></PrivateRoute>} /> */}
            <Route path="/card-information" title={"Card Information"} element={<PrivateRoute><CardInformation /></PrivateRoute>} />
            
            <Route path="/order-history" title={"Order History"} element={<PrivateRoute><OrderHistory /></PrivateRoute>} />
            <Route path="/contact-us" title={"Contact Us"} element={<PrivateRoute><ContactUs /></PrivateRoute>} />
            <Route exact path="/services/:servicename" element={<PrivateRoute><TuneUp /></PrivateRoute>} />
            <Route path="/booking-time" element={<PrivateRoute><BookingTime /></PrivateRoute>} />
            {/* <Route path="/booking-time-success" element={<PrivateRoute><BookingTimeSuccess /></PrivateRoute>} /> */}
            <Route path="/order-history" element={<PrivateRoute><OrderHistory /></PrivateRoute>} />
            <Route path="/order-details/:orderid" element={<PrivateRoute><OrderHistoryDetails /></PrivateRoute>} />
            <Route exact path="/order-edit/:orderid" element={<PrivateRoute><OrderEdit /></PrivateRoute>} />
            <Route path="/contact-us" element={<PrivateRoute><ContactUs /></PrivateRoute>} />
            <Route path="*" element={<NotFound/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
            <Route path='/terms-conditions' element={<TermsConditions/>} />
          </Routes>
          {/* {stripeApiKey && (
                <Elements stripe={loadStripe(stripeApiKey)}>
                  <CheckoutForm />
                </Elements>
              )} */}
        {/* {(CustomFunction.isAuthenticated()) &&
          <Footer
            appName={props.appName}
            currentUser={props.currentUser} />
        } */}
      </ParallaxProvider>
      
    );
  }
export default connect(mapStateToProps, mapDispatchToProps)(App);
