import {
    STRIPE_PUBLIC_KEY_LOAD,
    STRIPE_PUBLIC_KEY_UNLOAD
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case STRIPE_PUBLIC_KEY_LOAD:
        return {
          ...state,
          inProgress: null,
          errors: action.error ? action.payload.message : null,
          data: action.error ? null : action.payload
        };
      case STRIPE_PUBLIC_KEY_UNLOAD:
        return {};
      default:
        return state;
    }
  };
  