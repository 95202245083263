import {
    SERVICE_LOAD,
    SERVICE_UNLOAD,
    REPLACE_PARTS_DATA,
    IMAGEUPLOAD,
    IMAGEUPLOAD_UNLOAD
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case IMAGEUPLOAD: 
        return {
          ...state,
          // replace_parts: action.payload ? action.payload.replace_parts : null,
          file_upload: action.payload ? action.payload.image_id : null,
          file_upload_success: action.payload ? action.payload.message : null,
          service: null,
          inProgress: false,
          orderService: action.payload ? action.payload : null,
          errors: action.error ? action.payload.message : null
        };
      case IMAGEUPLOAD_UNLOAD:
        return {
          ...state,
          file_upload: null,
          file_upload_success: null,
          service: null,
          inProgress: false,
          orderService: action.payload ? action.payload : null,
          errors: action.error ? action.payload.message : null
        }
      default:
        return state;
    }
  
    return state;
  };
  