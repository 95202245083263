import {
  SERVICE_LOAD,
  SERVICE_UNLOAD,
  REPLACE_PARTS_DATA,
  IMAGEUPLOAD,
  IMAGEUPLOAD_UNLOAD
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_LOAD:
      //console.log(action);
     // console.log(action.error);
     // console.log(action.payload.message);
      // debugger;
      return {
        ...state,
        service: action.payload ? action.payload : null,
        file_upload: null,
        file_upload_success: null,
        inProgress: false,
        orderService: action.payload ? action.payload : null,
        errors: action.error ? action.payload.message : null
      };
    case SERVICE_UNLOAD:
      return {};

    case REPLACE_PARTS_DATA:
      return {
        ...state,
        replace_parts: action.payload ? action.payload.replace_parts : null,
        file_upload: null,
        file_upload_success: null,
        service: action.payload ? action.payload.service : null,
        service_id: action.payload ? action.payload.service_id : null,
        inProgress: false,
        orderService: action.payload ? action.payload : null,
        errors: action.error ? action.payload.message : null
      };
    // case IMAGEUPLOAD: 
    //   return {
    //     ...state,
    //     // replace_parts: action.payload ? action.payload.replace_parts : null,
    //     file_upload: action.payload ? action.payload.image_id : null,
    //     file_upload_success: action.payload ? action.payload.message : null,
    //     service: null,
    //     inProgress: false,
    //     orderService: action.payload ? action.payload : null,
    //     errors: action.error ? action.payload.message : null
    //   };
    // case IMAGEUPLOAD_UNLOAD:
    //   return {
    //     ...state,
    //     file_upload: null,
    //     file_upload_success: null,
    //     service: null,
    //     inProgress: false,
    //     orderService: action.payload ? action.payload : null,
    //     errors: action.error ? action.payload.message : null
    //   }
    default:
      return state;
  }

  return state;
};
