import { CardElement, CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { APP_LOAD, USER_LOAD } from '../../constants/actionTypes';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import agent from '../../agent';
import { mdiClipboardTextClockOutline, mdiLightbulbFluorescentTubeOutline } from "@mdi/js";




const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#222222",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#666" }
    }
  }
}

const mapStateToProps = state => ({ ...state });


const mapDispatchToProps = dispatch => ({
  onLoad: (payload, setData) => {
    // setData(payload); // update the data state in the parent component
    dispatch({ type: USER_LOAD, payload, skipTracking: true });
  }
});



const PaymentForm = ({ props, setData, setCardFieldEmpty, onSubmit, setIsOpen, setEditIsOpen, location,card }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const locationdata = location;
 // console.log("Location data as props", locationdata);
  const cameFromServicePage = locationdata?.state?.fromServicePage;
  let pathname = locationdata?.state?.servicePagePath;
  if (pathname) {
    pathname = pathname.trim().split("/");
   // console.log(pathname);
  }
  // const [success, setSuccess] = useState(false)
  const [stripeClientSecretKey, setStripeClientSecretKey] = useState("");
  const [formSuccessMessage, setformSuccessMessage] = useState("");
  const [formErrorMessage, setformErrorMessage] = useState("");
  const [checkoutData, setCheckoutData] = useState('');
  const [eventComplete, setEventComplete] = useState(false);
  const [userFormStatusMessage, setUserFormStatusErrorMessage] = useState('');


 // console.log("props on checkout", props);

  // console.log("Data from profile on checkout form", setData);



  const getStripeClientKey = async () => {
    const accessToken = JSON.parse(localStorage.getItem("access_token"));
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();
    try {
      const val = {
        // stripe_user_id: props?.common?.currentUser?.results?.stripe_user_id
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          'x-access-token': `${accessToken}`,
          'x-request-time' : new Date().toString(),
          'x-request-timezone': timeZone
        },
      };
      const response = await axios.post(process.env.REACT_APP_RIDEDOC_API_ROOT + 'payment/setup-intent', val, config);
    //  console.log(" STRIPE RESPONSE", response);
      setStripeClientSecretKey(response?.data?.message?.client_secret);
    } catch (error) {
     // console.log(error)
    }
  };


  useEffect(() => {

    // setTimeout(() => {
    //   setLoading(false)
    // }, 1500);

    // props.userLoad(agent.Auth.current());

    props.onLoad(agent.Auth.current());

    setformSuccessMessage("");
    setformErrorMessage("");

    getStripeClientKey();

  }, []);



  const handleSubmit = async (e) => {

    e.preventDefault();

    if (!stripe || !elements) {
      // alert("stripe return")
      return;
    }

    const cardElement = elements.getElement("card");

   // console.log("Check out data", checkoutData);

    if (eventComplete === false) {
      setformErrorMessage('Kindly fill card data');
      if (checkoutData.empty === false && checkoutData.error) {
        setformErrorMessage('');
        setformErrorMessage(checkoutData.error.message);
      }
    }
    // if (checkoutData.empty === false && checkoutData.error) {
    //   setformErrorMessage(checkoutData.error.message);
    // }

    else if (eventComplete) {
    //  console.log(eventComplete);

      setformErrorMessage('');
      // if (eventComplete === false) {
      //   // alert("card return")
      //   setformErrorMessage('Kindly fill card data');
      // }
      const result = await stripe.createToken(cardElement)
        .then((payload) => { return payload.token });

     // console.log("create token", result);

      let userCardDetails = '';
      if(!result){
        setformErrorMessage('Please enter valid card details.');
        setTimeout(()=>{
          setformErrorMessage('');
        },[5000]);
      }else{
      userCardDetails = result?.id;
      }
      // setUserCardDetails(result?.id);
      // const clientSecrectKey = stripeClientSecretKey;
      // var email = "tam@mailinator.com";

      // stripe
      //   .confirmCardSetup(clientSecrectKey, {
      //     payment_method: {
      //       // card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
      //       card: elements.getElement(CardElement)
      //       // billing_details: { email: email }
      //     }
      //   })
      //   .then(function (result) {
      //     if (result.error) {
      //       setformErrorMessage(result.error)
      //     } else {
      //       orderComplete(stripe, clientSecrectKey);
      //     }
      //   });



      // var addPaymentMethod = async (setupvalue) => {
      //   const accessToken = JSON.parse(localStorage.getItem("access_token"));
      //   try {
      //     const config = {
      //       headers: {
      //         "Content-Type": "application/json",
      //         'x-access-token': `${accessToken}`
      //       },
      //     };
      //     const setupvaluedata = setupvalue;
      //     const { data } = await axios.post("https://ec2-54-244-40-38.us-west-2.compute.amazonaws.com/api/users/add/payment-method", setupvaluedata, config);
      //     setformSuccessMessage(data.message);
      //   } catch (error) {
      //     setformErrorMessage(error.message)
      //     console.log(error)
      //   }
      // };

      /* Shows a success / error message when the payment is complete */
      // var orderComplete = function (stripe, clientSecret) {
      //   stripe.retrieveSetupIntent(clientSecret).then(function (result) {
      //     var setupvalue = result.setupIntent;
      //     if (setupvalue.status == "succeeded") {
      //       var setupvalue = {
      //         "id": setupvalue.id,
      //         "client_secret": setupvalue.client_secret,
      //         "created": setupvalue.created,
      //         "payment_method": setupvalue.payment_method,
      //         "status": setupvalue.status
      //       }
      //     } else {
      //       setformErrorMessage("We are facing some technical issue, please re-enter card details.")
      //     }
      //     addPaymentMethod(setupvalue);
      //   });
      // };
      let val = {

        // mobile: props?.common?.currentUser?.results?.mobile ? props?.common?.currentUser?.results?.mobile : '',
        // first_name: props?.common?.currentUser?.results?.first_name ? props?.common?.currentUser?.results?.first_name : '',
        // last_name: props?.common?.currentUser?.results?.last_name ? props?.common?.currentUser?.results?.last_name : '',
        // address: {

        //   address: props?.common?.currentUser?.results?.address?.address ? props?.common?.currentUser?.results?.address?.address : '',
        //   city: props?.common?.currentUser?.results?.address?.city ? props?.common?.currentUser?.results?.address?.city : '',
        //   state: props?.common?.currentUser?.results?.address?.state ? props?.common?.currentUser?.results?.address?.state : '',
        //   country: 'United States',
        //   zipcode: props?.common?.currentUser?.results?.address?.zipcode ? props?.common?.currentUser?.results?.address?.zipcode : '',
        // },
        // card: result?.card ? result?.card : null,
        stripe_user_id: props?.common?.currentUser?.results?.stripe_user_id,
        // vehicle_info: props?.common?.currentUser?.results?.vehicle_info?._id,
      }

      if (userCardDetails) {

        val.card_token_id = userCardDetails;

        const accessToken = JSON.parse(localStorage.getItem("access_token"));
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              'x-access-token': `${accessToken}`,
              'x-request-time' : new Date().toString(),
              'x-request-timezone': timeZone
            },
          };

          const res = await axios.patch(process.env.REACT_APP_RIDEDOC_API_ROOT + `user/` +
            props?.common?.currentUser?.results?._id,
            val,
            config
          );

          setData(res.data);
          // Check if the user came from the service page (Page X)

          if (cameFromServicePage) {
            navigate('/home');
          }
          else {
            setIsOpen(false);
            setEditIsOpen(false);
            setformSuccessMessage(res?.data?.message)
            setCardFieldEmpty(''); // pass the response.data and setData to onLoad

            // setData(res?.data);
            navigate('/profile-information');
          }
        }
        catch (error) {
          // setLoading(false)
        //  console.log("submit-errors", error);
          setformErrorMessage(error?.response?.data?.message);
          setUserFormStatusErrorMessage(error?.response?.data?.message);
          return false;
          // setProfileInfoError(error.response.data.message)
          // setProfileInfoError(error.response.message)
        }
      }
    }

    else{
      setformErrorMessage('');
    }
  }
 // console.log("FORM success message", formSuccessMessage);

 // console.log("Form Error Message", formErrorMessage);
  const handleCardChange = async (event) => {
   // console.log("Event", event);
    setCheckoutData(event);
    card(true)
    const { brand, cardNumber, cardExpiry, cardCvc } = event.error || {};

    // Access card information
    const { complete } = event;

    // if (elementType === "cardNumber") {
    //   console.log("Card Number:", value);
    // } else if (elementType === "cardExpiry") {
    //   console.log("Card Expiry:", value);
    // } else if (elementType === "cardCvc") {
    //   console.log("Card CVC:", value);
    // }
    setEventComplete(complete);
    // setformErrorMessage(event.error);

  }



  return (
    <div className="checkout-main">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="form-control">
            <CardElement onChange={handleCardChange} options={CARD_OPTIONS} />
            {/* <CardNumberElement options={CARD_OPTIONS} /> */}
          </div>
        </div>

        {/* <div className="row">
            <div className="col">
              <div className="form-group">
                <div className="form-control">
                  <CardExpiryElement options={CARD_OPTIONS} />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <div className="form-control">
                  <CardCvcElement options={CARD_OPTIONS} />
                </div>
              </div>
            </div>
          </div> */}

        <div className="form-group">
          <button className="btn btn-primary" type='submit'>SUBMIT</button>
          {/* {userFormStatusMessage && <span className='error' style={{ marginTop: 15, color: 'white' }}>{userFormStatusMessage.replace(/^Error: /i, '')}</span>} */}
          {formErrorMessage && <span className='error' style={{ marginTop: 15, color: 'white' }}>{formErrorMessage}</span>}
        </div>
      </form>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);