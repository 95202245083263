
import React, { useState, useEffect, createRef } from 'react';
import './landingpage.scss';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { Helmet } from "react-helmet";
// import PrivacyPolicy from '../TermsPolicy/PrivacyPolicy';

function LandingPage() {
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);

    // return scrollDirection;
  };

  const scrollDirection = useScrollDirection();

  // const ServiceAvailabilityRef = useRef(null);
  const refJSON = {
    "Home": useRef(null),
    "OurServices": useRef(null),
    "OurMission": useRef(null),
    "RidersHowItWorks": useRef(null),
    "RideDocsHowItWorks": useRef(null),
    "ServiceAvailability": useRef(null),
    "Price": useRef(null),
    "Resource": useRef(null),
    "ContactUs": useRef(null)
  }
  useEffect(() => {
    console.log("LOC HASH CALLED")
    console.log(refJSON[window.location.hash.slice(1)])
    if (window.location.hash) {
      refJSON[window.location.hash.slice(1)]?.current?.scrollIntoView();
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const hide = () => setIsOpen(false);
  const show = () => setIsOpen(true);


  // Close the dropdown if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

  const navigation = [
    { link: '#Home', text: 'Home' },
    { link: '#OurServices', text: 'Our Services' },
    { link: '#OurMission', text: 'Our Mission' },
    { link: '#Price', text: 'Service Prices' },
    { link: '#RidersHowItWorks', text: 'Riders -  How It Works' },
    { link: '#RideDocsHowItWorks', text: 'RideDocs -  How It Works' },
    { link: '#ServiceAvailability', text: 'Service Availability' },
    { link: '#Resource', text: 'Resources' },
    { link: '#ContactUs', text: 'Contact Us' },
    { link: '/faq', text: 'FAQ' },
  ];
  // const location = useLocation();
  // if (location.pathname !== '/') {

  return (
		<div className="landing_main">
			<header className={`${scrollDirection === "down" ? "hide" : "show"} landing_header`}>
				<div className="header">
					<div className="hd-logo">
						<a href="/">
							<img src="../images/logo.png" alt="RideDoc Logo" />
						</a>
					</div>

					<div className="hd-nav">
						<button className="nav-icon" onClick={toggle}>
							<span className={`menu ${isOpen ? "cross" : "hamburger"}`}>
								{isOpen ? <img src="images/close-ic.svg" alt="menu close icon" /> : <img src="images/menu.svg" alt="menu icon" />}
							</span>
						</button>
						<nav className={`nav-data ${isOpen ? "show" : "hide"}`} id="myDropdown">
							<ul>
								{navigation.map((nav) => (
									<li key={nav.text}>
										<a href={nav.link} onClick={toggle} onBlur={hide} onFocus={show}>
											{nav.text}
										</a>
									</li>
								))}
								{/*  */}
							</ul>
						</nav>
					</div>
				</div>
			</header>

			<section className="banner" id="Home" ref={refJSON.Home}>
				<div className="banner-section">
					<h1>
						<img src="images/logo-slogan.png" alt="logo" />
					</h1>
					<Link to="/signin">
						<button href="#" title="RideDoc" className="btn btn-primary home_btn">
							SCHEDULE NOW
						</button>
					</Link>
				</div>
			</section>

			<section className="our-services" id="OurServices" ref={refJSON.OurServices}>
				<div className="our-services-section">
					<h2>Our Services</h2>
					<h3>
						<img src="images/ourservices-text.png" alt="Mobile bike mechanics at your doorstep" />
					</h3>
					<div className="our-services-list">
						<ul>
							<li>
								<img src="images/ic-our-tuneups.svg" alt="Tune-Ups (Basic, Standard, or Advanced)" />
								<h3>Tune-Ups (Basic, Standard, or Advanced)</h3>
							</li>
							<li>
								<img src="images/ic-our-gearbreak-adjustment.svg" alt="Gear & Break Adjustment" />
								<h3>Gear & Break Adjustment</h3>
							</li>
							<li>
								<img src="images/ic-our-repair-parts.svg" alt="Repair/ Replace Parts" />
								<h3>Repair/ Replace Parts</h3>
							</li>
							<li>
								<img src="images/ic-our-bikeassembly.svg" alt="Bike Assembly" />
								<h3>Bike Assembly</h3>
							</li>
							<li>
								<img src="images/ic-our-bikepackingservices.svg" alt="Bike Packing Services" />
								<h3>Bike Packing Services</h3>
							</li>
							<li>
								<img src="images/ic-our-otherservices.svg" alt="Other Service" />
								<h3>Other Service</h3>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="our-mission" id="OurMission" ref={refJSON.OurMission}>
				<div className="our-mission-content">
					<h2>Our Mission</h2>
          <h3 className="mission-head">RideDoc is a DC based company with a mission to: </h3>
					<div className="our-mission-dt">
						<div className="our-mission-line">
							<ul>
								<li>
									<div className="dt-wrp">
										<div className="dt">
											<div className="dt-in">
												<h3>MECHANICS</h3>
												<p>
													Mechanics are integral to the biking experience. RideDoc recognizes that and shares majority of
													your repairs and services costs with mechanics; enabling a lucrative career. Additionally, with
													RideDoc mechanics have complete flexibility to manage their own schedules, doing what they do
													best!
												</p>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div className="dt-wrp">
										<div className="dt">
											<div className="dt-in">
												<h3>BIKERS</h3>
												<p>
													Biker safety is of utmost importance to us! Servicing and repairing your bikes regularly ensure
													enjoyable and safe rides. RideDoc eliminates hassles of transporting your bike to a store, long
													wait times, and pickups. A seamless online booking allows a mobile mechanic to meet you at your
													home, office, or any location of choice to deliver any of our services at fair and transparent
													prices.
												</p>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div className="dt-wrp">
										<div className="dt">
											<div className="dt-in">
												<h3>COMMUNITY</h3>
												<p>
													Biking is an eco-friendly mode of transport, and a great exercise. By improving ease of access to
													mechanics who improve safety of bikes, we wish to promote healthier lifestyles, and a cleaner,
													greener environment.
												</p>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

      <section className="price" id="Price" ref={refJSON.Price}>
				<div className="price-content">
					<h2>Service Prices</h2>
					<h4 className="price-subhead">
            Our Prices are comparable to the average price of Bike stores in the area
					</h4>
				</div>

				<div className="price_tab">
					<table>
						<thead>
							<tr>
								<th style={{ textAlign: "left" }}>Services</th>
								<th>Regular Bike</th>
								<th>eBike</th>
								<th>Kids Bike</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{ textAlign: "left" }}>Tune-Up (Basic)</td>
								<td>$90</td>
								<td>$135</td>
								<td>$45</td>
							</tr>
							<tr>
								<td style={{ textAlign: "left" }}>Tune-Up (Standard)</td>
								<td>$120</td>
								<td>$180</td>
								<td>$60</td>
							</tr>
							<tr>
								<td style={{ textAlign: "left" }}>Tune-Up (Advanced)</td>
								<td>$160</td>
								<td>$240</td>
								<td>$80</td>
							</tr>
							<tr>
								<td style={{ textAlign: "left" }}>Repair/Replace Parts</td>
								<td>$70</td>
								<td>$105</td>
								<td>$35</td>
							</tr>
							<tr>
								<td style={{ textAlign: "left" }}>Gear & Brake Adjustment</td>
								<td>$70</td>
								<td>$105</td>
								<td>$35</td>
							</tr>
							<tr>
								<td style={{ textAlign: "left" }}>Bike Assembly</td>
								<td>$120</td>
								<td>$180</td>
								<td>$60</td>
							</tr>
							<tr>
								<td style={{ textAlign: "left" }}>Bike Packaging Services</td>
								<td>$140</td>
								<td>$210</td>
								<td>$70</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='price-footer'>
					*please read service description before placing an order for specifics on what it includes and excludes
				</div>
			</section>

			<section className="how-it-works" id="RidersHowItWorks" ref={refJSON.RidersHowItWorks}>
				<div className="how-it-works-content">
					<h2>Riders - How It Works</h2>
					<div className="how-it-works-content-dt">
						<ul className="how-it-works-content-list">
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span>Step 1</span>
									</div>
									<div className="how-dt-cont">
										<h3>Setup Account</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span>Step 2</span>
									</div>
									<div className="how-dt-cont">
										<h3>Choose: Service Type, Location, Date & Time</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span>Step 3</span>
									</div>
									<div className="how-dt-cont">
										<h3>Submit Service Request</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span>Step 4</span>
									</div>
									<div className="how-dt-cont">
										<h3>RideDoc Accepts Service Request</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span>Step 5</span>
									</div>
									<div className="how-dt-cont">
										<h3>RideDoc Completes Service</h3>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="how-it-works docs-how-it-works-app" id="RideDocsHowItWorks" ref={refJSON.RideDocsHowItWorks}>
				<div className="how-it-works-content">
					<h2>RideDocs - How It Works</h2>
					<div className="how-it-works-content-dt">
						<ul className="how-it-works-content-list">
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span data-content="1">1</span>
									</div>
									<div className="how-dt-cont">
										<h3>Setup Account</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span data-content="2">2</span>
									</div>
									<div className="how-dt-cont">
										<h3>Understand Model & Terms Of Service</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span data-content="3">3</span>
									</div>
									<div className="how-dt-cont">
										<h3>Customize Service Preferences (e.g. Service Range)</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span data-content="4">4</span>
									</div>
									<div className="how-dt-cont">
										<h3>Select Open Orders</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span data-content="5">5</span>
									</div>
									<div className="how-dt-cont">
										<h3>Complete Service</h3>
									</div>
								</div>
							</li>
							<li className="how-it-works-content-list-li">
								<div className="how-dt">
									<div className="how-dt-ic">
										<span data-content="6">6</span>
									</div>
									<div className="how-dt-cont">
										<h3>Get Paid For Completed Services On Weekly Basis</h3>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="servicesav" id="ServiceAvailability" ref={refJSON.ServiceAvailability}>
				<div className="servicesav-content">
					<h2>Service Availability</h2>
					<div className="servicesav-map">
						<img src="images/ridedoc-loc-map.png" alt="map" />
						<div className="servicesav-map-av-lst">
							<span className="servicesav-map-av-lst-cr">Currently available:</span>
							<div className="servicesav-map-cm-soon">
								<span className="servicesav-map-cm-soon-txt">Coming soon:</span>
								<span className="servicesav-map-cm-soon-loc">Washington D.C.</span>
							</div>
						</div>
					</div>
				</div>
			</section>



			<section className="resource" id="Resource" ref={refJSON.Resource}>
				<div className="resource-content">
					<h2>Resources</h2>
					<div className="resContainer">
						<a href="https://waba.org/" target="_blank">
							<img className="resImgItem" src="images/resources/waba.jpg" alt="WABA" />
						</a>
						<a href="https://godcgo.com/" target="_blank">
							<img className="resImgItem" src="images/resources/godcgo.jpg" alt="GoDCGo" />
						</a>
						<a href="https://ddot.dc.gov/" target="_blank">
							<img className="resImgItem" src="images/resources/ddot.jpg" alt="DDOT" />
						</a>
						<a href="https://www.bikearlington.com/" target="_blank">
							<img className="resImgItem" style={{ background: "white", padding: "30px"}}
              src="images/resources/barling.jpg" alt="Bike Arlington" />
						</a>
						<a href="https://unlimitedbiking.com/washington-dc/" target="_blank">
							<img className="resImgItem" src="images/resources/unlimbike.jpg" alt="Unlimited Biking" />
						</a>
						<a href="https://capitalbikeshare.com/"  target="_blank">
							<img className="resImgItem" src="images/resources/capbikeshare.jpg" alt="Capital BikeShare" />
						</a>
						<a href="https://www.facebook.com/DCBikeParty/" target="_blank">
							<img className="resImgItem" src="images/resources/dcbikeparty.jpg" alt="DC Bike Party" />
						</a>
						<a href="https://bikeleague.org/" target="_blank">
							<img className="resImgItem" src="images/resources/bikeleague.jpg" alt="Bike League" />
						</a>
						<a href="https://www.peopleforbikes.org/" target="_blank">
							<img className="resImgItem" src="images/resources/peepsforbike.jpg" alt="People for Bikes" />
						</a>
					</div>
				</div>
			</section>

			<section className="contactus" id="ContactUs" ref={refJSON.ContactUs}>
				<div className="contactus-content">
					<h2>Contact Us</h2>
					<h3>We’re here – Share Your Excitement, Feedback, Questions with us!</h3>
					<div className="contactus-mail">
						<a href="mailto:hello@ridedoc.co">
							<img src="images/contactus-ic.png" alt="mail icon" />
							<span> - hello@ridedoc.co</span>
						</a>
					</div>
				</div>
				<div className="footerwrp">
					<footer className="footer">
						<div className="footer-in">
							<ul className="footer-menu footer-menu-rgt">
								<li className="footer-findtxt">
									<span>Join Us On</span>
								</li>
								<li>
									<ul className="footer-social">
										<li>
											<a href="https://www.facebook.com/profile.php?id=100089147461088">
												<img src="images/social-facebook-ic.png" alt="facebook" />
											</a>
										</li>
										<li>
											<a href="https://www.linkedin.com/company/91621116/">
												<img src="images/social-linkedin-ic.png" alt="facebook" />
											</a>
										</li>
										<li>
											<a href="https://instagram.com/ridedocs?igshid=OGQ5ZDc2ODk2ZA==">
												<img src="images/social-insta-ic.png" alt="facebook" />
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</div>
						<div className="footer-btm">
							<p>&copy; 2022 RideDoc LLC.</p>
							<ul className="footer-menu">
								<li className="footer-privacy">
									<Link to="/privacy-policy">Privacy Policy</Link>
								</li>
								<li className="footer-terms">
									<Link to="/terms-conditions">Terms</Link>
								</li>
							</ul>
						</div>
					</footer>
				</div>
			</section>
		</div>
  );
                
}

export default LandingPage;