import auth from './reducers/auth';
import common from './reducers/common';
import services from './reducers/services';
import contactus from './reducers/contactus';
import order from './reducers/order';
import vehicles from './reducers/vehicles';
import profileInformation from './reducers/profileInformation';
import imageupload from './reducers/imageupload';
import orderhistory from './reducers/orderhistory';
import stripe from './reducers/stripe';
import { routerReducer } from 'react-router-redux';

// combineReducers will be handled internally by configureStore
const createRootReducer = (history) => ({
  auth,
  common,
  services,
  orderhistory,
  stripe,
  contactus,
  order,
  vehicles,
  profileInformation,
  imageupload,
  router: routerReducer
});

export default createRootReducer