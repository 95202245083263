import React from 'react'
import { Navigate } from 'react-router-dom'
import CustomFunction from './customFuction';

import '../scss/base.scss';


export default function PrivateRoute({ children }) {
  if (!CustomFunction.isAuthenticated()) {
    return <Navigate to='/signin' />
  }
  return children;
}