import {
  PROFILE_INFORMATION_LOAD,
  PROFILE_INFORMATION_UPDATE_LOAD,
  PROFILE_INFORMATION_UPDATE_ZIPCODE
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case PROFILE_INFORMATION_LOAD:
        console.log(action);
        // console.log(action.error);
        // console.log(action.payload.message);
        // debugger;
        return {
          ...state,
          inProgress: false,
          errors: action.error ? action.payload.message : null,
          success: action.payload ? action.payload.message : null,
          payload: null
        };
      case PROFILE_INFORMATION_UPDATE_LOAD: 
        return {
          ...state,
          inProgress: false,
          errors: action.error ? action.payload.message : null,
          success: action.payload ? action.payload.message : null,
          payload: null
        };
      case PROFILE_INFORMATION_UPDATE_ZIPCODE:
        return {
          ...state,
          inProgress: false,
          errors: action.error ? action.payload.message : null,
          success: null,
          payload: action.payload ? action.payload : null
        }
      default:
        return state;
    }
  
    return state;
  };
  