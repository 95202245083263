import React from "react";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import "./faq.scss";
import { Link } from "react-router-dom";

function Faq() {
	function useScrollDirection() {
		const [scrollDirection, setScrollDirection] = useState(null);

		useEffect(() => {
			let lastScrollY = window.pageYOffset;

			const updateScrollDirection = () => {
				const scrollY = window.pageYOffset;
				const direction = scrollY > lastScrollY ? "down" : "up";
				if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
					setScrollDirection(direction);
				}
				lastScrollY = scrollY > 0 ? scrollY : 0;
			};
			window.addEventListener("scroll", updateScrollDirection); // add event listener
			return () => {
				window.removeEventListener("scroll", updateScrollDirection); // clean up
			};
		}, [scrollDirection]);

		// return scrollDirection;
	}

	const scrollDirection = useScrollDirection();
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const hide = () => setIsOpen(false);
	const show = () => setIsOpen(true);
	const navigation = [
		{ link: "/#Home", text: "Home" },
		{ link: "/#OurServices", text: "Our Services" },
		{ link: "/#OurMission", text: "Our Mission" },
		{ link: "/#RidersHowItWorks", text: "Riders -  How It Works" },
		{ link: "/#RideDocsHowItWorks", text: "RideDocs -  How It Works" },
		{ link: "/#ServiceAvailability", text: "Service Availability" },
		{ link: "/#Price", text: "Price" },
		{ link: "/#Resource", text: "Resource" },
		{ link: "/#ContactUs", text: "Contact Us" },
		{ link: "/faq", text: "FAQ" },
	];

    <header className={`${scrollDirection === "down" ? "hide" : "show"} landing_header`}>
    <div className="header">
        <div className="hd-logo">
            <a href="/">
                <img src="../images/logo.png" alt="RideDoc Logo" />
            </a>
        </div>

        <div className="hd-nav">
            <button className="nav-icon" onClick={toggle}>
                <span className={`menu ${isOpen ? "cross" : "hamburger"}`}>
                    {isOpen ? <img src="images/close-ic.svg" alt="menu close icon" /> : <img src="images/menu.svg" alt="menu icon" />}
                </span>
            </button>
            <nav className={`nav-data ${isOpen ? "show" : "hide"}`} id="myDropdown">
                <ul>
                    {navigation.map((nav) => (
                        <li key={nav.text}>
                            <a href={nav.link} onClick={toggle} onBlur={hide} onFocus={show}>
                                {nav.text}
                            </a>
                        </li>
                    ))}
                    {/*  */}
                </ul>
            </nav>
        </div>
    </div>
</header>

	return (
		<div className="landing_main">
			<header className={`${scrollDirection === "down" ? "hide" : "show"} landing_header`}>
				<div className="header">
					<div className="hd-logo">
						<a href="/">
							<img src="../images/logo.png" alt="RideDoc Logo" />
						</a>
					</div>

					<div className="hd-nav">
						<button className="nav-icon" onClick={toggle}>
							<span className={`menu ${isOpen ? "cross" : "hamburger"}`}>
								{isOpen ? <img src="images/close-ic.svg" alt="menu close icon" /> : <img src="images/menu.svg" alt="menu icon" />}
							</span>
						</button>
						<nav className={`nav-data ${isOpen ? "show" : "hide"}`} id="myDropdown">
							<ul>
								{navigation.map((nav) => (
									<li key={nav.text}>
										<a href={nav.link} onClick={toggle} onBlur={hide} onFocus={show}>
											{nav.text}
										</a>
									</li>
								))}
								{/*  */}
							</ul>
						</nav>
					</div>
				</div>
			</header>
			<section className="banner" id="Home">
				<div className="banner-section">
					<h1>
						<a href="/">
							<img src="images/logo-slogan.png" alt="logo" />
						</a>
					</h1>
					{/* <Link to="/#OurMission">
						<a href="#" title="RideDoc" className="btn btn-primary home_btn">
							SCHEDULE NOW
						</a>
					</Link> */}
				</div>
			</section>

			<section className="faq" id="FAQ">
				<h2>FAQ</h2>
				<div class="faqContainer">
					<div class="faqBody">
						<h3>About RideDoc</h3>
						<ol className="faqList">
							<li className="faqItem">
								<h4 className="faqHeading">What is RideDoc?</h4>
								<p className="faqContent">
									RideDoc is an online platform that eliminates the hassle of transporting a bike to a store by bringing the
									mechanic to the biker's location of choice. A DC-based Tech start up founded in 2022 with a (1) passion and
									commitment to simplify process of bike repairs and services, (2) goal of enabling bike mechanics to earn over 3X
									more than what they currently make working in Bike stores, and (3) promoting healthier lifestyles and a greener
									environment by empowering bikers to customer smoothly and safely.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">What areas does RideDoc service?</h4>
								<p className="faqContent">
									Currently, we serve the DMV area (DC Metro Area). However, RideDoc is constantly growing and expanding to new
									areas. Please continue to check our website for updates
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How does RideDoc pick its mechanics? What qualifications do they have?</h4>
								<p className="faqContent">
									Our mechanics can come from a variety of backgrounds. They're independent contractors and not employed directly by
									RideDoc. Anyone can sign up to become a mechanic on RideDoc. However, there is an activation and on-boarding
									process which includes, but is not limited to:
									<ul>
										<li>background check</li>
										<li>validation of bike-mechanic credentials and experience,</li>
										<li>verification of general liability and professional liability insurance</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Are RideDoc's services more expensive than a bike store?</h4>
								<p className="faqContent">
									No. RideDoc's goal is to eliminate the hassle of taking the bike to a store without paying a premium. Our prices
									are determine based on average service costs of bike stores in the area.
								</p>
							</li>
						</ol>

						<h3>For Bikers</h3>
						<ol className="faqList">
							<li className="faqItem">
								<h4 className="faqHeading">I forgot my password. How can I recover it?</h4>
								<p className="faqContent">
									On the sign-in screen, click the 'Forgot Password' option and follow the instructions. If you used either a Google
									or Apple account to login, you won't need to reset your password. Click on the respective icon/s and follow the
									instructions.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">I haven't received an email from RideDoc to validate my account and email?</h4>
								<p className="faqContent">
									It's possible that the email is in your Spam/Junk folder. When you find it, please click on “Report Not Spam” so
									that you continue to receive confirmation emails and subsequent communications from us. If you don't find it in
									your Spam/Junk folder, it's possible that you made a typo while manually entering your email address. We request
									you try registering again.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I request a service on RideDoc?</h4>
								<p className="faqContent">
									We offer the flexibility to request a service either through our website or through our app, which is available
									both on the Apple and Google play stores. You'll be able to find it by searching for “RideDoc'. You can create an
									account using either your profiles on Google, Apple, or register with an e-mail ID.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">I'm unable to find my Bike make in your list. Do you not fix it? </h4>
								<p className="faqContent">
									We do our best to keep our Bike database up to date. However, it's certainly possible that we are missing a few.
									If you don't find your bike on the list, please select 'other' and add details about your Bike type and make in
									the 'Comment's section.{" "}
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Can I pick a mechanic or are they assigned randomly?</h4>
								<p className="faqContent">
									At this point, any mechanic who has an availability on your service date and time will accept your order. However,
									we are constantly improving our platform and new features will be made available gradually based on user needs and
									feedback.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">What do I need to do before requesting a service for the first tike?</h4>
								<p className="faqContent">
									After creating an account, please update your profile with the following details:
									<ul>
										<li>Name</li>
										<li>Address (location where the service needs to be provided)</li>
										<li>Bike type, make, and model (to enable our mechanic to determine your needs)</li>
										<li>Credit/Debit card (your card won't be charged until the service is completed)</li>
									</ul>
									While placing the order, please:
									<ul>
										<li>Read the service description carefully to understand of what it includes and excludes</li>
										<li>
											Check the price (eBike services will cost 50% more than regular bikes; kids' bikes will cost 50% less than
											regular bikes). The exact cost of your service will be displayed on the final confirmation screen and
											order confirmation email
										</li>
										<li>Add pictures that aptly capture the service/repair needs</li>
										<li>Answer questions under the 'comments' section with as much detail as possible</li>
										<li>Choose a date and time slot that works best</li>
										<li>Confirm that that location is accurate</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">
									Do I need to buy the parts that I'm looking to replace or can the mechanic buy it for me?
								</h4>
								<p className="faqContent">
									You can pick the option that best suits you. If you want the mechanic to buy your parts, please provide a detailed
									description about the part that is required, and allow a few days for the mechanic to procure the parts. Post
									covid, supply-chain for bike parts was heavily impacted, so some parts maybe harder and take longer to find. In
									such case, it might be best for you to procure the part and request the service once the part has been delivered
									to you.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">
									Are there any special requirements I need to consider while choosing my service location?
								</h4>
								<p className="faqContent">
									The mechanics do not require a lot of space, but please make sure there is enough room for the bike to be mounted
									on a stand and for the mechanic to complete the service. In general, it can be done in any apartment, yard or
									driveway. When choosing the location, please consider the weather conditions, a location that won't be affected by
									a slight amount of dirt or grease.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">My service request has been pending for a long time. What should I do?</h4>
								<p className="faqContent">
									Sorry for the wait. RideDoc is committed to ensuring that a mechanic is available to accept your order. However,
									as independent contractors, mechanics have the flexibility to pick their jobs, hours, and service locations. We'll
									ensure that a mechanic accepts your order unless there are extenuating circumstances. If your order isn't accepted
									4 hours before the actual service time, please reach out to us at hello@ridedoc.co.{" "}
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">What should I do if I accidentally selected the wrong service location?</h4>
								<p className="faqContent">
									You won't be able to change your service location in the order. We suggest canceling and rebooking the order with
									the right address.{" "}
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How can I review my mechanic?</h4>
								<p className="faqContent">
									Currently, we don't have the option to review individual mechanics; you can send your feedback to
									hello@ridedoc.co. At Ridedoc we are committed to enhancing our user experience, and independent mechanic reviews
									are already in our product roadmap.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I cancel my service request?</h4>
								<p className="faqContent">
									Sorry to hear that you want to cancel your request. If the date or time does not work for rescheduling your
									request instead of canceling it. To cancel your service request, please access your RideDoc account either through
									the app or website, and:
									<ul>
										<li>Click "Orders" on the platform's bottom navigation tab</li>
										<li>Select the service you need to reschedule.</li>
										<li>Click “Edit Order” at the bottom of the screen</li>
										<li>Follow the instructions until you receive a revised confirmation</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Does a cancelation fee apply if I cancel my service request?</h4>
								<p className="faqContent">
									Plans can change. We won't charge you if you cancel at least 3 hours in advance of the actual service time.
									However, if it's within 3 hours of the service time, we'll charge you 50% of the service cost. Our mechanics are
									independent contractors, many of whom rely on your order as a primary source of income. The goal of the
									cancellation fee is solely to compensate mechanics for their time and effort.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">I completely forgot about my RideDoc appointment. Is there a No-Show Fee?</h4>
								<p className="faqContent">
									Mechanics will notify you either through text, call, or push notification when they are on their way to your
									location. Please keep your phone handy. If you're unavailable for more than 15 minutes after the order start time,
									mechanic will cancel the order and you'll be charged 50% of your order cost. The goal of the cancellation fee is
									solely to compensate mechanics for their time and effort.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">My plans changed. How can I reschedule my service?</h4>
								<p className="faqContent">
									If you need to reschedule your Service, access your RideDoc account either through the app or website, and :
									<ul>
										<li>Click "Orders" on the platform's bottom navigation tab</li>
										<li>Select the service you need to reschedule.</li>
										<li>Click “Edit Order” at the bottom of the screen.</li>
										<li>Follow the instructions until you receive a revised confirmation.</li>
									</ul>
									<u>Please note</u> that if you reschedule your service less than 3 hours in advance of the originally booked
									service time cancelation/late rescheduling fees might apply.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">
									Can I add additional services to an existing service - for instance, add a parts replacement request to a tune-up?
								</h4>
								<p className="faqContent">
									Absolutely! We recommend adding as much detail as possible about the additional service in the 'comments' section
									as you are placing the order. However, before the additional service is delivered, we recommend agreeing on the
									cost in advance with the mechanic.{" "}
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">
									I'm not satisfied with the quality of the service provided. How can I raise a complaint?
								</h4>
								<p className="faqContent">
									Sorry to hear that you aren't satisfied with the service provided by the mechanic. We are constantly trying to
									improve the quality of services delivered through RideDoc. Please share detailed feedback about your experience to
									hello@ridedoc.com. We will follow up with you as soon as possible and have it rectified.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Mechanic canceled my order… what should I do?</h4>
								<p className="faqContent">
									Sorry to hear that your order was canceled. Sometimes unforeseeable things happen, but don't worry, we are
									constantly monitoring cancelations by mechanics. Usually, when a mechanic cancels your order, a new one will
									accept your order as soon as possible. If a new mechanic is not assigned within 24 hours please feel free to
									contact us at hello@ridedoc.co
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">My mechanic did not show up. How do I get a refund?</h4>
								<p className="faqContent">
									Sorry for your experience. Usually, the mechanic will call or text when they are delayed or have trouble finding
									your service location. Please grant the mechanic a 15-minute grace period for showing up. If they don't show up
									within that timeframe, please contact us at hello@ridedoc.co. A refund won't be necessary because you won't be
									charged until your service is delivered. If you were charged, please write to us with all the requisite details
									for us to investigate and rectify.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Are there additional costs to the service cost displayed in my order?</h4>
								<p className="faqContent">
									RideDoc service costs have four parts:
									<ul>
										<li>Service fare (displayed on the confirmation screen and email)</li>
										<li>Parts (if the mechanic needs to procure)</li>
										<li>Tips (anything you wish you leave for the mechanic)</li>
										<li>Sales Tax (based on the State in which the service is delivered)</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How Do I change the Credit Card on my account?</h4>
								<p className="faqContent">
									To change your credit card, access your RideDoc account either through the app or website, and:
									<ul>
										<li>Click "Profile" from the app's bottom navigation.</li>
										<li>Select “Profile Information”</li>
										<li>Click the “Edit” Button at the bottom of the screen.</li>
										<li>Click the “Credit Card Icon” at the bottom of the screen right next to “Card Information”, and</li>
										<li>Follow the instructions to update your card</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Can I pay in Cash?</h4>
								<p className="faqContent">
									No, RideDoc is a cashless platform, so requesting a mobile bike service requires a valid credit or debit card. You
									won't be charged until the service is completed. You can tip the mechanic in cash if you like.{" "}
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">My Invoice Is Incorrect, What Should I Do?</h4>
								<p className="faqContent">
									Sorry to hear that. On the order detail screen click on “Need Help With Order?” section at the bottom of the
									screen and give us all the details we need to investigate and rectify. Alternatively, you can contact us at
									hello@ridedoc.co and provide:
									<ul>
										<li>Service type</li>
										<li>Order number</li>
										<li>Details that are incorrect on the invoice</li>
										<li>We will contact you as soon as possible to fix the issue.</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I delete my account?</h4>
								<p className="faqContent">
									Sorry to see you go! However, if you choose to delete your account, please ensure that you have no open service
									requests, or pending payments. You can delete your account by clicking the 'Delete Account' option under your
									profile and follow the instructions.
								</p>
							</li>
						</ol>

						<h3>For Mechanics</h3>
						<ol className="faqList">
							<li className="faqItem">
								<h4 className="faqHeading">How can I signup to become a mechanic on RideDoc</h4>
								<p className="faqContent">
									Anyone can sign up to become a mechanic on RideDoc. However, after you sign up, a member from the RideDoc team
									will reach out to activate your account. The activation and on-boarding process includes, but is not limited to:
									<ul>
										<li>Proof of valid ID</li>
										<li>background check</li>
										<li>validation of mechanic credentials and experience,</li>
										<li>verification of general liability and professional liability insurance</li>
									</ul>
									<u>To signup</u>, download the Mechanic App on your mobile device. The app is available both on Apple and Google
									Play stores under the name “RideDoc - Mechanic”. You can create an account using either your profiles on Google,
									Apple, or register with an e-mail ID
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">What requirements do I have to meet to become a mechanic?</h4>
								<p className="faqContent">
									<ul>
										<li>Requirements to become a mechanic includes, but is not limited to:</li>
										<li>Valid ID</li>
										<li>Clean background check</li>
										<li>Valid mechanic credentials</li>
										<li>Proof of general liability and professional liability insurance</li>
										<li>Consent to adhere to RideDoc's service standards, quality, and professionalism</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">My registration has been pending for a long time. Is there a way to check the status?</h4>
								<p className="faqContent">
									We are committed to the safety of our Biker and ensuring that we work with mechanics of the highest caliber.
									Sometimes the process takes longer than expected and we're really sorry for your wait. If you wish to contact us,
									please write to hello@ridedoc.co
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I set up my account and start picking up orders?</h4>
								<p className="faqContent">
									After you click “Profile” on the app's bottom navigation, select 'profile information and fill out the following
									details:
									<ul>
										<li>Name</li>
										<li>Address (app will use this location to show distance from an order)</li>
										<li>Phone (number that customers will use this to contact you)</li>
										<li>Email (you'll receive email confirmations on this)</li>
										<li>Service Availability Counties (select counties in which you intend to accept orders)</li>
										<li>
											Availability Hours (pick 'start time' and 'end time' based on your availability on the day of the week.
											Note that this will apply to all weeks unless you manually change it”)
										</li>
										<li>Checking Account Information (where you'll receive payments for completed jobs)</li>
										<li>Once you fill out this information, hit the “Save” Button</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Can I edit the counties that I'm serving?</h4>
								<p className="faqContent">
									Absolutely! Go to 'Service Availability Counties' under your profile information and add/delete counties as
									intended
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">I forgot my password. How can I recover it? </h4>
								<p className="faqContent">
									On the sign-in screen, click the 'Forgot Password' option and follow the instructions. If you used either a Google
									or Apple account to login, you won't need to reset your password. Click on the respective icon/s and follow the
									instructions
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I change my password?</h4>
								<p className="faqContent">
									On the sign-in screen, click the 'Forgot Password' option and follow the instructions. If you used either a Google
									or Apple account to login, you won't need to reset your password. Click on the respective icon/s and follow the
									instructions
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">
									Do I need to have any general liability and professional liability insurance for providing mobile bike services?
								</h4>
								<p className="faqContent">
									As you do work on third-party property, RideDoc requires you to have general liability and professional liability
									insurance. For activation and onboarding, you must have all the general liability and professional liability
									insurance coverage needed for providing mobile bike services, especially ones required by law in the states you
									operate in.{" "}
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I accept a new order?</h4>
								<p className="faqContent">
									All available jobs are listed on the home screen of your app. They are filtered for you based on counties and
									hours you listed on your profile.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">My Job just got canceled by the Customer. Do I receive some kind of compensation? </h4>
								<p className="faqContent">
									Plans can change. However, you will get compensated with 50% of the service cost if the customer cancel less than
									3 hours before the actual service time.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">My plans changed. Can I reschedule a Job?</h4>
								<p className="faqContent">
									We are sorry to hear that you cannot complete your Job. You won't be unable to reschedule a job on behalf of the
									customer. You can 'cancel' the order with the app so another Mechanic can accept the order.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">I need to cancel a Job. How can I do it? </h4>
								<p className="faqContent">
									Plans can change but please keep in mind that reliability is of utmost importance at RideDoc. We are closely
									monitoring cancelations by mechanics. RideDoc reserves the right to terminate your RideDoc account if we notice a
									pattern of last-minute cancelations. You'll be able to cancel an order by opening it and clicking on the 'cancel'
									button at the bottom of the screen.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">I'm unable to find the service location specified by the customer. What should I do?</h4>
								<p className="faqContent">
									Please call and/or text the customer to get additional instructions. You can find the customer's cell phone number
									on the mechanics app under the specific order.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">I'm running late for a service What should I do?</h4>
								<p className="faqContent">
									Please call and/or text the customer and apologize for the delay. Make sure that the customer has enough time
									after the agreed time slot for completing the service. You can find the customer's cell phone number on the
									mechanics app under the specific order. If the customer doesn't have sufficient time, check if they are able to
									reschedule their order for another date and time
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">My customer did not show up/ wasn't home. What should I do? </h4>
								<p className="faqContent">
									Please call and/or text the customer and grant a 15 mins grace period. If the customer fails to show up after 15
									mins, Please send an email to hello@ridedoc.co to request 50% of the service cost as your no-show fee along with
									the following information:
									<ul>
										<li>Proof that you showed up on time at the service location (i.e. picture of the building entrance)</li>
										<li>Screenshot of call/texts (proof that you tried to call the customer)</li>
										<li>Order number</li>
									</ul>
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How can I complain about a customer?</h4>
								<p className="faqContent">
									If your customer behaved unprofessionally, you are welcome to politely share feedback with them directly and deny
									service. If your safety was threatened, please let us know at hello@ridedoc.co. Please provide as many details as
									possible for us to ensure that appropriate action is taken against the customer.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How can I change the checking account I'm using for payments?</h4>
								<p className="faqContent">
									After you click “Profile” on the app's bottom navigation, select 'profile information and scroll to the bottom of
									the screen for the Checking Account information section. Once you update it, ensure that you hit the “Save” button{" "}
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Can I accept Cash payments?</h4>
								<p className="faqContent">
									No, RideDoc is a cashless platform. The Customers need to pay with the credit card on file. You're welcome to
									accept tips in cash.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I get paid, and how often?</h4>
								<p className="faqContent">
									We will wire your payments weekly, either on Monday or Tuesday. Depending on your bank, please allow 2-3 business
									days for the payment to reflect in your account. Payments will be sent to the checking account provided in the
									profile information.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I determine the price for parts I bought?</h4>
								<p className="faqContent">
									We recommend charging customer the actual price you paid for the part. The customer can show their gratitude for
									the additional effort through a tip. If the customer requests you to procure a part on their behalf, we recommend
									sharing and agreeing to the parts cost upfront. You can add the parts cost to the order in the final screen after
									you clicking on “Complete Order”. Please provide a brief description and submit it only after you've completed the
									order.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">Can I ask a customer to contact me directly for their next order?</h4>
								<p className="faqContent">
									RideDoc believes that its mechanics will adhere to a 'honor-code' and not solicit business directly. If we receive
									notice from a customer about a mechanic soliciting business directly, the mechanic will be instantly terminated
									from the platform and, as a penalty, all their pending payments will be withheld.
								</p>
							</li>
							<li className="faqItem">
								<h4 className="faqHeading">How do I delete my account?</h4>
								<p className="faqContent">
									Sorry to see you go! However, if you choose to delete your account, please ensure that you have no pending jobs.
									You can delete your account by clicking the 'Delete Account' option under your profile and follow the
									instructions.
								</p>
							</li>
						</ol>
					</div>
				</div>
			</section>
		</div>
	);
}
export default Faq;

// const mapStateToProps = state => ({ ...state });

// export default connect(mapStateToProps)(Faq);
