import ContactUs from "../Views/ContactUs/ContactUs";

export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const AUTH_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const FORGOTPASSWORD_PAGE_UNLOADED = 'FORGOTPASSWORD_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const FORGOTPASSWORD = 'FORGOTPASSWORD';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const GOOGLESIGNUP = 'GOOGLESIGNUP'
export const APPLESIGNUP = 'APPLESIGNUP';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';

// ContactUs
export const CONTACT_LOAD = 'CONTACT_LOAD';
export const CONTACT_UNLOAD = 'CONTACT_UNLOAD';
export const CONTACT_FORMSUBMIT = 'CONTACT_FORMSUBMIT';

// Services
export const SERVICE_LOAD = 'SERVICE_LOAD';
export const SERVICE_UNLOAD = 'SERVICE_UNLOAD';

// Users
export const USER_LOAD = 'USER_LOAD';

// Profile  Information
export const PROFILE_INFORMATION_LOAD = 'PROFILE_INFORMATION_LOAD';
export const PROFILE_INFORMATION_UNLOAD = 'PROFILE_INFORMATION_UNLOAD';
export const PROFILE_INFORMATION_UPDATE_LOAD = 'PROFILE_INFORMATION_UPDATE_LOAD';
export const PROFILE_INFORMATION_UPDATE_ZIPCODE = 'PROFILE_INFORMATION_UPDATE_ZIPCODE';

// Vehicles types
export const VEHICLE_TYPES_LOAD = 'VEHICLE_TYPES_LOAD';
export const VEHICLE_MODELS_LOAD = 'VEHICLE_MODELS_LOAD';
export const VEHICLE_MAKES_LOAD = 'VEHICLE_MAKES_LOAD';

export const IMAGEUPLOAD = 'IMAGEUPLOAD';
export const IMAGEUPLOAD_UNLOAD = 'IMAGEUPLOAD_UNLOAD';
export const REPLACE_PARTS_DATA = 'REPLACE_PARTS_DATA';

// Order
export const ORDER_LOAD = 'ORDER_LOAD';
export const ORDER_UNLOAD = 'ORDER_UNLOAD';
export const CHECK_AVAILABILITY_SLOT = 'CHECK_AVAILABILITY_SLOT';
export const ORDER_LOAD_SUCCESS = 'ORDER_LOAD_SUCCESS';

// Order History
export const ORDER_HISTORY_LOAD = 'ORDER_HISTORY_LOAD';
export const ORDER_HISTORY_UNLOAD = 'ORDER_HISTORY_UNLOAD';

// Stripe
export const STRIPE_PUBLIC_KEY_LOAD = 'STRIPE_PUBLIC_KEY_LOAD';
export const STRIPE_PUBLIC_KEY_UNLOAD = 'STRIPE_PUBLIC_KEY_UNLOAD';

// 401 Error
export const HANDLE_401_ERROR = (status) => {
    if(status == 401){
        window.location.href = window.location.origin + '/signin';
    }
}