import React, { useEffect } from 'react';
import './termsconditions.scss';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

function TermsConditions() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <div className='header-terms-wrp'>
             <header>
              <div className="header-terms-backic">
                <Link onClick={() => window.history.back()} className="header-terms-backic-icon">
                  <Icon path={mdiArrowLeft} size={'28px'} color="#222" />
                </Link>
              </div>
              <div className='profile-menu-header-text'>
                <p>Terms of Service</p>
              </div>
            </header>
            </div>
        
        <div className='p-content10 main-content doc-content'>
            <p className="p-content1 para-content"><span className="s-content1">&nbsp;</span><span className="s-content2 title-content">RIDEDOC TERMS OF SERVICE</span></p>
            <p className="p-content1 para-content"><span className="s-content3">Last Updated: July 1st 2023</span></p>
            <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.gjdgxs">1.</a></span><span className="s-content4"><a className="link1"
                href="#h.gjdgxs">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.gjdgxs">The RideDoc
                    Platform&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.30j0zll">2.</a></span><span className="s-content4"><a className="link1"
                href="#h.30j0zll">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.30j0zll">Modification to the
                    Agreement&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.1fob9te">3.</a></span><span className="s-content4"><a className="link1"
                href="#h.1fob9te">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.1fob9te">Eligibility&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.3znysh7">4.</a></span><span className="s-content4"><a className="link1"
                href="#h.3znysh7">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.3znysh7">Charges&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.2et92p0">5.</a></span><span className="s-content4"><a className="link1"
                href="#h.2et92p0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.2et92p0">Mechanic
                    Payments&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.tyjcwt">6.</a></span><span className="s-content4"><a className="link1"
                href="#h.tyjcwt">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.tyjcwt">RideDoc
                    Communications&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.3dy6vkm">7.</a></span><span className="s-content4"><a className="link1"
                href="#h.3dy6vkm">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.3dy6vkm">Your
                    Information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.1t3h5sf">8.</a></span><span className="s-content4"><a className="link1"
                href="#h.1t3h5sf">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.1t3h5sf">Promotions, Referrals, and Loyalty
                    Programs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.4d34og8">9.</a></span><span className="s-content4"><a className="link1"
                href="#h.4d34og8">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.4d34og8">Restricted
                    Activities&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.2s8eyo1">10.</a></span><span className="s-content4"><a className="link1"
                href="#h.2s8eyo1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.2s8eyo1">User Representations, Warranties and
                    Agreements&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.17dp8vu">11.</a></span><span className="s-content4"><a className="link1"
                href="#h.17dp8vu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.17dp8vu">Mechanic Representations, Warranties and
                    Agreements&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.3rdcrjn">12.</a></span><span className="s-content4"><a className="link1"
                href="#h.3rdcrjn">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.3rdcrjn">Intellectual
                    Property&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.26in1rg">13.</a></span><span className="s-content4"><a className="link1"
                href="#h.26in1rg">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.26in1rg">Disclaimers&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14</a></span>
            </p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.lnxbz9">14.</a></span><span className="s-content4"><a className="link1"
                href="#h.lnxbz9">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.lnxbz9">State and Local
                    Disclosures&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.35nkun2">15.</a></span><span className="s-content4"><a className="link1"
                href="#h.35nkun2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1"
                    href="#h.35nkun2">Indemnification&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.1ksv4uv">16.</a></span><span className="s-content4"><a className="link1"
                href="#h.1ksv4uv">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.1ksv4uv">Limitation of
                    Liability&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.44sinio">17.</a></span><span className="s-content4"><a className="link1"
                href="#h.44sinio">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.44sinio">Term and
                    Termination&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.2jxsxqh">18.</a></span><span className="s-content4"><a className="link1"
                href="#h.2jxsxqh">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.2jxsxqh">Arbitration
                    agreement&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;19</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.z337ya">19.</a></span><span className="s-content4"><a className="link1"
                href="#h.z337ya">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.z337ya">Confidentiality&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30</a></span>
            </p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.3j2qqm3">20.</a></span><span className="s-content4"><a className="link1"
                href="#h.3j2qqm3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.3j2qqm3">Relationship with
                    RideDoc&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;31</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.1y810tw">21.</a></span><span className="s-content4"><a className="link1"
                href="#h.1y810tw">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.1y810tw">Third-Party
                    Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;32</a></span></p>
            <p className="p-content4 para-content"><span className="s-content4"><a className="link1" href="#h.4i7ojhp">22.</a></span><span className="s-content4"><a className="link1"
                href="#h.4i7ojhp">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span><span className="s-content4"><a
                    className="link1" href="#h.4i7ojhp">General&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;32</a></span></p>
            <p className="p-content2 p-content3 para-content"><span className="s-content5"></span></p>
            <hr style={{pageBreakBefore: "always", display: "none"}} />
            <p className="p-content5 para-content"><span className="s-content5"></span></p>
            <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
            <p className="p-content2 para-content"><span className="s-content3">These Terms of Service constitute a legally binding agreement (the
                &ldquo;Agreement&rdquo;) between you and RideDoc LLC., its parents, subsidiaries, representatives,
                affiliates, officers and directors (collectively, &ldquo;RideDoc,&rdquo; &ldquo;we,&rdquo; &ldquo;us&rdquo;
                or &ldquo;our&rdquo;) governing your use of the RideDoc applications, websites, technology, facilities, and
                platform (collectively, the &ldquo;RideDoc Platform&rdquo;).</span></p>
            <p className="p-content2 para-content"><span className="s-content1">PLEASE BE ADVISED: THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS BETWEEN
                YOU AND RIDEDOC CAN BE BROUGHT </span><span className="c16 s-content2">(SEE SECTION 18 BELOW).&nbsp;</span><span
                    className="s-content3">THESE PROVISIONS WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO: (1) WAIVE YOUR RIGHT TO A JURY
                    TRIAL, AND (2) SUBMIT CLAIMS YOU HAVE AGAINST RIDEDOC TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL
                    BASIS, NOT AS A PLAINTIFF OR className MEMBER IN ANY className, GROUP OR REPRESENTATIVE ACTION OR
                    PROCEEDING.&nbsp;AS A MECHANIC OR MECHANIC APPLICANT, YOU HAVE AN OPPORTUNITY TO OPT OUT OF ARBITRATION WITH
                    RESPECT TO CERTAIN CLAIMS AS PROVIDED IN SECTION 18.</span></p>
            <p className="p-content2 para-content"><span className="s-content1">By entering into this Agreement, and/or by using or accessing the RideDoc Platform,
                you expressly acknowledge that you understand this Agreement (including the dispute resolution and
                arbitration provisions in Section 18) and accept all of its terms.&nbsp;</span><span className="s-content1">IF YOU DO
                    NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE RIDEDOC
                    PLATFORM OR ANY OF THE SERVICES PROVIDED THROUGH THE RIDEDOC PLATFORM.</span><span className="s-content3">&nbsp; If you
                        are accessing or using the RideDoc Platform to access or use RideDoc Business services on behalf of an
                        organization contracted with RideDoc, and you are not accessing or using the RideDoc Platform as a Rider or
                        Mechanic, your access and use is governed by the contract between RideDoc and your organization.</span></p>
            <ol className="list-content1 lst-kix_list_21-0 start" start="1">
                <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.gjdgxs"><span className="s-content6 s-content2">The RideDoc Platform</span></li>
            </ol>
            <p className="p-content9 p-content10 para-content"><span className="s-content1">The RideDoc Platform provides a marketplace where persons who seek a mobile
                bike mechanic (&ldquo;Riders&rdquo;) can be matched with a mobile bike mechanic. (&ldquo;Mechanics&rdquo;)
                One option for Riders is to request a service from mobile bike mechanics who are driving to the intended
                service location, chosen by the Rider. Riders can choose from one of the services available on the platform.
                They will be matched with a Mechanic based on availability. The Mechanic will provide the service at the
                Rider&rsquo;s chosen time and location, both of which are subject to availability. Riders should account for
                a public or a private space which is large enough for the Mechanic to deliver the service without any
                limitation. Mechanics, Riders, and any other individuals, excluding any Excluded Individuals, using the
                RideDoc Platform are collectively referred to herein as &ldquo;Users,&rdquo; and the services provided by
                Mechanics to Riders, and other related services provided by Mechanics in connection with the RideDoc
                Platform, shall be referred to herein as &ldquo;Mobile Bike Services.&rdquo; &ldquo;RideDoc Services&rdquo;
                shall include any service provided by RideDoc pursuant to the RideDoc Platform (for clarity, RideDoc
                Services does not include Mobile Bike Services or Third-Party Services). As a User, you authorize RideDoc to
                match and/or re-match you with Mechanics or Riders based on factors such as your location, the requested
                service location, the requested time and type of service, user preferences, &nbsp;membership status,
                regulatory or other third-party requirements, user statistics, and platform efficiency, and to cancel an
                existing match based on the same or other considerations. Any decision by a User to offer or accept Mobile
                Bike Services is a decision made in such User&rsquo;s sole discretion. A separate agreement is formed
                between Mechanics and Riders when the Rider accepts the Mobile Bike Services offered by the Mechanic.
            </span><span className="s-content11">MECHANICS ARE INDEPENDENT BUSINESS OWNERS. MECHANICS ARE INDEPENDENT CONTRACTORS OF
                RIDERS AND NOT EMPLOYEES, PARTNERS, REPRESENTATIVES, AGENTS, JOINT VENTURERS, INDEPENDENT CONTRACTORS OR
                FRANCHISEES OF RIDEDOC. RIDEDOC DOES NOT PERFORM MOBILE BIKE SERVICES AND DOES NOT EMPLOY INDIVIDUALS TO
                PERFORM MOBILE BIKE SERVICES. BY CONNECTING PEOPLE AND BUSINESSES SEEKING MOBILE BIKE SERVICES WITH
                MECHANICS, RIDEDOC OPERATES AS AN ONLINE MARKETPLACE THAT CONNECTS CLIENTS (RIDERS) WITH SERVICE PROVIDERS
                (MECHANICS) WHO WISH TO CONDUCT MOBILE BIKE SERVICES</span></p>
            <p className="p-content2 para-content"><span className="s-content3">As used herein, &ldquo;Excluded Individuals&rdquo; means any individual who is
                registering to use the RideDoc Platform or whose use of the RideDoc Platform is on behalf of an organization
                contracted with RideDoc, except as a Rider or Mechanic.</span></p>
            <p className="p-content9 p-content10 para-content"><span className="s-content11">USERS HEREBY ACKNOWLEDGE THAT RIDEDOC DOES NOT SUPERVISE, SCOPE, DIRECT,
                CONTROL OR MONITOR A MECHANIC&lsquo;S WORK AND EXPRESSLY DISCLAIMS (TO THE EXTENT PERMITTED BY LAW) ANY
                RESPONSIBILITY AND LIABILITY FOR THE WORK PERFORMED AND THE TASKS IN ANY MANNER, INCLUDING BUT NOT LIMITED
                TO A WARRANTY OR CONDITION OF GOOD AND WORKMANLIKE SERVICES, WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR
                A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, STATUTE, ORDINANCE, REGULATION, OR CODE.</span></p>
            <p className="p-content10 p-content9 para-content"><span className="s-content11">Any reference on the RideDoc Platform to a Mechanic being licensed or
                credentialed in some manner, or &quot;background checked,&quot; &ldquo;vetted&rdquo; (or similar language)
                designations indicates only that the RideDoc has completed a relevant account registration process or met
                certain criteria and does not represent anything else. Any such description is not an endorsement,
                certification or guarantee by RideDoc of such Mechanic&rsquo;s skills or qualifications or whether they are
                licensed, insured, trustworthy, safe or suitable. Instead, any such description is intended to be useful
                information for Clients to evaluate when they make their own decisions about the identity and suitability of
                RideDocs whom they select, interact, or contract with via the RideDoc Platform.</span></p>
            <ol className="list-content1 lst-kix_list_21-0" start="2">
                <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.30j0zll"><span className="s-content6 s-content2">Modification to the Agreement</span></li>
            </ol>
            <p className="p-content5 p-content7 p-content8 para-content"><span className="s-content10 s-content2"></span></p>
            <p className="p-content2 para-content"><span className="s-content3">RideDoc reserves the right to modify the terms and conditions of this Agreement, and
                such modifications shall be binding on you only upon your acceptance of the modified Agreement. RideDoc
                reserves the right to modify any information on pages referenced in the hyperlinks from this Agreement from
                time to time, and such modifications shall become effective upon posting. Continued use of the RideDoc
                Platform after any such changes shall constitute your acceptance of such changes. Unless material changes
                are made to the arbitration provisions herein, you agree that modification of this Agreement does not create
                a renewed opportunity to opt out of arbitration (if applicable).</span></p>
            <ol className="list-content1 lst-kix_list_21-0" start="3">
                <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.1fob9te"><span className="s-content8">Eligibility</span></li>
            </ol>
            <p className="p-content2 para-content"><span className="s-content3">The RideDoc Platform may only be used by individuals who have the right and authority
                to enter into this Agreement and are fully able and competent to satisfy the terms, conditions, and
                obligations herein. The RideDoc Platform is not available to Users who have had their User account
                temporarily or permanently deactivated. You may not allow other persons to use your User account, you agree
                that you are the sole authorized user of your User account, and you may not use your User account on behalf
                of any third party, except as otherwise expressly permitted by RideDoc. To use the RideDoc Platform, each
                User shall create a User account. Each person may only create one User account, and RideDoc reserves the
                right to deactivate any additional or duplicate accounts. Your participation in certain RideDoc programs and
                use of certain RideDoc services may be subject to additional eligibility requirements as determined by
                RideDoc.</span></p>
            <p className="p-content2 para-content"><span className="s-content3">By becoming a User, you represent and warrant that you are at least 18 years old.
            </span></p>
            <ol className="list-content1 lst-kix_list_21-0" start="4">
                <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.3znysh7"><span className="s-content8">Charges</span></li>
            </ol>
            <p className="p-content2 para-content"><span className="s-content3">As a User, you understand that request or use of Mobile Bike Services, or RideDoc
                Services may result in charges (&ldquo;Charges&rdquo;) to you and/or to an organization, if applicable.
                Charges to Riders and/or organizations, if applicable, for Mobile Bike Services include Fares (defined
                below) and other applicable fees, surcharges, and taxes, plus any tips to the Mechanic that you elect to
                pay. RideDoc has the authority and reserves the right to determine and modify pricing by quoting you a price
                for a specific service at the time you make a request. Pricing may vary based on the type of service you
                request described within the RideDoc Platform. You are responsible for reviewing the applicable price quote
                within the RideDoc Platform and shall be responsible for all Charges incurred under your User account
                regardless of your awareness of such Charges or the amounts thereof. When you are requesting a service which
                could involve the use of additional parts you acknowledge that you will be charged an additional fee for
                those respective parts. </span></p>
            <ul className="list-content1 lst-kix_list_1-0 start">
                <li className="c14 list-content2 li-bullet-0 para-list"><span className="s-content9">Quoted Fares.</span><span className="s-content3">&nbsp;When you make a
                    service request using the RideDoc Platform, RideDoc will quote you a Fare at the time of your request.
                    The quote is subject to change until the service request is confirmed. </span></li>
                <li className="list-content2 list-content4 li-bullet-0 para-list"><span className="s-content9">Parts Charges. </span><span className="s-content3">When you make a service
                    request using the RideDoc Platform for a service which does require parts (e.g. bicycle tube, bicycle
                    chain) additional charges for the parts used plus tax will be added during checkout. Quotes shown in the
                    RideDoc platform do not include any parts. If your service requires the use of additional parts please
                    ask the mechanic for a quote ahead of the installment.</span></li>
            </ul>
            <p className="p-content2 para-content"><span className="s-content5">Service Fees and Other Charges.</span></p>
            <ul className="list-content1 lst-kix_list_2-0 start">
                <li className="c14 list-content2 li-bullet-0 para-list"><span className="s-content9">Cancellation/ No Show Fee.</span><span className="s-content3">&nbsp;After
                    requesting a service you may cancel it through the RideDoc Platform, but note that a cancellation fee of
                    50% of the quoted fare applies if you cancel within 2 hours of the booked service time. RideDoc may also
                    charge a fee of 50% of the quoted fare if you fail to show up after requesting a service. </span></li>
                <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content9">Abuse Fee</span><span className="s-content3">. If we receive a credible
                    report that you have misused or abused the RideDoc Platform, you agree to pay an &ldquo;Abuse Fee&rdquo;
                    of up to $250 as determined by RideDoc in its sole discretion. RideDoc reserves the right (but is not
                    obligated) to verify or otherwise require documentation of abuse prior to processing the Abuse
                    Fee.</span></li>
                <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content9">Other Charges.</span><span className="s-content3">&nbsp;Other fees and
                    surcharges may apply to your service, including, but not limited to: local fees, event fees, wait time
                    fees determined by RideDoc or its marketing partners. In addition, where required by law RideDoc will
                    collect applicable taxes. </span></li>
                <li className="list-content4 list-content2 li-bullet-0 para-list"><span className="s-content9">Tips.</span><span className="s-content3">&nbsp;Following a service, you may
                    have the opportunity to elect to tip your Mechanic in cash or through the RideDoc Platform. Any tips
                    will be provided entirely to the applicable Mechanic.</span></li>
            </ul>
            <p className="p-content2 para-content"><span className="s-content5">Charges Generally.</span></p>
            <ul className="list-content1 lst-kix_list_3-0 start">
                <li className="c14 list-content2 li-bullet-0 para-list"><span className="s-content9">Facilitation of Charges.</span><span className="s-content3">&nbsp;All
                    Charges are facilitated through a third-party payment processor (Stripe). RideDoc may replace its
                    third-party payment processor without notice to you. Cash payments are strictly prohibited unless
                    expressly permitted by RideDoc. Your payment of Charges to RideDoc satisfies your payment obligation for
                    your use of the RideDoc Platform, RideDoc Services and Mobile Bike Services. Certain Charges may be
                    collectively billed as a single purchase transaction to your selected payment method based on the
                    payment frequency indicated in your settings. If your primary payment method expires, is invalid, or if
                    Charges to your primary payment method are unable to be processed for whatever reason, you have to
                    provide an alternate source of payment. If you don&#39;t recognize a transaction, then check your
                    service receipts and payment history.</span></li>
                <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content9">No Refunds.</span><span className="s-content3">&nbsp;All Charges are
                    non-refundable except to the extent required by law. This no-refund policy shall apply at all times
                    regardless of your decision to terminate usage of the RideDoc Platform, any disruption to the RideDoc
                    Platform, RideDoc Services, or Mobile Bike Services, or any other reason whatsoever.</span></li>
                <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content9">Coupons.</span><span className="s-content3">&nbsp;You may receive coupons,
                    credits, discounts, or other promotions (collectively, &ldquo;Coupons&rdquo;) that you can apply toward
                    payment of certain Charges. Coupons are valid only for use on the RideDoc Platform, and are not
                    transferable or redeemable for cash except as required by law. Coupons cannot be combined unless
                    expressly provided otherwise, and if the cost of your Charges exceeds the applicable Coupon value, we
                    may charge your payment method on file for the Charges in excess of the Coupon amount. With respect to
                    Fares, RideDoc may deduct the amount attributable to the Service Fee, or Other Charges before
                    application of the Coupon. Additional restrictions on Coupons may apply as communicated to you in a
                    relevant promotion.</span></li>
                <li className="list-content4 list-content2 li-bullet-0 para-list"><span className="s-content9">Payment Card Authorization.</span><span className="s-content3">&nbsp;Upon
                    addition of a new payment method or each request for RideDoc Services or Mobile Bike Services RideDoc
                    may seek authorization of your selected payment method to verify the payment method, ensure the Charges
                    will be covered, and protect against unauthorized behavior. The authorization is not a charge, however,
                    it may reduce your available credit by the authorization amount until your bank&rsquo;s next processing
                    cycle. Should the amount of our authorization exceed the total funds on deposit in your account, you may
                    be subject to overdraft of NSF charges by the bank issuing your debit or prepaid card. RideDoc is not
                    responsible for these charges and is unable to assist you in recovering them from your issuing bank.
                </span></li>
            </ul>
            <p className="p-content2 para-content"><span className="s-content3">For clarity, RideDoc does not charge a fee for Users to access the RideDoc Platform,
                but retains the right to charge Users and/or organizations, if applicable, a fee or any other Charge for
                accessing or using RideDoc Services or Mobile Bike Service made available through the RideDoc
                Platform.</span></p>
            <ol className="list-content1 lst-kix_list_21-0" start="5">
                <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.2et92p0"><span className="s-content8">Mechanic Payments</span></li>
            </ol>
            <p className="p-content2 para-content"><span className="s-content1">If you are a Mechanic, you will receive payment for your provision of &nbsp;Mobile
                Bike Services pursuant to the terms of the&nbsp;</span><span className="c16 s-content2">Mechanic Addendum, </span><span
                    className="s-content3">which shall form part of this Agreement between you and RideDoc.</span></p>
            <hr style={{pageBreakBefore: "always", display: "none"}} />
                <p className="p-content5 para-content"><span className="s-content3"></span></p>
                <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
                <ol className="list-content1 lst-kix_list_21-0" start="6">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.tyjcwt"><span className="s-content8">RideDoc Communications</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content3">By entering into this Agreement or using the RideDoc Platform, you agree to receive
                    communications from us, our affiliates, or our third-party partners via email and push notifications.
                    Communications from RideDoc, its affiliated companies and/or Mechanics may include but are not limited to:
                    operational communications concerning your User account or use of the RideDoc Platform, RideDoc Services,
                    Third-Party Services or Mobile Bike Services, updates concerning new and existing features on the RideDoc
                    Platform, communications concerning marketing or promotions run by us or our third-party partners, and news
                    concerning RideDoc and industry developments. </span></p>
                <p className="p-content2 para-content"><span className="s-content3">IF YOU WISH TO OPT OUT OF PROMOTIONAL EMAILS, YOU CAN UNSUBSCRIBE FROM OUR
                    PROMOTIONAL EMAIL LIST BY FOLLOWING THE UNSUBSCRIBE OPTIONS IN THE PROMOTIONAL EMAIL ITSELF. HOWEVER, YOU
                    ACKNOWLEDGE THAT OPTING OUT OF RECEIVING ALL COMMUNICATIONS MAY IMPACT YOUR USE OF THE RIDEDOC PLATFORM OR
                    RELATED SERVICES. PLEASE NOTE THAT IT CAN TAKE UP TO 72 HOURS AFTER OPTING OUT UNTIL THIS IS FULLY REFLECTED
                    IN OUR SYSTEMS:</span></p>
                <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
                <ol className="list-content1 lst-kix_list_21-0" start="7">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.3dy6vkm"><span className="s-content8">Your Information</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content1">Your Information is any information you provide, publish or post, and any
                    information provided on your behalf, to or through the RideDoc Platform (including any profile information
                    you provide) or send to other Users (including via in-application feedback, any email feature, or through
                    any RideDoc-related Facebook, Twitter or other social media posting) (your &ldquo;Information&rdquo;). You
                    consent to us using your Information to create a User account that will allow you to use the RideDoc
                    Platform, RideDoc Services, and participate in the Mobile Bike Services. Our collection and use of personal
                    information in connection with the RideDoc Platform, RideDoc Services, and &nbsp;Mobile Bike Services is as
                    provided in RideDoc&rsquo;s&nbsp;</span><span className="c16 s-content2">Privacy Policy. </span><span className="s-content3">You
                        are solely responsible for your Information and your interactions with other members of the public, and we
                        act only as a passive conduit for your online posting of your Information. You agree to provide and maintain
                        accurate, current and complete Information and that we and other members of the public may rely on your
                        Information as accurate, current and complete. To enable RideDoc to use your Information for the purposes
                        described in the Privacy Policy and this Agreement, or to otherwise improve the RideDoc Platform, RideDoc
                        Services, or &nbsp;Mobile Bike Services you grant to us a non-exclusive, worldwide, perpetual, irrevocable,
                        royalty-free, transferable, sub-licensable (through multiple tiers) right and license to exercise the
                        copyright, publicity, and database rights you have in your Information, and to use, copy, perform, display
                        and distribute such Information to prepare derivative works, or incorporate into other works, such
                        Information, in any media now known or not currently known. RideDoc does not assert any ownership over your
                        Information; rather, as between you and RideDoc, subject to the rights granted to us in this Agreement, you
                        retain full ownership of all of your Information and any intellectual property rights or other proprietary
                        rights associated with your Information.</span></p>
                <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
                <ol className="list-content1 lst-kix_list_21-0" start="8">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.1t3h5sf"><span className="s-content8">Promotions, Referrals, and Loyalty
                        Programs</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content3">RideDoc, at its sole discretion, may make available promotions, referral programs and
                    loyalty programs with different features to any Users or prospective Users. RideDoc reserves the right to
                    withhold or deduct credits or benefits obtained through a promotion or program in the event that RideDoc
                    determines or believes that the redemption of the promotion or receipt of the credit or benefit was in
                    error, fraudulent, illegal, or in violation of the applicable promotion or program terms or this Agreement.
                    RideDoc reserves the right to terminate, discontinue, modify or cancel any promotions or programs at any
                    time and in its sole discretion without notice to you.</span></p>
                <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
                <ol className="list-content1 lst-kix_list_21-0" start="9">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.4d34og8"><span className="s-content8">Restricted Activities</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content3">With respect to your use of the RideDoc Platform, RideDoc Services, and your
                    participation in the Mobile Bike Services, you agree that you will not:</span></p>
                <ol className="list-content1 lst-kix_list_4-0 start" start="1">
                    <li className="list-content2 c14 li-bullet-0 para-list"><span className="s-content3">impersonate any person or entity;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">defame, abuse, intimidate, mislead, stalk, threaten, or
                        otherwise harass any person, or carry any weapons;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">violate any law, statute, rule, permit, ordinance or
                        regulation;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">interfere with or disrupt the RideDoc Platform or the servers or
                        networks connected to the RideDoc Platform;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">post Information or interact on the RideDoc Platform, RideDoc
                        Services, or Mobile Bike Services in a manner which is fraudulent, libelous, abusive, obscene, profane,
                        sexually oriented, harassing, or illegal;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">use the RideDoc Platform in any way that infringes any third
                        party&rsquo;s rights, including: intellectual property rights, copyright, patent, trademark, trade
                        secret or other proprietary rights or rights of publicity or privacy;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">post, email or otherwise transmit any malicious code, files or
                        programs designed to interrupt, damage, destroy or limit the functionality of the RideDoc Platform or
                        any computer software or hardware or telecommunications equipment or surreptitiously intercept or
                        expropriate any system, data or personal information;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">forge headers or otherwise manipulate identifiers in order to
                        disguise the origin of any information transmitted through the RideDoc Platform;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">&ldquo;frame&rdquo; or &ldquo;mirror&rdquo; any part of the
                        RideDoc Platform, without our prior written authorization or use meta tags or code or other devices
                        containing any reference to us in order to direct any person to any other website for any
                        purpose;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">modify, adapt, translate, reverse engineer, decipher, decompile
                        or otherwise disassemble any portion of the RideDoc Platform;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">rent, lease, lend, sell, redistribute, license or sublicense the
                        RideDoc Platform or access to any portion of the RideDoc Platform;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">use any robot, spider, site search/retrieval application, or
                        other manual or automatic device or process to retrieve, index, scrape, &ldquo;data mine&rdquo;, copy,
                        access, acquire information, generate impressions or clicks, input or store information, search, monitor
                        any portion of the RideDoc Platform, or in any way reproduce or circumvent the navigational structure or
                        presentation of the RideDoc Platform or its contents;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">Use tools with the goal of masking your IP address (like the TOR
                        network)</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">transfer, lend, or sell your User account, password and/or
                        identification, or any other User&rsquo;s Information to any other party;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">use a false email address or other identifying information,
                        impersonate or misrepresent any person or entity, or your affiliation with any person or entity, or
                        otherwise omit, misrepresent, or mislead as to the origin or source of any entity accessing the RideDoc
                        Platform;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">discriminate against or harass anyone on the basis of race,
                        national origin, religion, gender, gender identity or expression, physical or mental disability, medical
                        condition, marital status, age or sexual orientation;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">commercialize the Mobile Bike Services, or our RideDoc Services
                        without an agreement directly with RideDoc;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">you charge prices for parts used or replace which are above the
                        Fair Market Value of the respective parts</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">misuse or abuse the Mobile Bike Services or our RideDoc Services
                        in violation of eligibility requirements as determined by RideDoc;</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">engage in any form of potential sexual assault, misconduct, or
                        harassment</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">circumvent any measures implemented by RideDoc to prevent or
                        address violations of this Agreement; or</span></li>
                    <li className="list-content4 list-content2 li-bullet-0 para-list"><span className="s-content3">cause any third party to engage in the restricted activities
                        above.</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content3">Should you suspect that any unauthorized party may be using your User account or you
                    suspect any other breach of security or violation of this Agreement, you agree to notify us
                    immediately.</span></p>
                <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
                <ol className="list-content1 lst-kix_list_21-0" start="10">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.2s8eyo1"><span className="s-content2 s-content6">User Representations, Warranties and
                        Agreements</span></li>
                </ol>
                <p className="p-content9 p-content10 para-content"><span className="s-content11">All Users represent and warrant that:</span></p>
                <ul className="list-content1 lst-kix_list_18-0 start">
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You will respect the privacy (including without limitation
                        private, family and home life), property, and data protection rights of Users and will not record
                        (whether video or audio or otherwise) any Service or any interaction by or with any User and/or Mechanic
                        in connection with the RideDoc Platform without the prior written consent of RideDoc and/or the relevant
                        User, as applicable;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You will fulfill the commitments you make to other Users,
                        communicate clearly and promptly, be present and/or available at the time you agree upon with your
                        Mechanic or Rider as the case may be, and only utilize the third party payment service provider
                        specified or approved by RideDoc to make or receive payment for services provided through the RideDoc
                        Platform (&nbsp;see Section 4 &ldquo;Charges&rdquo; of this agreement)</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You will act professionally and responsibly in your
                        interactions with other Users;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You will use your real name or business name on your
                        profile;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">When using or accessing the RideDoc Platform, you will act
                        in accordance with all applicable local, state, provincial, national, or international law or custom and
                        in good faith;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">Where you enter into this Agreement on behalf of a company
                        or other organization, you represent and warrant that you have authority to act on behalf of that entity
                        and to bind that entity to this Agreement.</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">Other than as fully and promptly disclosed in writing to
                        RideDoc, you do not have any motivation, status, or interest that RideDoc may reasonably wish to know
                        about in connection with the RideDoc Platform, including without limitation, if you are using or will or
                        intend to use the RideDoc Platform for any journalistic, academic, investigative, or unlawful
                        purpose.</span></li>
                </ul>
                <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
                <ol className="list-content1 lst-kix_list_21-0" start="11">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.17dp8vu"><span className="s-content8">Mechanic Representations, Warranties and
                        Agreements</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content3">By providing &nbsp;Mobile Bike Services as a Mechanic on the RideDoc Platform, you
                    represent, warrant, and agree that:</span></p>
                <ol className="list-content1 lst-kix_list_5-0 start" start="1">
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">When using the RideDoc Platform, you are operating as a
                        sole proprietor, partnership, limited liability company, limited liability partnership, corporation or
                        other business entity;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You are customarily engaged in an independently established
                        business of the same nature as the services performed for Clients through the RideDoc Platform, and
                        maintain an independent clientele;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You have the unrestricted right to work in the jurisdiction
                        in which you will be performing the Mobile Bike Services</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">If the Service is performed in a jurisdiction that requires
                        you to have a business license or business tax registration, you have the required business license or
                        business tax registration;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You are responsible for identifying and obtaining any
                        required licenses, permits, or registrations before offering Mobile Bike Services </span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You have any and all insurance required to operate your
                        business and provide your services;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You will use your real name or business name and&nbsp;an
                        up-to-date photo&nbsp;on your profile;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You will honor your commitments to other Users on the
                        Platform, including by responding to invitations promptly; performing the Task(s) as agreed upon with
                        your Rider; and providing timely, high-quality services to your Clients;</span></li>
                    <li className="p-content6 list-content2 p-content10 li-bullet-0 para-list"><span className="s-content11">You will only offer and provide services for which you have
                        the necessary skills and expertise, and provide those services safely and in accordance with all
                        applicable laws.</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">You will not engage in reckless behavior while providing Mobile
                        Bike Services, permit an unauthorized third party to accompany you while providing Mobile Bike Services,
                        provide Mobile Bike Services as a Mechanic while under the influence of alcohol or drugs, or take action
                        that harms or threatens to harm the safety of the RideDoc community or third parties.</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">You will not, while providing the Mobile Bike Services, demand
                        that a rider pay in cash, or use a credit card reader, such as a Square Reader, to accept payment or
                        engage in any other activity in a manner that is inconsistent with your obligations under this
                        Agreement.</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">You will not solicit Mobile Bike Services that are not routed
                        through the RideDoc Platform.</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">You will communicate the estimated price for parts used or
                        replaced to the Rider before installing them. </span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">The quoted fare for the parts used or replaced must be in range
                        with the Fair Market Value of those parts. Fair market value, or FMV, is a price a willing,
                        knowledgeable, unpressured buyer is willing to pay to a willing, knowledgeable, and unpressured
                        seller.</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">You will not attempt to defraud RideDoc or Riders on the RideDoc
                        Platform or in connection with your provision of Mobile Bike Services. If we suspect that you have
                        engaged in fraudulent activity we may withhold applicable Fares or other payments for the conducted
                        services in question and take any other action against you available under the law.</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">You will not discriminate against Riders with
                        disabilities</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content3">You agree that we may obtain information about you, including
                        your criminal records, and you agree to provide any further necessary authorizations to facilitate our
                        access to such records during the term of the Agreement.</span></li>
                    <li className="p-content6 list-content2 li-bullet-0 para-list"><span className="s-content1">You will pay all applicable federal, state and local taxes
                        based on your provision of Mobile Bike Services and any payments received by you.</span><sup><a
                            href="#cmnt1" id="cmnt_ref1">[a]</a></sup></li>
                    <li className="list-content4 list-content2 li-bullet-0 para-list"><span className="s-content3">You will comply with RideDoc&rsquo;s reasonable requests to
                        provide information in connection with Rider complaints, law enforcement requests, or any other
                        incident.</span></li>
                </ol>
                <ol className="list-content1 lst-kix_list_21-0" start="12">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.3rdcrjn"><span className="s-content8">Intellectual Property</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content3">All intellectual property rights in and to the RideDoc Platform shall be owned by
                    RideDoc absolutely and in their entirety. These rights include database rights, inventions and patentable
                    subject-matter, patents, copyright, design rights (whether registered or unregistered), trademarks (whether
                    registered or unregistered) and other similar rights wherever existing in the world together with the right
                    to apply for protection of the same. All other trademarks, logos, service marks, company or product names
                    set forth in the RideDoc Platform are the property of their respective owners. You acknowledge and agree
                    that any questions, comments, suggestions, ideas, feedback or other information (&ldquo;Submissions&rdquo;)
                    provided by you to us are non-confidential and shall become the sole property of RideDoc. RideDoc shall own
                    exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use
                    and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or
                    compensation to you. Except for the explicit license grants hereunder, nothing in this Agreement shall be
                    construed to transfer ownership of or grant a license under any intellectual property rights.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">RIDEDOC and other RideDoc logos, designs, graphics, icons, scripts and service names
                    are registered trademarks, trademarks or trade dress of RideDoc in the United States and/or other countries
                    (collectively, the &ldquo;RideDoc Marks&rdquo;). If you provide Mobile Bike Services as a Mechanic, RideDoc
                    grants to you, during the term of this Agreement, and subject to your compliance with the terms and
                    conditions of this Agreement, a limited, revocable, non-exclusive license to display and use the RideDoc
                    Marks solely on the RideDoc stickers/decals, and any other RideDoc-branded items provided by RideDoc
                    directly to you in connection with providing the Mobile Bike Services (&ldquo;License&rdquo;). The License
                    is non-transferable and non-assignable, and you shall not grant to any third party any right, permission,
                    license or sublicense with respect to any of the rights granted hereunder without RideDoc&rsquo;s prior
                    written permission, which it may withhold in its sole discretion. The RideDoc logo (or any RideDoc Marks)
                    may not be used in any manner that is likely to cause confusion, including but not limited to: use of a
                    RideDoc Mark in a domain name or RideDoc referral code, or use of a RideDoc Mark as a social media handle or
                    name, avatar, profile photo, icon, favicon, or banner. You may identify yourself as a Mechanic on the
                    RideDoc Platform, but may not misidentify yourself as RideDoc, an employee of RideDoc, or a representative
                    or agent of RideDoc.</span></p>
                <p className="p-content2"><span className="s-content3">You acknowledge that RideDoc is the owner and licensor of the RideDoc Marks,
                    including all goodwill associated therewith, and that your use of the RideDoc logo (or any RideDoc Marks)
                    will confer no interest in or ownership of the RideDoc Marks in you but rather inures to the benefit of
                    RideDoc. You agree to use the RideDoc logo strictly in accordance with RideDoc&rsquo;s Brand Guidelines, as
                    may be provided to you and revised from time to time, and to immediately cease any use that RideDoc
                    determines to be nonconforming or otherwise unacceptable.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">You agree that you will not: (1) create any materials that use the RideDoc Marks or
                    any derivatives of the RideDoc Marks as a trademark, service mark, trade name or trade dress, other than as
                    expressly approved by RideDoc in writing; (2) use the RideDoc Marks in any way that tends to impair their
                    validity as proprietary trademarks, service marks, trade names or trade dress, or use the RideDoc Marks
                    other than in accordance with the terms, conditions and restrictions herein; (3) take any other action that
                    would jeopardize or impair RideDoc&rsquo;s rights as owner of the RideDoc Marks or the legality and/or
                    enforceability of the RideDoc Marks, including, challenging or opposing RideDoc&rsquo;s ownership in the
                    RideDoc Marks; (4) apply for trademark registration or renewal of trademark registration of any of the
                    RideDoc Marks, any derivative of the RideDoc Marks, any combination of the RideDoc Marks and any other name,
                    or any trademark, service mark, trade name, symbol or word which is similar to the RideDoc Marks; (5) use
                    the RideDoc Marks on or in connection with any product, service or activity that is in violation of any law,
                    statute, government regulation or standard.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">You agree you will not rent, lease, lend, sell, or otherwise redistribute the RideDoc
                    Mark, or manufacture, produce, print, sell, distribute, purchase, or display counterfeit/inauthentic RideDoc
                    Marks or (including but not limited to signage, stickers, apparel, or decals) from any source other than
                    directly from RideDoc.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">Violation of any provision of this License may result in immediate termination of the
                    License, in RideDoc&rsquo;s sole discretion, a takedown request sent to the appropriate ISP, or social media
                    platform, and/or a Uniform Domain-Name Dispute-Resolution Policy Proceeding (or equivalent proceeding). If
                    you create any materials (physical or digital) bearing the RideDoc Marks (in violation of this Agreement or
                    otherwise), you agree that upon their creation RideDoc exclusively owns all right, title and interest in and
                    to such materials, including any modifications to the RideDoc Marks or derivative works based on the RideDoc
                    Marks or RideDoc copyrights. You further agree to assign any interest or right you may have in such
                    materials to RideDoc, and to provide information and execute any documents as reasonably requested by
                    RideDoc to enable RideDoc to formalize such assignment.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">RideDoc respects the intellectual property of others, and expects Users to do the
                    same. If you believe, in good faith, that any materials on the RideDoc Platform infringe upon your
                    copyrights, please contact us at hello@ridedoc.co </span></p>
                <ol className="list-content1 lst-kix_list_21-0" start="13">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.26in1rg"><span className="s-content8">Disclaimers</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content3">The following disclaimers are made on behalf of RideDoc, our affiliates,
                    subsidiaries, parents, successors and assigns, and each of our respective officers, directors, employees,
                    agents, and shareholders.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">RideDoc does not provide Mobile bike services, and RideDoc is not a mechanic nor bike
                    repair company. It is up to the Mechanic to decide whether or not to offer a service to a Rider contacted
                    through the RideDoc Platform, and it is up to the Rider to decide whether or not to accept a service from
                    any Mechanic contacted through the RideDoc Platform. We cannot ensure that a Mechanic or Rider will complete
                    an arranged service. We have no control over the quality or safety of the service that occurs as a result of
                    the Mobile Bike Services. Any safety-related feature, process, policy, standard, or other effort undertaken
                    by RideDoc is not an indication of any employment or agency relationship with any User.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">The RideDoc Platform is provided on an &ldquo;as is&rdquo; basis and without any
                    warranty or condition, express, implied or statutory. We do not guarantee and do not promise any specific
                    results from use of the RideDoc Platform, RideDoc Services, Third-Party Services, and/or the Mobile Bike
                    Services, including the ability to provide or receive Mobile Bike Services at any given location or time.
                    RideDoc reserves the right, for example, to limit or eliminate access to the RideDoc Platform for Mobile
                    Bike Services, Third-Party Services, and/or RideDoc Services in specific geographic areas and/or at specific
                    times based on commercial viability, public health concerns, or changes in law. To the fullest extent
                    permitted by law, we specifically disclaim any implied warranties of title, merchantability, fitness for a
                    particular purpose and non-infringement. Some states do not allow the disclaimer of implied warranties, so
                    the foregoing disclaimer may not apply to you.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">We do not warrant that your use of the RideDoc Platform, RideDoc Services,
                    Third-Party Services, or Mobile Bike Services will be accurate, complete, reliable, current, secure,
                    uninterrupted, always available, or error-free, or will meet your requirements, that any defects in the
                    RideDoc Platform will be corrected, or that the RideDoc Platform is free of viruses or other harmful
                    components. We disclaim liability for, and no warranty is made with respect to, connectivity, availability,
                    accuracy, completeness, and reliability of the RideDoc Platform, RideDoc Services, Third-Party Services, or
                    Mobile Bike Services. You are responsible at all times for your conduct and the consequences of your conduct
                    while using the RideDoc Platform.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">We cannot guarantee that each Rider or Mechanic is who he or she claims to be. Please
                    use common sense when using the RideDoc Platform, RideDoc Services, Third-Party Services, and Mobile Bike
                    Services. Please note that there are also risks of dealing with underage persons or people acting under
                    false pretense, and we do not accept responsibility or liability for any content, communication or other use
                    or access of the RideDoc Platform by persons under the age of 18 in violation of this Agreement. We
                    encourage you to communicate directly with each potential Mechanic or Rider prior to engaging in an arranged
                    service.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">RideDoc is not responsible for the conduct, whether online or offline, of any User of
                    the RideDoc Platform, RideDoc Services, Third-Party Services, or Mobile Bike Services. You are solely
                    responsible for your interactions with other Users. We do not procure insurance for, nor are we responsible
                    for, personal belongings left by Mechanics or Riders. By using the RideDoc Platform, RideDoc Services,
                    Third-Party Services, and participating in the Mobile Bike Services, you agree to accept such risks and
                    agree that RideDoc is not responsible for the acts or omissions of Users on the RideDoc Platform, RideDoc
                    Services, Third-Party Services, or participating in the Mobile Bike Services.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">You are responsible for the use of your User account and RideDoc expressly disclaims
                    any liability arising from the unauthorized use of your User account.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">It is possible for others to obtain information about you that you provide, publish
                    or post to or through the RideDoc Platform (including any profile information you provide), send to other
                    Users, or share during the Mobile Bike Services, and to use such information to harass or harm you. We are
                    not responsible for the use of any personal information that you disclose to other Users on the RideDoc
                    Platform or through the Mobile Bike Services, RideDoc Services, or Third-Party Services. Please carefully
                    select the type of information that you post on the RideDoc Platform or through the Mobile Bike Services,
                    RideDoc Services, or Third-Party Services or release to others. We disclaim all liability, regardless of the
                    form of action, for the acts or omissions of other Users (including unauthorized users, or
                    &ldquo;hackers&rdquo;).</span></p>
                <p className="p-content2 para-content"><span className="s-content3">Opinions, advice, statements, offers, or other information or content concerning
                    RideDoc or made available through the RideDoc Platform, but not directly by us, are those of their
                    respective authors, and should not necessarily be relied upon. Such authors are solely responsible for such
                    content. Under no circumstances will we be responsible for any loss or damage resulting from your reliance
                    on information or other content posted by third parties, whether on the RideDoc Platform or otherwise. We
                    reserve the right, but we have no obligation, to monitor the materials posted on the RideDoc Platform and
                    remove any such material that in our sole opinion violates, or is alleged to violate, the law or this
                    agreement or which might be offensive, illegal, or that might violate the rights, harm, or threaten the
                    safety of Users or others.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">Location data provided by the RideDoc Platform is for basic location purposes only
                    and is not intended to be relied upon in situations where precise location information is needed or where
                    erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or
                    environmental damage. Neither RideDoc, nor any of its content providers, guarantees the availability,
                    accuracy, completeness, reliability, or timeliness of location data tracked or displayed by the RideDoc
                    Platform. Any of your Information, including geolocational data, you upload, provide, or post on the RideDoc
                    Platform may be accessible to RideDoc and certain Users of the RideDoc Platform.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">RideDoc advises you to use the RideDoc Platform with a data plan with unlimited or
                    very high data usage limits, and RideDoc shall not be responsible or liable for any fees, costs, or overage
                    charges associated with any data plan you use to access the RideDoc Platform</span></p>
                <p className="p-content2 para-content"><span className="s-content3">The availability of the Services may be dependent on the third-party from which you
                    received the license to the RideDoc App, e.g., the Apple iPhone or Android app stores (&ldquo;App
                    Store&rdquo;). These Terms are between you and RideDoc and not with the App Store and RideDoc is responsible
                    for the provision of Services as described in these Terms. However, if you downloaded the RideDoc App from
                    the Apple App Store, Apple and its subsidiaries are third-party beneficiaries of these Terms. Upon your
                    acceptance of these Terms, Apple shall have the right (and will be deemed to have accepted the right) to
                    enforce these Terms against you as a third-party beneficiary thereof. These Terms incorporate by reference
                    Apple&rsquo;s Licensed Application End User License Agreement, for purposes of which, you are the
                    &ldquo;end-user.&rdquo; In the event of a conflict in the terms of the Licensed Application End User License
                    Agreement and these Terms, these Terms will&nbsp;control.</span></p>
                <p className="p-content2 para-content"><span className="s-content3">RideDoc shall not be in breach of this Agreement nor liable for failure or delay in
                    performing obligations under this Agreement if such failure or delay results from events, circumstances or
                    causes beyond its reasonable control including (without limitation) natural disasters or acts of God; labor
                    disputes or stoppages; war; government action; epidemic or pandemic; chemical or biological contamination;
                    strikes; riots; acts of domestic or international terrorism; quarantines; national or regional emergencies;
                    or any other cause, whether similar in kind to the foregoing or otherwise, beyond the party&rsquo;s
                    reasonable control. All service dates under this Agreement affected by force majeure shall be tolled for the
                    duration of such force majeure. The parties hereby agree, when feasible, not to cancel but reschedule the
                    pertinent obligations as soon as practicable after the force majeure condition ceases to exist.</span></p>
                <p className="p-content5 p-content7 p-content8 para-content"><span className="s-content6 s-content2"></span></p>
                <ol className="list-content1 lst-kix_list_21-0" start="14">
                    <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.lnxbz9"><span className="s-content6 s-content2">State and Local Disclosures</span></li>
                </ol>
                <p className="p-content2 para-content"><span className="s-content1">Certain jurisdictions require additional disclosures to you. You can view any
                    disclosures required by your local jurisdiction at </span><span
                        className="c16 s-content7">www.RideDoc.com/terms/</span><span className="s-content1">disclosures. </span><sup><a href="#cmnt2"
                            id="cmnt_ref2">[b]</a></sup><span className="s-content3">Please check regularly for updates.</span></p>
                <hr style={{pageBreakBefore: "always", display: "none"}} />
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
                    <ol className="list-content1 lst-kix_list_21-0" start="15">
                        <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.35nkun2"><span className="s-content8">Indemnification</span></li>
                    </ol>
                    <p className="p-content2 para-content"><span className="s-content3">You will indemnify and hold harmless and, at RideDoc&rsquo;s election, defend RideDoc
                        including our affiliates, subsidiaries, parents, successors and assigns, and each of our respective
                        officers, directors, employees, agents, or shareholders (collectively, the &ldquo;Indemnified
                        Parties&rdquo;) from and against any claims, actions, suits, losses, costs, liabilities and expenses
                        (including reasonable attorneys&rsquo; fees) relating to or arising out of your use of the RideDoc Platform,
                        RideDoc Services, Third-Party Services, and participation in the Mobile Bike Services, including: (1) your
                        breach of this Agreement or the documents it incorporates by reference; (2) your violation of any law or the
                        rights of a third party, including, Mechanics, Riders, as a result of your own interaction with such third
                        party; (3) any allegation that any materials or Information that you submit to us or transmit through the
                        RideDoc Platform or to us infringes, misappropriates, or otherwise violates the copyright, trademark, trade
                        secret or other intellectual property or other rights of any third party; and/or (4) any other activities in
                        connection with the RideDoc Platform, RideDoc Services, or Mobile Bike Services. This indemnity shall be
                        applicable without regard to the negligence of any party, including any indemnified person. You will not,
                        without RideDoc&rsquo;s prior written consent, agree to any settlement on behalf of any Indemnified Party
                        which includes either the obligation to pay any monetary amounts, or any admissions of liability, whether
                        civil or criminal, on the part of any Indemnified Party.</span></p>
                    <ol className="list-content1 lst-kix_list_21-0" start="16">
                        <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.1ksv4uv"><span className="s-content6 s-content2">Limitation of Liability</span></li>
                    </ol>
                    <p className="p-content2 para-content"><span className="s-content3">IN NO EVENT WILL RIDEDOC, INCLUDING OUR AFFILIATES, SUBSIDIARIES, PARENTS, SUCCESSORS
                        AND ASSIGNS, AND EACH OF OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR SHAREHOLDERS
                        (COLLECTIVELY &ldquo;RIDEDOC&rdquo; FOR PURPOSES OF THIS SECTION), BE LIABLE TO YOU FOR ANY INCIDENTAL,
                        SPECIAL, EXEMPLARY, PUNITIVE, CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING DAMAGES FOR DELETION,
                        CORRUPTION, LOSS OF DATA, LOSS OF PROGRAMS, FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR
                        TRANSMITTED BY THE RIDEDOC PLATFORM, SERVICE INTERRUPTIONS, OR FOR THE COST OF PROCUREMENT OF SUBSTITUTE
                        SERVICES) ARISING OUT OF OR IN CONNECTION WITH THE RIDEDOC PLATFORM, RIDEDOC SERVICES, THE MOBILE BIKE
                        SERVICES, OR THIS AGREEMENT, HOWEVER ARISING INCLUDING NEGLIGENCE, EVEN IF WE OR OUR AGENTS OR
                        REPRESENTATIVES KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE RIDEDOC PLATFORM MAY BE
                        USED BY YOU TO REQUEST AND SCHEDULE SERVICES BUT YOU AGREE THAT RIDEDOC HAS NO RESPONSIBILITY OR LIABILITY
                        TO YOU RELATED TO ANY SERVICES SET FORTH IN THIS AGREEMENT. FOR CLARITY AND WITHOUT LIMITING THE FOREGOING,
                        RIDEDOC HAS NO RESPONSIBILITY OR LIABILITY FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF
                        OR RELIANCE ON SERVICES SET FORTH IN THIS AGREEMENT OR ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY
                        THIRD-PARTY PROVIDER. CERTAIN JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
                        THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO
                        YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</span></p>
                    <ol className="list-content1 lst-kix_list_21-0" start="17">
                        <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.44sinio"><span className="s-content6 s-content2">Term and Termination</span></li>
                    </ol>
                    <p className="p-content2 para-content"><span className="s-content3">This Agreement is effective upon your acceptance of this Agreement. This Agreement
                        may be terminated: (a) by User, without cause, upon seven (7) days&rsquo; prior written notice to RideDoc;
                        or (b) by either Party immediately, without notice, upon the other Party&rsquo;s material breach of this
                        Agreement, including but not limited to any breach of Section 9 or breach of Section 10 or Section 11 of
                        this Agreement. In addition, RideDoc may terminate this Agreement or deactivate your User account
                        immediately in the event: (1) you are no longer eligible to qualify as a User; (2) you no longer qualify to
                        provide Mobile Bike Services or (3) you fall below RideDoc&rsquo;s star rating or cancellation threshold; or
                        (4) RideDoc has the good faith belief that such action is necessary to protect the safety of the RideDoc
                        community or third parties, provided that in the event of a deactivation pursuant to (1)-(4) above, you will
                        be given notice of the potential or actual deactivation and an opportunity to attempt to cure the issue to
                        RideDoc&rsquo;s reasonable satisfaction prior to RideDoc permanently terminating the Agreement. For all
                        other breaches of this Agreement, you will be provided notice and an opportunity to cure the breach. If the
                        breach is cured in a timely manner and to RideDoc&rsquo;s satisfaction, this Agreement will not be
                        permanently terminated. Sections 2, 6, 7 (with respect to the license), 12-13, 15-20, and 22 shall survive
                        any termination or expiration of this Agreement.</span></p>
                    <ol className="list-content1 lst-kix_list_21-0" start="18">
                        <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.2jxsxqh"><span className="s-content6 s-content2">Arbitration agreement</span></li>
                    </ol>
                    <p className="p-content6 para-content"><span className="s-content3">By agreeing to these Terms, you agree that you are required to resolve any claim that
                        you may have against RideDoc on an individual basis in arbitration as set forth in this Arbitration
                        Agreement, and not as a className, collective, coordinated, consolidated, mass and/or representative action. You
                        and RideDoc are each waiving your right to a trial by jury. This Arbitration Agreement will preclude you
                        from bringing any className, collective, coordinated, consolidated, mass and/or representative action against
                        RideDoc, and also preclude you from participating in or recovering relief in any current or future className,
                        collective, coordinated, consolidated, mass and/or representative action brought against RideDoc by someone
                        else&mdash;except as provided below in Section 18(a)(3)(c). Thus, the parties agree that the Arbitrator
                        shall not conduct any form of className, collective, coordinated, consolidated, mass and/or representative
                        arbitration, nor join, coordinate, or consolidate claims of multiple individuals against RideDoc in a single
                        proceeding&mdash;except as provided below in Section 18(a)(3)(c). For the avoidance of doubt, except as
                        provided below in Section 18(a)(3)(c), this Arbitration Agreement precludes you from bringing or
                        participating in any kind of className, collective, coordinated, consolidated, mass and/or representative or
                        other kind of group, multi-plaintiff or joint action against RideDoc, other than participating in a
                        classNamewide, collective, coordinated, consolidated, mass and/or representative settlement of claims.</span>
                    </p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(a) Agreement to Binding Arbitration Between You and RideDoc.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content9">(1) Covered Disputes:</span><span className="s-content3">&nbsp;Except as expressly provided
                        below in Section 18(b), you and RideDoc agree that any dispute, claim, or controversy in any way arising out
                        of or relating to (i) these Terms and prior versions of these Terms, or the existence, breach, termination,
                        enforcement, interpretation, scope, waiver, or validity thereof; (ii) your access to or use of the Services
                        at any time; (iii) incidents or accidents resulting in personal injury to you or anyone else that you allege
                        occurred in connection with your use of the Services (including, but not limited to, your use of the RideDoc
                        Platform or the mechanics version of the RideDoc App), regardless whether the dispute, claim, or controversy
                        occurred or accrued before or after the date you agreed to these Terms, and regardless whether you allege
                        that the personal injury was experienced by you or anyone else; and (iv) your relationship with RideDoc,
                        will be settled by binding individual arbitration between you and RideDoc, and not in a court of law. This
                        Arbitration Agreement survives after your relationship with RideDoc ends.</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content9">(2) className Action Waiver</span><span className="s-content3">: Any and all disputes, claims, or
                        controversies between the parties shall be resolved only in individual arbitration. The parties expressly
                        waive the right to have any dispute, claim, or controversy brought, heard, administered, resolved, or
                        arbitrated as a className, collective, coordinated, consolidated, and/or representative action, and neither an
                        arbitrator nor an arbitration provider shall have any authority to hear, arbitrate, or administer any className,
                        collective, coordinated, consolidated, and/or representative action, or to award relief to anyone but the
                        individual in arbitration. The parties also expressly waive the right to seek, recover, or obtain any
                        non-individual relief. Notwithstanding anything else in this agreement, this className Action Waiver does not
                        prevent you or RideDoc from participating in a classNamewide, collective, and/or representative settlement of
                        claims.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">The parties further agree that if for any reason a claim does not proceed in
                        arbitration, this className Action Waiver shall remain in effect, and a court may not preside over any action
                        joining, coordinating, or consolidating the claims of multiple individuals against RideDoc in a single
                        proceeding, except that this className Action Waiver shall not prevent you or RideDoc from participating in a
                        classNamewide, collective, and/or representative settlement of claims. If there is a final judicial
                        determination that any portion of this className Action Waiver is unenforceable or unlawful for any reason, (i)
                        any className, collective, coordinated, consolidated, and/or representative claims subject to the unenforceable
                        or unlawful portion(s) shall proceed in a court of competent jurisdiction; (ii) the portion of the className
                        Action Waiver that is enforceable shall be enforced in arbitration; (iii) the unenforceable or unlawful
                        portion(s) shall be severed from this Arbitration Agreement; and (iv) severance of the unenforceable or
                        unlawful portion(s) shall have no impact whatsoever on the enforceability, applicability, or validity of the
                        Arbitration Agreement or the arbitrability of any remaining claims asserted by you or RideDoc.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(3) Mass Actions:</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content9">a. Mass Action Waiver:</span><span className="s-content3">&nbsp;Any and all disputes, claims,
                        or controversies between the parties shall be resolved only in individual arbitration. The parties expressly
                        waive the right to have any dispute, claim, or controversy brought, heard, administered, resolved, or
                        arbitrated as a mass action, and neither an arbitrator nor an arbitration provider shall have any authority
                        to hear, arbitrate, or administer any mass action or to award relief to anyone but the individual in
                        arbitration&mdash;except as provided below in Section 18(a)(3)(c). The parties also expressly waive the
                        right to seek, recover, or obtain any non-individual relief. The parties agree that the definition of a
                        &ldquo;Mass Action&rdquo; includes, but is not limited to, instances in which you or RideDoc are represented
                        by a law firm or collection of law firms that has filed 50 or more arbitration demands of a substantially
                        similar nature against the other party within 180 days of the arbitration demand filed on your or
                        RideDoc&rsquo;s behalf, and the law firm or collection of law firms seeks to simultaneously or collectively
                        administer and/or arbitrate all the arbitration demands in the aggregate. Notwithstanding anything else in
                        this agreement, this Mass Action Waiver does not prevent you or RideDoc from participating in a mass
                        settlement of claims.</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content9">b. Dispute Procedure:</span><span className="s-content1">&nbsp;Notwithstanding any provision
                        to the contrary in the applicable arbitration provider&rsquo;s rules, the arbitrator shall be empowered to
                        determine whether the party bringing any claim has filed a Mass Action in violation of the Mass Action
                        Waiver. Either party shall raise with the arbitrator or arbitration provider such a dispute within 15 days
                        of its arising. If such a dispute arises before an arbitrator has been appointed, the parties agree that (i)
                        a panel of three arbitrators shall be appointed to resolve only disputes concerning whether the party
                        bringing any claim has filed a Mass Action in violation of the Mass Action Waiver. Each party shall select
                        one arbitrator from the arbitration provider&rsquo;s roster to serve as a neutral arbitrator, and these
                        arbitrators shall appoint a third neutral arbitrator. If the parties&rsquo; arbitrators cannot agree on a
                        third arbitrator, the arbitration provider will select the third arbitrator; (ii) RideDoc shall pay any
                        administrative fees or costs incidental to the appointment of Arbitrators under this provision, as well as
                        any fees or costs that would not be incurred in a court proceeding, such as payment of the fees of the
                        arbitrators, as well as room rental; (iii) the arbitrators shall issue a written decision with findings of
                        fact and conclusions of law; and (iv) any further arbitration proceedings or assessment of
                        arbitration-related fees shall be stayed pending the arbitrators&rsquo; resolution of the parties&rsquo;
                        dispute. If the arbitrator or panel of arbitrators determines that you have violated the Mass Action Waiver,
                        the parties shall have the opportunity to opt out of arbitration within 30 days of the arbitrator&rsquo;s or
                        panel of arbitrator&rsquo;s decision. You may opt out of arbitration by providing written notice of your
                        intention to opt out to the arbitration provider and to RideDoc LLC,</span><span>&nbsp;</span><span
                            className="s-content3">6100 4th Street NW, Washington DC, 20011, &nbsp;via USPS Priority Mail or hand delivery. This
                            written notice must be signed by you, and not any attorney, agent, or other representative of yours. RideDoc
                            may opt out of arbitration by sending written notice of its intention to opt out to the arbitration provider
                            and to you or your attorney, agent, or representative if you are represented. For the avoidance of doubt,
                            the ability to opt out of arbitration described in this Section 18(a)(3)(b) only applies if the arbitrator
                            or panel of arbitrators determines that you have violated the Mass Action Waiver. If the parties proceed
                            with arbitration, the parties agree that arbitrations will be batched as provided in Section 18(a)(3)(c)
                            below.</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content5">c. Batching:</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content3">i. To increase efficiency of resolution in the event a Mass Action is filed and
                        neither party exercises its right to opt out of arbitration pursuant to Section 18(a)(3)(b) above, the
                        following procedure shall apply. At the request of either party, an arbitrator shall be selected according
                        to the applicable arbitration provider&rsquo;s rules to act as a special master (&ldquo;Special
                        Master&rdquo;) to resolve threshold disputes regarding the propriety of some or all the arbitration demands
                        submitted in the Mass Action (&ldquo;Mass Arbitration Demands&rdquo;). These threshold disputes may include,
                        but are not limited to:</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <ol className="list-content1 lst-kix_list_29-2 start" start="1">
                        <li className="list-content3 li-bullet-0 para-list"><span className="s-content3">Any dispute regarding filing fees owed with respect to the Mass
                            Arbitration Demands, including whether claimants have submitted valid fee waivers;</span></li>
                        <li className="list-content3 li-bullet-0 para-list"><span className="s-content3">Any dispute regarding whether the applicable arbitration provider
                            has complied with the Arbitration Agreement with respect to processing and administering the Mass
                            Arbitration Demands;</span></li>
                        <li className="list-content3 li-bullet-0 para-list"><span className="s-content3">Any dispute regarding whether the Mass Arbitration Demands meet the
                            requirements set forth in Section 18(d) below;</span></li>
                        <li className="list-content3 li-bullet-0 para-list"><span className="s-content3">Whether claimants are barred from proceeding with their claims based
                            on a prior settlement agreement, violation of these Terms, or expiration of the statute of
                            limitations;</span></li>
                        <li className="list-content3 li-bullet-0 para-list"><span className="s-content3">Any dispute relating to representation of the same claimant by
                            multiple law firms;</span></li>
                        <li className="list-content3 li-bullet-0 para-list"><span className="s-content3">Any dispute regarding whether the Mass Arbitration Demands were
                            filed with the correct arbitration provider;</span></li>
                        <li className="list-content3 li-bullet-0 para-list"><span className="s-content3">Any dispute regarding discovery common to all claims; and</span>
                        </li>
                        <li className="list-content3 li-bullet-0 para-list"><span className="s-content3">Any disputes regarding legal or factual issues common to all
                            claims.</span></li>
                    </ol>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content3">Any such request shall be made within 15 days following the expiration of the
                        opt-out period described in Section 18(a)(3)(b), and may be made by providing written notice to the
                        arbitration provider. Upon the request of either party to appoint a Special Master to resolve the foregoing
                        issues, the applicable arbitration provider shall refrain from further processing any of the Mass
                        Arbitration Demands to which a dispute has been raised. No further payment for filing fees, administrative
                        costs, or arbitrator fees shall be deemed due with respect to any of the Mass Arbitration Demands as to
                        which a dispute has been raised until after the dispute(s) has/have been resolved by the Special Master.
                        RideDoc shall be responsible for the applicable arbitration provider&rsquo;s and Special Master&rsquo;s fees
                        and costs related to the proceedings before the Special Master.</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content3">A Special Master appointed pursuant to this procedure shall have no authority to
                        consolidate cases.</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content3">ii. After proceedings before the Special Master have concluded, to the extent any
                        of the Mass Arbitration Demands are permitted to proceed, the parties shall group the Mass Arbitration
                        Demands into batches of no more than 100 demands per batch by state of residence, and then alphabetically by
                        last name (plus, to the extent there are less than 100 arbitration demands left over after the batching
                        described above, a final batch consisting of the remaining demands), and shall inform the arbitration
                        provider of the batches and their compositions within 14 days of the conclusion of proceedings before the
                        Special Master. The arbitration provider shall treat each batch of claims as one case, with each case having
                        one demand for arbitration, one appointed arbitrator, and one set of administrative documents and
                        administrative and filing fees per batch. The parties shall randomly assign sequential numbers to each
                        batch, and only one batch shall proceed to arbitration at a time in the order of the random sequential
                        numbers. A separate arbitrator will be appointed to, and administrative and filing fees assessed for, each
                        batch as the batch proceeds to arbitration. You agree to cooperate in good faith with RideDoc and the
                        arbitration provider to implement such a batch approach to resolution and fees. Nothing in this provision
                        shall be construed as limiting the right to object that the filing or presentation of multiple arbitration
                        demands by or with the assistance of the same law firm or organization violates any term of this
                        Agreement.</span></p>
                    <p className="p-content5 p-content7 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content3">iii. If any Mass Arbitration Demands were originally processed as individual
                        arbitration demands before this batching procedure was commenced, further proceedings, including the
                        assessment of further arbitration filing or administration fees to either party shall be governed by the
                        procedures set forth in this Section 18(a)(3).</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content9">(4) Delegation Clause:</span><span className="s-content3">&nbsp;Only an arbitrator, and not
                        any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute arising
                        out of or relating to the interpretation, applicability, enforceability, or formation of this Arbitration
                        Agreement, including without limitation any claim that all or any part of this Arbitration Agreement is void
                        or voidable. An arbitrator shall also have exclusive authority to resolve all threshold arbitrability
                        issues, including issues relating to whether these Terms are applicable, unconscionable, or illusory and any
                        defense to arbitration, including without limitation waiver, delay, laches, or estoppel. However, only a
                        court of competent jurisdiction, and not an arbitrator, shall have the exclusive authority to resolve any
                        and all disputes arising out of or relating to the className Action Waiver and Mass Action Waiver, including,
                        but not limited to, any claim that all or part of the className Action Waiver and/or Mass Action Waiver is
                        unenforceable, unconscionable, illegal, void, or voidable&mdash;except that, as stated and pursuant to the
                        procedures provided in Section 18(a)(3)(b), an arbitrator or panel of arbitrators shall have authority to
                        determine whether the party bringing any claim has violated the Mass Action Waiver.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 p-content7 para-content"><span className="s-content9">(5) Application to Third Parties:</span><span className="s-content3">&nbsp;This Arbitration
                        Agreement shall be binding upon, and shall include any claims brought by or against any third parties,
                        including but not limited to your spouses, heirs, third-party beneficiaries and assigns, where their
                        underlying claims arise out of or relate to your use of the Services. To the extent that any third-party
                        beneficiary to this agreement brings claims against the Parties, those claims shall also be subject to this
                        Arbitration Agreement.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(b) Exceptions to Arbitration.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">Notwithstanding the foregoing, this Arbitration Agreement shall not require
                        arbitration of the following claims: (i) individual claims brought in small claims court so long as the
                        matter remains in such court and advances only on an individual basis; (ii) individual claims of sexual
                        assault or sexual harassment occurring in connection with your use of the Services; and/or (iii) injunctive
                        or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened
                        infringement, misappropriation, or violation of a party&rsquo;s copyrights, trademarks, trade secrets,
                        patents, or other intellectual property rights.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">Such claims may be brought and litigated in a court of competent jurisdiction by you
                        on an individual basis only. On an individual basis means that you cannot bring such claims as a className,
                        collective, coordinated, consolidated, mass and/or representative action against RideDoc. For the avoidance
                        of doubt, this precludes you from bringing claims as or participating in any kind of any className, collective,
                        coordinated, consolidated, mass and/or representative or other kind of group, multi-plaintiff or joint
                        action against RideDoc and no action brought by you may be consolidated or joined in any fashion with any
                        other proceeding. Where your claims are brought and litigated to completion on such an individual basis in a
                        court of competent jurisdiction, RideDoc agrees to honor your election.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">The parties&rsquo; agreement not to require arbitration in these limited instances
                        does not waive the enforceability of this Arbitration Agreement as to any other provision (including, but
                        not limited to, the waivers provided for in Section 2(a), which will continue to apply in court as well as
                        in arbitration), or the enforceability of this Arbitration Agreement as to any other controversy, claim, or
                        dispute.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(c) Rules and Governing Law.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">The parties shall be required to meet and confer to select a neutral arbitration
                        provider. Such an arbitration provider shall have operations in the state in which the dispute arises. If
                        the parties are unable to mutually agree upon an arbitration provider, then either party may invoke 9 U.S.C.
                        &sect; 5 to request that a court of competent jurisdiction appoint an arbitration provider with operations
                        in the state in which the dispute arises. Any arbitration provider appointed by a court under 9 U.S.C.
                        &sect; 5 shall conduct arbitration solely on an individualized basis as set forth in this Section 2. Once
                        the parties mutually agree upon a neutral arbitration provider, or an arbitrator provider is appointed under
                        9 U.S.C. &sect; 5, the ensuing arbitration shall commence pursuant to the rules of the designated
                        arbitration provider, except as designated herein. Once an arbitration provider is agreed upon or appointed,
                        an Arbitrator shall be appointed. The Arbitrator will be either (1) a retired judge or (2) an attorney
                        licensed to practice law in the state where the arbitration is conducted with experience in the law
                        underlying the dispute. The Arbitrator will be selected by the parties from the applicable arbitration
                        provider&rsquo;s roster of arbitrators. If the parties are unable to agree upon an Arbitrator after a good
                        faith meet and confer effort, then the applicable arbitration provider will appoint the Arbitrator in
                        accordance with its rules.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">Notwithstanding any choice of law or other provision in these Terms, the parties
                        agree and acknowledge that this Arbitration Agreement evidences a transaction involving interstate commerce
                        and that the Federal Arbitration Act, 9 U.S.C. &sect; 1 et seq. (&ldquo;FAA&rdquo;), will govern its
                        interpretation and enforcement and proceedings pursuant thereto. It is the intent of the parties to be bound
                        by the provisions of the FAA for all purposes, including, but not limited to, interpretation,
                        implementation, enforcement, and administration of this Arbitration Agreement, and that the FAA and the
                        applicable arbitration provider&rsquo;s rules shall preempt all state laws to the fullest extent permitted
                        by law. All statutes of limitations that would otherwise be applicable will apply to any arbitration
                        proceeding. If the FAA and applicable arbitration provider&rsquo;s rules are found to not apply to any issue
                        regarding the interpretation or enforcement of this Arbitration Agreement, then that issue shall be resolved
                        under the laws of the state where you reside when you accept these Terms.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">Any dispute, claim, or controversy arising out of or relating to incidents or
                        accidents resulting in personal injury (including but not limited to sexual assault or harassment claims)
                        that you allege occurred in connection with your use of the Services, whether before or after the date you
                        agreed to the Terms, shall be governed by and construed in accordance with the laws of the state in which
                        the incident or accident occurred.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(d) Process.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content1">Pre-Arbitration Dispute Resolution and Notification. The parties agree that
                        good-faith informal efforts to resolve disputes often can result in a prompt, low-cost, and mutually
                        beneficial outcome. The parties therefore agree that, before either party demands arbitration against the
                        other, we will personally meet and confer, via telephone or videoconference, in a good-faith effort to
                        resolve informally any claim covered by this Arbitration Agreement. Multiple individuals initiating claims
                        cannot participate in the same informal telephonic dispute resolution conference. If you are represented by
                        counsel, your counsel may participate in the conference, but you shall also fully participate in the
                        conference. The party initiating the claim must give notice to the other party in writing of their intent to
                        initiate an informal dispute resolution conference, which shall occur within 60 days after the other party
                        receives such notice, unless an extension is mutually agreed upon by the parties. To notify RideDoc that you
                        intend to initiate an informal dispute resolution conference, write to RideDoc
                        LLC,</span><span>&nbsp;</span><span className="s-content3">6100 4th Street NW, Washington DC, 20011, providing your
                            name, the telephone number(s) associated with your RideDoc account (if any), the email address(es)
                            associated with your RideDoc account, and a description of your claim. Engaging in an informal dispute
                            resolution conference is a condition precedent that must be fulfilled before commencing arbitration, and the
                            Arbitrator shall dismiss any arbitration demand filed before completion of an informal dispute resolution
                            conference. The statute of limitations and any filing fee deadlines shall be tolled while the parties engage
                            in the informal dispute resolution process required by this paragraph.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content1">Initiating Arbitration. In order to initiate arbitration following the conclusion of
                        the informal dispute resolution process required by this Section, a party must provide the other party with
                        a written demand for arbitration and file the demand with the applicable arbitration provider, as determined
                        by Section 2(c). A party initiating an arbitration against RideDoc must send the written demand for
                        arbitration to RideDoc LLC .</span><span>&nbsp;</span><span className="s-content1">6100 4th Street NW, Washington DC,
                            20011, or serve the Demand on RideDoc&rsquo;s registered agent for service of process, c/o RideDoc
                            LLC.</span><span>&nbsp;</span><span className="s-content3">Additionally, a party initiating arbitration against RideDoc
                                must send an electronic version of the demand for arbitration to the Arbitration Provider, and must send an
                                electronic version of the as-filed demand to hello@ridedoc.co</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">By signing the demand for arbitration, counsel certifies to the best of
                        counsel&rsquo;s knowledge, information, and belief, formed after an inquiry reasonable under the
                        circumstances, that (i) the demand for arbitration is not being presented for any improper purpose, such as
                        to harass, cause unnecessary delay, or needlessly increase the cost of dispute resolution; (ii) the claims
                        and other legal contentions are warranted by existing law or by a nonfrivolous argument for extending,
                        modifying, or reversing existing law or for establishing new law; and (iii) the factual contentions have
                        evidentiary support or, if specifically so identified, will likely have evidentiary support after a
                        reasonable opportunity for further investigation or discovery. The Arbitrator shall be authorized to afford
                        any relief or impose any sanctions available under Federal Rule of Civil Procedure 11 or any applicable
                        state law for either party&rsquo;s violation of this requirement.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(e) Location.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">Unless you and RideDoc otherwise agree, if you reside in the United States, the
                        arbitration will be conducted in the county where you reside. The right to a hearing will be determined by
                        the applicable arbitration provider&rsquo;s rules. Subject to the applicable arbitration provider&rsquo;s
                        rules, the Arbitrator will have the discretion to direct a reasonable exchange of information by the
                        parties, consistent with the expedited nature of the arbitration.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(f) Offers of Judgment.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">At least 10 days before the date set for the arbitration hearing, any party may serve
                        an offer in writing upon the other party to allow judgment on specified terms. If the offer is accepted, the
                        offer with proof of acceptance shall be submitted to the arbitrator, who shall enter judgment accordingly.
                        If the offer is not accepted prior to the arbitration hearing or within 30 days after it is made, whichever
                        occurs first, it shall be deemed withdrawn, and cannot be given in evidence upon the arbitration. If an
                        offer made by one party is not accepted by the other party, and the other party fails to obtain a more
                        favorable award, the other party shall not recover their post-offer costs and shall pay the offering
                        party&rsquo;s costs from the time of the offer.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(g) Arbitrator&rsquo;s Decision.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">The Arbitrator will render an award within the time frame specified in the applicable
                        arbitration provider&rsquo;s rules. Judgment on the arbitration award may be entered in any court of
                        competent jurisdiction. The Arbitrator may award declaratory or injunctive relief only in favor of the
                        claimant and only to the extent necessary to provide relief warranted by the claimant&rsquo;s individual
                        claim. An Arbitrator&rsquo;s decision shall be final and binding on all parties.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">The Arbitrator is not bound by decisions reached in separate arbitrations, and the
                        Arbitrator&rsquo;s decision shall be binding only upon the parties to the arbitration that are the subject
                        of the decision.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">The Arbitrator shall award reasonable costs incurred in the arbitration to the
                        prevailing party in accordance with the law(s) of the state in which arbitration is held.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(h) Fees.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">With the exception of the provisions governing payment of arbitration costs set forth
                        above, your responsibility to pay any filing, administrative, and arbitrator fees will be solely as set
                        forth in the applicable arbitration provider&rsquo;s rules and shall be up to the amount you would be
                        required to pay if you filed a claim in court.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">If you have a gross monthly income of less than 300% of the federal poverty
                        guidelines, you are entitled to a waiver of arbitration fees and costs, exclusive of arbitrator fees. If you
                        believe that you meet the requirements to obtain a fee waiver, and your demand for arbitration arises
                        outside of California, then you may request a fee waiver only by submitting to the arbitration provider AO
                        240, Application to Proceed in District Court Without Prepaying Fees or Costs (found here), or a declaration
                        under oath containing all the information required by AO 240; if your demand for arbitration arises in
                        California, then you must submit a declaration under oath providing your monthly income and the number of
                        persons in your household.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">Any and all disputes regarding a party&rsquo;s obligation to pay any arbitration fees
                        or costs that arise after an arbitrator is appointed shall be determined solely by the arbitrator. If such a
                        dispute arises before an arbitrator has been appointed, and if no Special Master has been requested by
                        either party pursuant to Section 18(a)(3)(c)(i) of these Terms, the parties agree that (i) the due date for
                        any disputed fees shall be stayed pending resolution of the parties&rsquo; dispute, (ii) a panel of three
                        arbitrators shall be appointed to resolve the parties&rsquo; dispute concerning a party&rsquo;s obligation
                        to pay fees or costs of arbitration, (iii) the panel of arbitrators shall be appointed by each party
                        selecting one arbitrator from the arbitration provider&rsquo;s roster to serve as neutral arbitrators, and
                        these arbitrators shall appoint a third neutral arbitrator. If the parties&rsquo; arbitrators cannot agree
                        on a third arbitrator, the arbitration administrator will select the third arbitrator, (iv) RideDoc shall
                        pay any administrative fees or costs incidental to the appointment of a panel of arbitrators under this
                        provision, as well as any fees or costs that would not be incurred in a court proceeding, such as payment of
                        the fees of the arbitrator(s), as well as room rental, and (v) the arbitrator(s) shall issue a written
                        decision with findings of fact and conclusions of law. If two or more fee disputes between a claimant and
                        RideDoc arise at or around the same time, the disputes may be consolidated for resolution by a single
                        arbitrator or panel of arbitrators either at the agreement of the parties or the election of the party
                        common to all such disputes.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(i) Opting Out of Arbitration for Mechanic Claims That Are Not In a Pending
                        Settlement Action.</span></p>
                    <p className="p-content9 para-content"><span className="s-content1">As a Mechanic or Mechanic applicant, you may opt out of the requirement to
                        arbitrate Mechanic Claimspursuant to the terms of this subsection if you have not previously agreed to an
                        arbitration provision in RideDoc&rsquo;s Terms of Service where you had the opportunity to opt out of the
                        requirement to arbitrate.. With respect to any Claims brought by RideDoc against a Mechanic, or for Claims
                        brought by a Mechanic against RideDoc that: (A) are based on an alleged employment relationship between
                        RideDoc and a Mechanic; (B) arise out of, or relate to, RideDoc&rsquo;s actual deactivation of a
                        Mechanic&rsquo;s User account or a threat by RideDoc to deactivate a Mechanic&rsquo;s User account; (C)
                        arise out of, or relate to, RideDoc&rsquo;s actual termination of a Mechanic&rsquo;s Agreement with RideDoc
                        under the termination provisions of this Agreement, or a threat by RideDoc to terminate a Mechanic&rsquo;s
                        Agreement; (D) arise out of, or relate to, Fares (as defined in this Agreement, including RideDoc&rsquo;s
                        commission or fees on the Fares), tips, or average hourly guarantees owed by RideDoc to Mechanics for Mobile
                        Bike Services, other than disputes relating to referral bonuses, other RideDoc promotions, or consumer-type
                        disputes, or (E) arise out of or relate to background checks performed in connection with a user seeking to
                        become a Mechanic (the subset of Claims in subsections (A)-(E) shall be collectively referred to as
                        &ldquo;Mechanic Claims&rdquo;),</span></p>
                    <p className="p-content2 para-content"><span className="s-content3">If you have previously agreed to such an arbitration provision, you may opt out of
                        any revisions to your prior arbitration agreement made by this provision in the manner specified below, but
                        opting out of this arbitration provision has no effect on any previous, other, or future arbitration
                        agreements that you may have with RideDoc. If you have not previously agreed to such an arbitration
                        provision and do not wish to be subject to this Arbitration Agreement with respect to Mechanic Claims, you
                        may opt out of arbitration with respect to such Mechanic Claims, other than those in a Pending Settlement
                        Action, by notifying RideDoc in writing of your desire to opt out of arbitration for such Mechanic Claims,
                        which writing must be dated, signed and delivered by electronic mail to hello@ridedoc.co</span></p>
                    <p className="p-content2 para-content"><span className="s-content3">In order to be effective, (A) the writing must clearly indicate your intent to opt
                        out of this Arbitration Agreement with respect to Mechanic Claims that are not part of a Pending Settlement
                        Action, (B) the writing must include the name, phone number, and email address associated with your User
                        Account, and (C) the email containing the signed writing must be sent within 30 days after the date this
                        Agreement is executed by you. Should you not opt out within the 30-day period, you and RideDoc shall be
                        bound by the terms of this Arbitration Agreement in full (including with respect to Mechanic Claims that are
                        not part of a Pending Settlement Action). Any opt out that you submit shall not apply to any Mechanic Claims
                        that are part of a Pending Settlement Action and your Mechanic Claims in any such Pending Settlement Action
                        shall continue to be governed by the arbitration provisions that are contained in the applicable RideDoc
                        Terms of Use that you agreed to prior to the effective date of this Agreement.</span></p>
                    <p className="p-content2 para-content"><span className="s-content3">Cases have been filed against RideDoc and may be filed in the future involving
                        Mechanic Claims. You should assume that there are now, and may be in the future, lawsuits against RideDoc
                        alleging className, collective, and/or representative Mechanic Claims in which the plaintiffs seek to act on
                        your behalf, and which, if successful, could result in some monetary recovery to you. But if you do agree to
                        arbitration of Mechanic Claims with RideDoc under this Arbitration Agreement, you are agreeing in advance
                        that you will bring all such claims, and seek all monetary and other relief, against RideDoc in an
                        individual arbitration, except for the Mechanic Claims that are part of a Pending Settlement Action. You are
                        also agreeing in advance that you will not participate in, or seek to recover monetary or other relief, for
                        such claims in any court action or className, collective, and/or representative action. You have the right to
                        consult with counsel of your choice concerning this Arbitration Agreement and you will not be subject to
                        retaliation if you exercise your right to assert claims or opt- out of any Mechanic Claims under this
                        Arbitration Agreement.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content5">(j) Severability and Survival.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content6 para-content"><span className="s-content3">If any portion of this Arbitration Agreement is found to be unenforceable or unlawful
                        for any reason, (i) the unenforceable or unlawful provision shall be severed from these Terms; (ii)
                        severance of the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of the
                        Arbitration Agreement or the parties&rsquo; ability to compel arbitration of any remaining claims on an
                        individual basis pursuant to the Arbitration Agreement; and (iii) to the extent that any claims must
                        therefore proceed on a className, collective, consolidated, or representative basis, such claims must be
                        litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that
                        litigation of those claims shall be stayed pending the outcome of any individual claims in
                        arbitration.</span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <p className="p-content5 para-content"><span className="s-content3"></span></p>
                    <ol className="list-content1 lst-kix_list_21-0" start="19">
                        <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.z337ya"><span className="s-content8">Confidentiality</span></li>
                    </ol>
                    <p className="p-content2 para-content"><span className="s-content3">You agree not to use any technical, financial, strategic and other proprietary and
                        confidential information relating to RideDoc&rsquo;s business, operations and properties, information about
                        a User made available to you in connection with such User&rsquo;s use of the RideDoc Platform, which may
                        include the User&rsquo;s name, service location, contact information and photo (&ldquo;Confidential
                        Information&rdquo;) disclosed to you by RideDoc for your own use or for any purpose other than as
                        contemplated herein. You shall not disclose or permit disclosure of any Confidential Information to third
                        parties, and you agree not to store separate and outside of the RideDoc Platform any Confidential
                        Information obtained from the RideDoc Platform. As a Mechanic, you understand that some of the Confidential
                        Information you receive may be protected by federal and/or state confidentiality laws, such as the Health
                        Information Portability and Accountability Act of 1996 (&ldquo;HIPAA&rdquo;), governing the privacy and
                        security of protected (patient) health information. In the event that you know a Rider, you should not
                        disclose to anyone the identity of the Rider,the type of service or the service location, as this could
                        violate HIPAA. You understand that any violation of the Agreement&rsquo;s confidentiality provisions may
                        violate HIPAA or state confidentiality laws and could result in civil or criminal penalties against you. You
                        agree to take all reasonable measures to protect the secrecy of and avoid disclosure or use of Confidential
                        Information of RideDoc in order to prevent it from falling into the public domain. Notwithstanding the
                        above, you shall not have liability to RideDoc with regard to any Confidential Information which you can
                        prove: was in the public domain at the time it was disclosed by RideDoc or has entered the public domain
                        through no fault of yours; was known to you, without restriction, at the time of disclosure, as demonstrated
                        by files in existence at the time of disclosure; is disclosed with the prior written approval of RideDoc;
                        becomes known to you, without restriction, from a source other than RideDoc without breach of this Agreement
                        by you and otherwise not in violation of RideDoc&rsquo;s rights; or is disclosed pursuant to the order or
                        requirement of a court, administrative agency, or other governmental body; provided, however, that You shall
                        provide prompt notice of such court order or requirement to RideDoc to enable RideDoc to seek a protective
                        order or otherwise prevent or restrict such disclosure.</span></p>
                    <ol className="list-content1 lst-kix_list_21-0" start="20">
                        <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.3j2qqm3"><span className="s-content6 s-content2">Relationship with RideDoc</span></li>
                    </ol>
                    <p className="p-content2 p-content3 para-content"><span className="s-content3"></span></p>
                    <p className="p-content2 para-content"><span className="s-content3">As a Mechanic on the RideDoc Platform, you acknowledge and agree that you and RideDoc
                        are in a direct business relationship, and the relationship between the parties under this Agreement is
                        solely that of independent contracting parties. You and RideDoc expressly agree that (1) this is not an
                        employment agreement and does not create an employment relationship between you and RideDoc; and (2) no
                        joint venture, franchisor- franchisee, partnership, or agency relationship is intended or created by this
                        Agreement. You have no authority to bind RideDoc, and you undertake not to hold yourself out as an employee,
                        agent or authorized representative of RideDoc.</span></p>
                    <p className="p-content2 para-content"><span className="s-content3">RideDoc does not, and shall not be deemed to, direct or control you generally or in
                        your performance under this Agreement specifically, including in connection with your provision of Mobile
                        Bike Services, your acts or omissions. You retain the sole right to determine when, where, and for how long
                        you will utilize the RideDoc Platform. RideDoc does not, and shall not be deemed to, unilaterally prescribe
                        specific dates, times of day, or any minimum number of hours for you to utilize the RideDoc Platform. You
                        retain the option to accept or to decline or ignore a Rider&rsquo;s request for Mobile Bike Services via the
                        RideDoc Platform, or to cancel an accepted request for &nbsp;Mobile Bike Services via the RideDoc Platform,
                        subject to RideDoc&rsquo;s then-current cancellation policies. RideDoc does not, and shall not be deemed to,
                        require you to accept any specific request for Mobile Bike Services as a condition of maintaining access to
                        the platform. With the exception of any signage required by law or permit/license rules or requirements,
                        RideDoc shall have no right to require you to: (a) display RideDoc&rsquo;s names, logos or colors on your
                        vehicle or your bike repair shop; or (b) wear a uniform or any other clothing displaying RideDoc&rsquo;s
                        names, logos or colors. You acknowledge and agree that you have complete discretion to provide Mobile Bike
                        Services or otherwise engage in any other business or employment activities, including but not limited to
                        providing services similar to the Mobile Bike Services to other companies, and that RideDoc does not, and
                        shall not be deemed to, restrict you from engaging in any such activity.</span></p>
                    <ol className="list-content1 lst-kix_list_21-0" start="21">
                        <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.1y810tw"><span className="s-content8">Third-Party Services</span></li>
                    </ol>
                    <p className="p-content2 para-content"><span className="s-content3">In addition to connecting Riders with Mechanics, the RideDoc Platform may enable
                        Users to provide services or receive services from other third parties. For example, Users may be able to
                        use the RideDoc Platform to receive roadside assistance or other services provided by third parties
                        (collectively, the &ldquo;Third-Party Services&rdquo;). This Agreement between you and RideDoc governs your
                        use of the RideDoc Platform in connection with the Third-Party Services.</span></p>
                    <p className="p-content2 para-content"><span className="s-content3">In addition, you understand that the Third-Party Services may also be subject to
                        terms and pricing of the third-party provider (collectively, the &ldquo;Third-Party Terms&rdquo;) which will
                        govern your relationship with such third-party provider, as applicable. You agree that RideDoc is not
                        responsible and may not be held liable for the Third-Party Services or the actions or omissions of the
                        third-party provider. Such Third-Party Services may not be investigated, monitored or checked for accuracy,
                        appropriateness, or completeness by RideDoc, and RideDoc is not responsible for any Third-Party Services
                        accessed through the RideDoc Platform. </span></p>
                    <p className="p-content2 para-content"><span className="s-content3">In the event of a conflict in the terms of any Third-Party Terms and this Agreement,
                        the terms of this Agreement shall control with respect to RideDoc and your agreements with RideDoc herein,
                        and the limitations of liability set forth in Section 16 above shall also apply to the third-party provider.
                        The Dispute Resolution and Arbitration Agreement provisions in Section 18 above shall apply instead of any
                        terms in any Third-Party Terms for all purposes except with respect to claims that are solely against the
                        third-party provider.</span></p>
                    <ol className="list-content1 lst-kix_list_21-0" start="22">
                        <li className="p-content6 list-content2 li-bullet-0 para-list" id="h.4i7ojhp"><span className="s-content6 s-content2">General</span></li>
                    </ol>
                    <p className="p-content2 para-content"><span className="s-content1">Except as provided in Section 18, this Agreement shall be governed by the laws of
                        the State of Washington DC without regard to choice of law principles. This choice of law provision is only
                        intended to specify the use of Washington DC &nbsp;law to interpret this Agreement and is not intended to
                        create any other substantive right to People from other states to assert claims under Washington DC law
                        whether by statute, common law, or otherwise. If any provision of this Agreement is or becomes invalid or
                        non-binding, the parties shall remain bound by all other provisions of this Agreement. In that event, the
                        parties shall replace the invalid or non-binding provision with provisions that are valid and binding and
                        that have, to the greatest extent possible, a similar effect as the invalid or non-binding provision, given
                        the contents and purpose of this Agreement. You agree that this Agreement and all incorporated agreements
                        may be automatically assigned by RideDoc, in our sole discretion by providing notice to you. You may not
                        assign this Agreement without RideDoc&rsquo;s prior written approval. Any purported assignment by you in
                        violation of this Section 22 shall be void. Except as explicitly stated otherwise, any notices to RideDoc
                        shall be given by certified mail, postage prepaid and return receipt requested to RideDoc
                        LLC,</span><span>&nbsp;</span><span className="s-content1">6100 4th Street NW, Washington DC, 20011.</span><span
                            className="s-content1">&nbsp;</span><sup><a href="#cmnt3" id="cmnt_ref3">[c]</a></sup><sup><a href="#cmnt4"
                                id="cmnt_ref4">[d]</a></sup><span className="s-content3">Any notices to you shall be provided to you through the
                                    RideDoc Platform or given to you via the email address or physical address you provide to RideDoc during the
                                    registration process. Headings are for reference purposes only and in no way define, limit, construe or
                                    describe the scope or extent of such section. The words &ldquo;include&rdquo;, &ldquo;includes&rdquo; and
                                    &ldquo;including&rdquo; are deemed to be followed by the words &ldquo;without limitation&rdquo;. A
                                    party&rsquo;s failure to act with respect to a breach by the other party does not constitute a waiver of the
                                    party&rsquo;s right to act with respect to subsequent or similar breaches, any such waiver shall be in
                                    writing. This Agreement sets forth the entire understanding and agreement between you and RideDoc with
                                    respect to the subject matter hereof and supersedes all previous understandings and agreements between the
                                    parties, whether oral or written.</span></p>
                    <p className="p-content2 para-content"><span className="s-content3">If you have any questions regarding the RideDoc Platform, RideDoc Services, or Mobile
                        Bike Services, please contact us via eMail at hello@ridedoc.co</span></p>

                </div>
                </div>
                )
}

                export default TermsConditions
