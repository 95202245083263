import React, { Fragment } from 'react';
import { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import './ProfileInformation.scss';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiEmailMarkAsUnread } from '@mdi/js';
import { Link, NavLink } from 'react-router-dom';
import {Helmet} from "react-helmet";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import axios from "axios";
import { APP_LOAD } from '../../constants/actionTypes';
import agent from '../../agent';
import monthJson from './month.json'

// import {
//   CardNumberElement,
//   CardCvcElement,
//   CardExpiryElement,
//   useStripe,
//   useElements,
// } from "@stripe/react-stripe-js";

const mapStateToProps = state => ({ ...state });

const CardInformation = (props) => {

  const [stripeApiKey, setStripeApiKey] = useState("");
  // const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isLoading, setLoading] = useState(true)
  const dispatch = useDispatch();
  
  const getStripeApiKey = async () => {
    const accessToken = JSON.parse(localStorage.getItem("access_token"));
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();
    try {
      const config = {
        headers: { 
          "Content-Type": "application/json",
          'x-access-token': `${accessToken}`,
          'x-request-time' : new Date().toString(),
          'x-request-timezone': timeZone
        },
      };
      const { data } = await axios.get("https://ec2-54-244-40-38.us-west-2.compute.amazonaws.com/api/v1/stripe/get-setup-intent", config);
      setStripeApiKey(data.public_key);
    } catch (error) {
     // console.log(error)
    }
  };

  
  // isEmail Verified check
  // let userData = '';
  // const loadDataOnlyOnce = useCallback(() => {
  //   userData = props.common.currentUser;

  //   if(userData) {
  //     setIsEmailVerified(userData.email_verified)
  //   }
  // }, [dispatch]); 
  

  useEffect(() => {
   
    dispatch({ type: APP_LOAD, payload: agent.Auth.current() })

    // setIsEmailVerified(false)

    setTimeout(() => {
      setLoading(false)
    }, 2000);

    getStripeApiKey();

    // loadDataOnlyOnce();
  }, []);

  let curUser = '';
  let paymentMethods = '';
  curUser = props.common.currentUser;
  let isEmailVerified = false;
  if(curUser) {
    isEmailVerified = curUser.email_verified ? curUser.email_verified : false;
    paymentMethods = curUser.payment_methods ? curUser.payment_methods : '';
  }


      return isLoading ? (   //Checkif if is loading
        <div className="lds-ripple"><div></div><div></div></div>
      ) : (
        <div className='main'>
          <Helmet>
              <title>RideDoc | Card Information</title>
              <meta name="description" content="Mobile bike mechanics at your door step" />
          </Helmet>

          <div className='header-wrp'>
            <header className='header-lft'>
              <div className="header-backic">
                <Link to="/profile-information" className="header-backic-icon">
                  <Icon path={mdiArrowLeft} size={'28px'} color="#222" />
                </Link>
              </div>
              <p className="header-mnutxt">Card Information</p>
            </header>
          </div>
          <div className='main-wrapper booking-wrp'>
            {isEmailVerified ?
              <div className="profile-info-wrp">
                <section className="profile-info-sec-wrp">
                {/* */}
                    <div className="form-hd-lbl">
                      <h3>Card Information</h3>
                    </div>
                    {(paymentMethods.length > 0) ?
                      <Fragment>
                        {paymentMethods?.map((paymentdetails) => (
                          <div className='crd-details' key={paymentdetails}>
                            <div className='crd-name'>
                              <p>{paymentdetails.brand}</p>
                            </div>
                            <div className='crd-info'>
                              <p className='crd-num'>XXXX XXXX XXXX <span>{paymentdetails.last4}</span></p>
                              <div className='crd-expde'>
                                <p className='crd-exmonth'>Expiry Month: <span>{paymentdetails.exp_month}</span></p>
                                <p className='crd-exyear'>Expiry Year: <span>{paymentdetails.exp_year}</span></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Fragment>
                    :
                      <Fragment>
                        {stripeApiKey && (
                          <Elements stripe={loadStripe(stripeApiKey)}>
                            <CheckoutForm />
                          </Elements>
                        )}
                      </Fragment>
                    }
                </section>
              </div>
            : 
            <div className='nonverify'>
              <Icon path={mdiEmailMarkAsUnread} size={'80px'} color="#ff5f07" />
              <p className='nonverify-cnt'>Please verify your email id. Once email has verified add your card details.</p>
            </div>
            }
          </div>
          <div className='footer-main-wrp-in'>
            <div className="footer-lst">
              <div className="footer-lst-in">
                <div className="footer-item">
                  <NavLink to="/home" className={({ isActive }) => (isActive ? 'footer-in active' : 'footer-in')}>
                    <span className='footer-link-in footer-home'>
                      <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.6 19V12.2941H14.4V19H20.4V10.0588H24L12 0L0 10.0588H3.6V19H9.6Z" fill="#FF5F07" />
                      </svg>
                    </span>
                    <span className='footer-txt'>Home</span>
                  </NavLink>
                </div>
                <div className="footer-item">
                  <NavLink to="/profile" className={({ isActive }) => (isActive ? 'footer-in active' : 'footer-in')}>
                    <span className='footer-link-in footer-profile'>
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5 0C10.7598 0 11.968 0.500445 12.8588 1.39124C13.7496 2.28204 14.25 3.49022 14.25 4.75C14.25 6.00978 13.7496 7.21796 12.8588 8.10876C11.968 8.99956 10.7598 9.5 9.5 9.5C8.24022 9.5 7.03204 8.99956 6.14124 8.10876C5.25044 7.21796 4.75 6.00978 4.75 4.75C4.75 3.49022 5.25044 2.28204 6.14124 1.39124C7.03204 0.500445 8.24022 0 9.5 0ZM9.5 11.875C14.7487 11.875 19 14.0006 19 16.625V19H0V16.625C0 14.0006 4.25125 11.875 9.5 11.875Z" fill="black" />
                      </svg>
                    </span>
                    <span className='footer-txt'>Profile</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
export default connect(mapStateToProps)(CardInformation);