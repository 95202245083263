import {
    CONTACT_LOAD,
    CONTACT_UNLOAD,
    CONTACT_FORMSUBMIT
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case CONTACT_LOAD:
        return {
          ...state,
          contact: action.payload ? action.payload : null,
          inProgress: null,
          errors: action.error ? action.payload.message : null,
          data: action.error ? null : action.payload.message
        };
      case CONTACT_FORMSUBMIT: 
          return {
            ...state,
            contactForm: action.payload ? action.payload : null,
            inProgress: true,
            errors: action.error ? action.payload.message : null,
            data: action.error ? null : action.payload.message
          };
      case CONTACT_UNLOAD:
        return {};
      default:
        return state;
    }
  };
  