import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import './../auth.scss';
import { Helmet } from "react-helmet";
import { Link, NavLink } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiAccountCircleOutline, mdiAccountTie, mdiLogout, mdiEmailOpen, mdiClipboardTextClock, mdiCalendar, mdiClockTimeTwoOutline, mdiClose, mdiPhoneInTalkOutline, mdiAt } from '@mdi/js';
import agent from '../../../agent';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LOGIN, FORGOTPASSWORD, FORGOTPASSWORD_PAGE_UNLOADED } from '../../../constants/actionTypes';
import HomeIcon from '../Signin/Home .png';
import OrdersIcon from '../Signin/OrdersIcon.png';
import ProfileIcon from '../Signin/ProfileIcon.png';
import { mdiEye, mdiEyeOff, mdiEyeLock } from '@mdi/js';
import ForgotPasswordOtp from './ForgotPasswordOtp';
import Modal from 'react-modal';
import axios from 'axios';

const mapStateToProps = state => ({ ...state });

const ForgotPassword = (props) => {
  //   const dispatch = useDispatch();
  //   let forgotPasswordError = '';
  //   let forgotPasswordSuccess = '';
  // // error message
  //   if(props.auth) {
  //     if(props.auth.errors != null && props.auth.errors != '') {
  //       forgotPasswordError = props.auth.errors.message
  //     }
  //   }
  //   console.log(props);
  //   if(props.auth) {
  //     console.log(forgotPasswordSuccess)
  //     if(props.auth.forgotpassword != null && props.auth.forgotpassword != '') {
  //       forgotPasswordSuccess = props.auth.forgotpassword
  //       console.log("forgot success",forgotPasswordSuccess);
  //     }
  //   }
  const [otpIsOpen, setOtpIsOpen] = useState(false);
  const [otpErrorIsOpen, setOtpErrorIsOpen] = useState(false);
  const [passwordType, setPasswordType] = useState('');
  const [cardAddPopup, setCardAddPopup] = React.useState(true);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [otpId, setOtpId] = useState('');

  // form validation rules 
  const validationSchema = Yup.object().shape({
    emailid: Yup.string().required('Email ID is required.').email('Please enter valid Email ID').max(64)
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, getValues, handleSubmit, reset, formState, formState: { errors, isSubmitSuccessful } } = useForm(formOptions);

  const result = {
    otpId: otpId,
    email: getValues('emailid')
  }
  //console.log("RESULT", result);


  const onSubmit = async (data) => {
    const request = {
      email: data.emailid,
      role: 'user',
    }

    axios.post(process.env.REACT_APP_RIDEDOC_API_ROOT + `auth/reset-password`, request, {
      headers: {
        'Content-Type': "application/json",
      }
    }).then((success) => {
      setOtpId(success?.data?.results?.otp_id);
      //console.log("OTP ID", success?.data?.results?.otp_id);
      setForgotPasswordSuccess(success);
      setOtpIsOpen(true);
    }).catch((error) => {
      if (error?.response?.status === 406) {
        // setForgotPasswordError("User doesn't exist");
        setOtpIsOpen(false);
        setOtpErrorIsOpen(true);
      } else {
        setForgotPasswordError(error.message);
      }
    })
  }

  function closeModel() {
    setOtpIsOpen(false);
    setOtpErrorIsOpen(false);
  }




  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#fff',
      maxWidth: '400px',
      padding: '30px 15px',
      width: '90%'
    },
  };


  return (
    <React.Fragment>
      <div className='forgot_bg'>
        <div className='main'>
          <Helmet>
            <title>RideDoc | Signin</title>
            <meta name="description" content="Mobile bike mechanics at your door step" />
          </Helmet>
          <div className='header-wrp'>
            <header className='hd-centered'>
              <Link to="/" className="logo" style={{ marginTop: 40 }}>
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="RideDoc" />
              </Link>
            </header>
            <h3 className='h5' style={{ fontWeight: 400, paddingTop: "1.5rem" }}>Mobile bike mechanics at your door step</h3>
          </div>
          <div className='main-wrapper'>
            <section className="auth-wrp forgot-form-wrp">
              <div className="auth-hd forgot-hd">
                <h1 className='h3'>Forgot Password</h1>
              </div>
              <div className="auth-default-log-wrp">
                {forgotPasswordError && <span className='error'>{forgotPasswordError}</span>}
                {/* {forgotPasswordSuccess && <span className='success'>{forgotPasswordSuccess}</span>} */}

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group forgot-group">
                    <input name="emailid" type="text" {...register('emailid')} className={`form-control signin-form-group ${errors.emailid ? 'is-invalid' : ''}`} />
                    <label className="form-lbl" htmlFor="emailid">Email ID</label>
                  </div>
                  {errors.emailid &&
                    <div className="invalid-feedback">{errors.emailid?.message}</div>
                  }
                  <div className="form-group forgot-group" style={{ marginTop: "2rem" }}>
                    <button className="btn btn-primary forgot-btn" type="submit"> Continue </button>
                  </div>
                </form>
                <div className="alredy-signin forgot-already-signin">
                  <p>Back to  <Link to="/signin" className="logo">Sign In</Link></p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Modal
        isOpen={otpIsOpen}
        onRequestClose={closeModel}
        ariaHideApp={false}
        style={customStyles}
        overlayClassName="modal-Overlay"
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> 
       <button >close</button> */}

        <div className='booking-wrp'>
          <div className={cardAddPopup ? 'rd-popup-wrp' : 'rd-popup-wrp dn'}>
            <div className="rd-popup-hd">
              <div className="rd-popup-close">
                <div className='reg-close' onClick={closeModel}>
                  <Icon path={mdiClose} size={'24px'} color="#222" />
                </div>
              </div>
            </div>
            <div className="rd-popup-content order-slot-cnt">
              <p className='zipcode-popup-mssage'>Otp sent to your Email, please verify</p>
            </div>
          </div>
        </div>
        <div className='ok-button' style={{ width: "21%", margin: "0 auto" }}>
          <Link to={{ pathname: '/forgot-otp-password' }} state={result}>
            <button className="btn btn-primary btn-ok">OK</button>
          </Link>
        </div>
      </Modal>

      <Modal
        isOpen={otpErrorIsOpen}
        onRequestClose={closeModel}
        ariaHideApp={false}
        style={customStyles}
        overlayClassName="modal-Overlay"
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> 
       <button >close</button> */}

        <div className='booking-wrp'>
          <div className={cardAddPopup ? 'rd-popup-wrp' : 'rd-popup-wrp dn'}>
            <div className="rd-popup-hd">
              <div className="rd-popup-close">
                <div className='reg-close' onClick={closeModel}>
                  <Icon path={mdiClose} size={'24px'} color="#222" />
                </div>
              </div>
            </div>
            <div className="rd-popup-content order-slot-cnt">
              <p className='zipcode-popup-mssage'>User doesn't exist</p>
            </div>
          </div>
        </div>
        <div className='ok-button' style={{ width: "21%", margin: "0 auto" }}>  
          <button className="btn btn-primary btn-ok" onClick={closeModel}>OK</button> 
        </div>
      </Modal>


    </React.Fragment>

  );
}

export default connect(mapStateToProps)(ForgotPassword);