import agent from './agent';
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  GOOGLESIGNUP,
  REFRESH_TOKEN
} from './constants/actionTypes';
import jwt_decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';

const promiseMiddleware = store => next => action => {
  // console.log(action);
  // console.log("promiseMiddle")
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      res => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        // console.log('RESULT', res);
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      error => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        // console.log('ERROR', error);
        action.error = true;
        action.payload = error.response.body;
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = store => next => action => {

  if (action.type === LOGIN || action.type === GOOGLESIGNUP) {
    if (!action.error) {
      if (action.payload.results.access_token) {
        window.localStorage.setItem('access_token', JSON.stringify(action.payload.results.access_token))
        window.localStorage.setItem('refresh_token', JSON.stringify(action.payload.results.refresh_token))
      }
      // window.localStorage.setItem('token', action.payload.access_token);
      // referesh token stringify with encription need for praveen
      agent.setToken(action.payload.results.access_token);
    }
  } else if (action.type === REFRESH_TOKEN) {
    if (!action.error) {
      if (action.payload.results.access_token) {
        window.localStorage.setItem('access_token', JSON.stringify(action.payload.results.access_token))
      }
      // window.localStorage.setItem('token', action.payload.access_token);
      // referesh token stringify with encription need for praveen
      agent.setToken(action.payload.results.access_token);
    }
  } else if (action.type === LOGOUT) {
    localStorage.clear();
    agent.setToken(null);
    window.location.replace("https://ridedoc.co");
    // window.location.reload();
  }

  next(action);
};

const checkRefreshTokenExpirationMiddleware = store => next => action => {
  const tokenData = JSON.parse(localStorage.getItem("refresh_token"));
  const refreshToken = tokenData && tokenData["refresh_token"];
  if (refreshToken) {
    if (jwt_decode(refreshToken).exp < Date.now() / 1000) {
      next(action);
      localStorage.clear();
    }
  }
  next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}


export { promiseMiddleware, localStorageMiddleware, checkRefreshTokenExpirationMiddleware }
