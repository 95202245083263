// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import React from 'react';
import { store, history } from './store';

import { BrowserRouter as Router } from 'react-router-dom';
// import { ConnectedRouter as Router } from 'connected-react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from './components/App';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <Router history={history}>
    <GoogleOAuthProvider clientId="597831761606-qrmqirk4j2ujv5omgb9h6nvajr5i8vqn.apps.googleusercontent.com">
      <App />
      </GoogleOAuthProvider>
    </Router>
  </Provider>
);
