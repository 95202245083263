import React from 'react';
import jwt_decode from 'jwt-decode';

class CustomFunction {
  static isAuthenticated() {

    //console.log("isAuthenticated in")
    let authToken = '';
    let authTokenDecode = '';
    let authTokenExpiry = '';
    authToken = JSON.parse(localStorage.getItem("access_token"));
    if (authToken) {
      authTokenDecode = jwt_decode(authToken);
      authTokenExpiry = authTokenDecode.exp
      if (Date.now() >= authTokenExpiry * 1000) {
        // navigate('/home')
        return false;
      }
      return true;
    }
    return false;
  }

  static isErrorMulFormMessage(data) {
    const errorMessageArray = data;
    const errorMessageObject = Object.keys(errorMessageArray).map(function (key) {
      return errorMessageArray[key]
    });
    const errorMessage = errorMessageObject.map((errorMessageArray) =>
      <li key={errorMessageArray} className='sr-err-data'>{errorMessageArray}</li>
    );
    return (
      <ul className='sr-err-msg'>{errorMessage}</ul>
    );
  }

  static check_meridiem(totalMinutes) {
    return parseInt(totalMinutes) > 720 ? 'PM' : 'AM';
  }

  static toHoursAndMinutes(totalMinutes) {
    let hours = Math.floor(totalMinutes / 60);
    if (hours > 12) {
      hours = hours - 12;
    }
    const minutes = (totalMinutes % 60) || '00';

    return `${hours}:${minutes} ${this.check_meridiem(totalMinutes)}`;
    // return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
  }

  static orderStatusFn(orderstatusval) {
    // console.log(orderstatusval);
    let orderstatus = '';
    switch (orderstatusval) {
      case 'open':
        orderstatus = 'Pending';
        break;
      case 'confirmed':
        orderstatus = 'Confirmed';
        break;
      case 'ontheway':
        orderstatus = 'Confirmed';
        break;
      case 'cancelled':
        orderstatus = 'Cancelled';
        break;
      case 'completed':
        orderstatus = 'Completed';
        break;
      default:
        orderstatus = '';
        break;
    }
    return orderstatus;
  }
  static orderStatusFnColor(orderstatusval) {
    let orderstatusColor = '';
    switch (orderstatusval) {
      case 'open':
        orderstatusColor = '#FFB400';
        break;
      case 'confirmed':
        orderstatusColor = '#507C76';
        break;
      case 'ontheway':
        orderstatusColor = '#24AD65';
        break;
      case 'cancelled':
        orderstatusColor = '#E94E4E';
        break;
      case 'completed':
        orderstatusColor = '#24AD65';
        break;
      default:
        orderstatusColor = '#FFB400';
        break;
    }
    return orderstatusColor;
  }

}
export default CustomFunction;