import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import {Helmet} from "react-helmet";

  const NotFound =() => {

      return (
        <div className='main'>
          <Helmet>
              <title>RideDoc | Page Not Available</title>
              <meta name="description" content="Mobile bike mechanics at your door step" />
          </Helmet>
          <div className='main-wrapper height-full-page'>
            <div className='pgnotav pb20'>
                <img src={process.env.PUBLIC_URL + '/images/pagenotfound.svg'} alt="Page not available" />
                <h1 className='pgnotav-hd pb20'>This Page Does Not Exist</h1>
                <Link to='/home' className="btn btn-w-200">Return Home</Link>
            </div>
          </div>
        </div>

      );
  }

export default connect()(NotFound);